<template>
    <div v-if="dataRetrieved">
        <div class="page-header-info-container">
            <div>
                <button type="button" class="btn btn-primary upload-button" @click="verifyInvoiceData">
                    Invoice Split Screen
                </button>

                <button type="button" class="btn btn-primary upload-button" @click="mergeAndOpenPDFs">
                    Open Invoices
                </button>
                <button type="button" class="btn btn-primary upload-button" @click="openWaybill">
                    Open Waybill
                </button>
                <button type="button" class="btn btn-primary upload-button" @click="sendRPARequest" v-if="entryStatus === 'PARTIAL_STORAGE'">
                    Trigger RPA
                </button>
                <!-- <button type="button" class="btn btn-primary upload-button" @click="classifyInvoiceItems">
                    Classify Items
                </button> -->
            </div>
            <div>
                <div class="badge-container">
                    <h3>
                        <span
                            :class="[
                                'badge',
                                {
                                    'bg-success': classificationStatus === 'Classification Completed',
                                    'bg-warning text-dark': classificationStatus === 'Classification In Progress',
                                    'bg-danger': classificationStatus === 'Not Classified',
                                },
                                'fixed-badge',
                            ]"
                        >
                            {{ this.classificationStatus }}
                        </span>
                    </h3>
                </div>
            </div>
        </div>

        <VerifyDocumentData
            :invoices="commercialInvoices"
            :customsEntryForm="customsEntryForm"
            v-if="displayDocumentVerificationView"
            :pdfFiles="invoiceDocumentSourceURLs"
        />

        <div v-if="!displayDocumentVerificationView">
            <h1>Entry Details</h1>
            <h2>Reference Number: {{ this.entryDetails?.entry_number }}</h2>

            <CustomsEntryForm
                ref="myCustomsForm"
                :customsEntryForm="customsEntryForm"
                @update-customs-entry-form="customsEntryForm = $event"
            />
            <!-- Commercial Invoices Table -->
            <div v-if="dataRetrieved">
                <DocumentTableView
                    :invoiceDataArray="commercialInvoices"
                    :customsEntryForm="customsEntryForm"
                    :documentVerificationFormat="false"
                    :linesToBeDeleted="linesToBeDeleted"
                    :linesToBeAdded="linesToBeAdded"
                    :invoicesToBeAdded="invoicesToBeAdded"
                />
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Number of Lines Read</th>
                                <th>Total Invoices Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ this.totalNumberOfLines }}</td>
                                <td>{{ this.totalFOBValue }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tr>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-success"
                                    :disabled="isGenerateCsvDisabled"
                                    @click="generateAsycudaXML"
                                >
                                    Generate Asycuda XML
                                </button>
                            </td>
                            <td>
                                <button type="button" class="btn btn-primary" @click="saveData">Save Entry</button>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="goToCustomsDeclarationPage(this.entryId)"
                                >
                                    Generate Valuation Form
                                </button>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    :disabled="isGenerateCsvDisabled"
                                    @click="generateWorkSheet"
                                >
                                    Generate Worksheet
                                </button>
                            </td>
                            <td>
                                <button type="button" class="btn btn-primary" @click="generateDepositForm">
                                    Generate Deposit Form
                                </button>
                                
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type="button" class="btn btn-primary" @click="generateCARICOM">
                                    Generate CARICOM Invoice
                                </button>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    :disabled="isGenerateCsvDisabled"
                                    @click="approveClassification"
                                    v-if="classificationStatus === 'Not Classified' || entryStatus === 'STORED_AND_NOT_CLASSIFIED'"
                                    >
                                    Approve Classification
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    :disabled="isGenerateCsvDisabled"
                                    @click="partialStorage"
                                    v-if="entryStatus === 'CLASSIFICATION_OKAY'"
                                    >
                                    Partial Storage
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    :disabled="isGenerateCsvDisabled"
                                    @click="fullStorage"
                                    v-if="entryStatus === 'PARTIAL_STORAGE'"
                                    >
                                    Full Storage
                                </button>
                            </td>
                            <td>
                                <!-- <button type="button" class="btn btn-primary" @click="displayRatesTable">
                                    View Rates of Exchange
                                </button> -->
                                <button type="button" class="btn btn-primary" @click="generateBreakdownSheet">
                                    Generate Breakdown Sheet
                                </button>
                            </td>
                            <td>
                                <button type="button" class="btn btn-primary" @click="generateTTC84">
                                    Generate Special Exemption
                                </button>
                            </td>
                            <td>
                                <button type="button" class="btn btn-primary" @click="generateOvertimeForm">
                                    Generate Overtime Form
                                </button>
                            </td>
                            <td v-if="typeOfDeclaration === 'Export'">
                                <button type="button" class="btn btn-primary" @click="generateScoonerBL()">
                                    Generate Scooner BL
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import * as SweetAlertConnector from "../composables/SweetAlertConnector";
import * as PackageTypes from "@/composables/PackageTypes";
import * as DatabaseConnector from "@/composables/DatabaseConnector";
import * as Utilities from "@/composables/Utilities";
import CustomsEntryForm from "./CustomsEntry/CustomsEntryForm.vue";
import * as Currency from "@/composables/Currency";
import { CLASSIFICATION_STATUS, ENTRY_STATUS } from "../constants";

import moment from "moment-timezone";
import DocumentTableView from "./DocumentTableView.vue";
import VerifyDocumentData from "./VerifyDocumentData.vue";

export default {
    name: "EntryDetails",
    components: {
        CustomsEntryForm,
        DocumentTableView,
        VerifyDocumentData,
    },
    props: {
        entryId: {
            type: [String, Number],
            required: true,
        },
    },
    watch: {
        totalFOBValue(newVal) {
            this.customsEntryForm.totalCargoValue = newVal;
        },
        "customsEntryForm.waybillDate"(newDate) {
            this.updateRates(newDate);
        },
        "customsEntryForm.countryOfOrigin"(newCountry) {
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    if (!item.country_of_origin || item.country_of_origin.trim().length === 0) {
                        item.country_of_origin = newCountry;
                    }
                });
            });
        },
        "customsEntryForm.regimeType"(newRegime) {
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                        (regime) => regime.regimeTypeId === newRegime
                    );
                    if(regimeData){
                        item.cpcCode = regimeData.cpcCodes[0].cpcId;
                    }
                });
            });
        },
        commercialInvoices: {
            handler: function (invoices) {
                // console.log("Invoice Changes");
                // console.log("Rates of Exchange: ", this.customsEntryForm.ratesOfExchange);
                if (this.customsEntryForm.ratesOfExchange.length > 0) {
                    // console.log("Rate of Exchange: ", this.customsEntryForm.ratesOfExchange);
                    invoices.forEach((invoice) => {
                        // console.log("Invoice Currency: ", invoice.currency);
                        this.$watch(
                            () => invoice.currency,
                            (newVal) => {
                                if (newVal) {
                                    // console.log("New Currency: ", newVal);
                                    invoice.rate_of_exchange = this.customsEntryForm.ratesOfExchange.find(
                                        (rate) => rate.base_currency === newVal
                                    ).rate;
                                }
                            },
                            { deep: true, immediate: true }
                        );
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        typeOfDeclaration(){
            const typeCode = this.customsEntryForm.regimeType == undefined ? "" : this.customsEntryForm.regimeTypeCode?.slice(0, 2);
            return typeCode === "EX" ? "Export" : "Import";
        },
        totalFOBValue() {
            return this.commercialInvoices
                .reduce((acc, invoice) => acc + parseFloat(invoice.invoice_total), 0)
                .toFixed(2);
        },
        toalInsuranceValue() {
            return this.commercialInvoices.reduce((acc, invoice) => acc + parseFloat(invoice.insurance), 0).toFixed(2);
        },
        totalInlandValue() {
            return this.commercialInvoices.reduce((acc, invoice) => acc + parseFloat(invoice.inland), 0).toFixed(2);
        },
        totalOtherChargesValue() {
            return this.commercialInvoices
                .reduce((acc, invoice) => acc + parseFloat(invoice.otherCharges), 0)
                .toFixed(2);
        },
        netInvoicePrice() {
            return (
                parseFloat(this.totalFOBValue) +
                parseFloat(this.toalInsuranceValue) +
                parseFloat(this.totalInlandValue) +
                parseFloat(this.totalOtherChargesValue)
            );
        },
        formattedDate: {
            get() {
                const date = new Date(this.entryDetails.waybill_date);
                return date.toISOString().split("T")[0];
            },
            set(newValue) {
                this.entryDetails.waybill_date = newValue;
            },
        },
        isGenerateCsvDisabled() {
            return this.commercialInvoices.some((invoice) => {
                return invoice.lines.some((line) => !line.tariff_code || line.tariff_code.trim() === "");
            });
        },
        classificationStatus() {
            const classificationResult = Utilities.getClassificationStatus(this.commercialInvoices)
                ? CLASSIFICATION_STATUS.NOT_CLASSIFIED
                : CLASSIFICATION_STATUS.COMPLETED;
            if (classificationResult === false) return CLASSIFICATION_STATUS.NOT_CLASSIFIED;
            else {
                if (this.classificationApproved === 1) return CLASSIFICATION_STATUS.APPROVED;
                else return CLASSIFICATION_STATUS.NOT_CLASSIFIED;
            }
        },
        availableCPCCodes() {
            if (this.customsEntryForm.regimeTypeCode !== undefined && this.customsEntryForm.regimeTypeCode !== null) {
                const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                    (regime) => regime.regimeTypeCode === this.customsEntryForm.regimeTypeCode
                );
                if (regimeData) {
                    return regimeData.cpcCodes;
                } else return [];
            } else {
                return this.customsEntryForm.regimeTypeOptions.flatMap((regime) => regime.cpcCodes);
            }
        },
    },
    data() {
        return {
            invoiceDocumentSourceURLs: [],
            displayDocumentVerificationView: false,
            entryDetails: null,
            waybill: {},
            waybillSourceURL: "",
            entry: {},
            entryStatus: "",
            commercialInvoices: [],
            linesToBeDeleted: [],
            linesToBeAdded: [],
            invoicesToBeAdded: [],
            dataRetrieved: false,
            loading: false,
            error: null,
            countries: [],
            totalNumberOfLines: 0,
            modeOfTransportOOptions: ["Air", "Ocean"],
            freightTypeOptions: ["FCL", "LCL", "Bulk", "Break Bulk"],
            packageTypeOptions: [],
            currencies: [],
            tradeAgreements: [],
            classificationApprovedBy: "",
            dateClassified: "",
            expandedIndex: null,
            classificationApproved: 0,
            customsEntryForm: {
                exporters: [],
                importers: [],
                vessels: [],
                ports: [],
                declarants: [],
                incotermsList: [],
                ratesOfExchange: [],
                importerId: "",
                exporterId: "",
                importerName: "",
                exporterName: "",
                importerTaxIdentificationNumber: "",
                exporterTaxIdentificationNumber: "",
                exporterCountry: "",
                declarantId: "",
                declarantTaxIdentificationNumber: "",
                modeOfTransport: "",
                freightType: "",
                waybillNumber: "",
                waybillDate: "",
                estimatedArrivalDate: "",
                vesselId: "",
                vesselName: "",
                incoTerms: "",
                portOfDischargeId: "",
                portOfDischargeName: "",
                netWeight: 0.0,
                grossWeight: 0.0,
                numberOfPackages: 0,
                packageType: "",
                packageTypes: [],
                marksAndNumbers: "As Addressed",
                freightCurrency: "USD",
                insuranceCurrency: "USD",
                otherChargesCurrency: "USD",
                totalCargoValue: 0.0,
                freightAmount: 0.0,
                insuranceAmount: 0.0,
                otherCharges: 0.0,
                insuranceRateOfExchange: 0.0,
                otherChargesRateOfExchange: 0.0,
                freightRateOfExchange: 0.0,
                rateOfExchange: 0.0,
                regimeType: "",
                regimeTypeCode: "",
                regimeTypeOptions: [],
                depositAmount: 0.0,
                containerCharges: 0.0,
                additionalCharges: 0.0,
                localFee: 0.0,
                countryOfOrigin: "",
                countryOfFinalDestination: "TT",
                countryLastProvinance: "",
                tradingCountry: "",
            },
            manualExemptionApplied: false,
        };
    },
    mounted() {
        this.fetchAllDetails();
        this.fetchCountries();
        this.packageTypeOptions = PackageTypes.usePackageTypes();
        this.currencies = Currency.getCurrencyList();
        this.fetchTradeAgreements();
    },
    methods: {
        verifyInvoiceData,
        async generateBreakdownSheet(){
            console.log("Generating Breakdown Sheet");
            console.log("Commercial Invoices: ", this.commercialInvoices);

            const invoices = this.commercialInvoices.map(invoice => {
                const inland = parseFloat(invoice.inland) || 0;
                const insurance = parseFloat(invoice.insurance) || 0;
                const otherCharges = parseFloat(invoice.otherCharges) || 0;
                const baseAmount = parseFloat(invoice.invoice_total) || 0;

                // Calculate total
                const total = inland + insurance + otherCharges + baseAmount;

                return {
                    invoiceNumber: `${invoice.invoice_number} - ${invoice.vendor_name}`,
                    otherCharges: otherCharges,
                    insurance: insurance,
                    inlandFreight: inland,
                    baseAmount: baseAmount,
                    total: total
                };
            });

            const importerName = this.customsEntryForm.importerName;


            const requestData = {
                customerName: importerName, // Ensure this is defined in your component
                referenceNumber: this.entryDetails.entry_number, // Ensure this is defined in your component
                invoices: invoices
            };

            SweetAlertConnector.showLoadingAlert("Generating Breakdown Sheet...");
            DatabaseConnector.generateBreakdownSheet(requestData).then((response) => {
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert("Breakdown Sheet Generated Successfully!");
                const fileURL = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
                const fileLink = document.createElement("a");
                const fileName = `Breakdown Sheet - ${this.entryDetails.entry_number}.xlsx`;
                fileLink.href = fileURL;
                fileLink.setAttribute("download", fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                fileLink.remove();
            }).catch((error) => {
                console.error("Failed to generate Breakdown Sheet:", error);
                SweetAlertConnector.showErrorAlert("Failed to generate Breakdown Sheet!");
            });
        },
        async saveCommodityCodeAccuracyPerformanceMetrics(){
            console.log("Saving Classification Accuracy Performance Metrics");
            const metrics = [];
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    metrics.push({
                        invoice_line_id: line.invoice_line_id,
                        approved_classification_code: line.tariff_code,

                    });
                });
            });
            const requestData = { metrics };
            console.log("Classification Accuracy Request Data: ", requestData);
            return DatabaseConnector.updateClassificationAccuracyPerformanceMetrics(requestData);
        },
        generateScoonerBL(){
            SweetAlertConnector.scoonerBLAlert().then((response) => {
                console.log("Scooner BL Response: ", response);
                const countryFinalDestinationName = this.countries.find((country) => country.code === this.customsEntryForm.countryOfFinalDestination)?.name;
                const grossWeightString = `${this.customsEntryForm.grossWeight.toLocaleString('en-US')} KGS`;
                const lines = this.commercialInvoices.flatMap(invoice => {
                    return invoice.lines.map(line => ({
                        quantity: line.quantity,
                        description: line.description,
                        packageType: "."
                    }))
                })
                lines[0].packageType = "px";
                const sconnerBLDetails = Utilities.formatScoonerBLDetails(this.customsEntryForm.exporterName, this.customsEntryForm.importerName, this.customsEntryForm.marksAndNumbers, this.customsEntryForm.vesselName, countryFinalDestinationName, lines, response.freightPayableAt.toUpperCase(), grossWeightString, response.portOfDischarge, this.entryDetails?.entry_number)
                console.log("Scooner BL Details: ", sconnerBLDetails);
                SweetAlertConnector.showLoadingAlert("Generating Scooner BL...");
                DatabaseConnector.generateScoonerBL(sconnerBLDetails).then((response) => {
                    SweetAlertConnector.closeCurrentAlert();
                    SweetAlertConnector.showSuccessAlert("Scooner BL Generated Successfully!");
                    const fileURL = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
                    const fileLink = document.createElement("a");
                    const fileName = `Scooner BL - ${this.entryDetails?.entry_number}.pdf`;
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                }).catch((error) => {
                    console.error("Failed to generate Scooner BL:", error);
                    SweetAlertConnector.showErrorAlert("Failed to generate Scooner BL!");
                });
            })
        },
        checkMandatoryData(){
            console.log("Checking Mandatory Data");
            if (this.customsEntryForm.importerId === "" || this.customsEntryForm.exporterId === "" ){
                SweetAlertConnector.showErrorAlert("Please fill in all mandatory fields before proceeding!");
                return false;
            }
            if(this.checkAllCPCCodes() === false){
                SweetAlertConnector.showErrorAlert("Please ensure all CPC Codes are filled in before proceeding!");
                return false;
            }
            if(this.checkAllNPCCodes() === false){
                SweetAlertConnector.showErrorAlert("Please ensure all NPC Codes are filled in before proceeding!");
                return false;
            }
            const typeOfDeclaration =
                this.customsEntryForm.regimeType == undefined ? "" : this.customsEntryForm.regimeTypeCode?.slice(0, 2);
            console.log("Type of Declaration: ", typeOfDeclaration);
            // console.log("Arrival Date: ", this.customsEntryForm.estimatedArrivalDate);
            // if(typeOfDeclaration === "IM" && (this.customsEntryForm.estimatedArrivalDate === undefined || null || this.customsEntryForm.estimatedArrivalDate.trim().length === 0)){
            //     SweetAlertConnector.showErrorAlert("Missing Arrival Date! Please Update Entry Details!");
            //     return false;
            // }
            return true;
        },
        checkAllCPCCodes(){
            let allCodesExist = true;
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    console.log("Line: ", line)
                    if (line.cpcCode === "" || line.cpcCode === "N/A" || line.cpcCode === null || line.cpcCode === undefined){
                        allCodesExist = false;
                    }
                });
            });
            return allCodesExist;
        },
        checkAllNPCCodes(){
            let allCodesExist = true;
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    if (line.npcCode === "" || line.npcCode === "N/A" || line.npcCode === null || line.npcCode === undefined || line.npcCode.trim() === ""){
                        allCodesExist = false;
                    }
                });
            });
            return allCodesExist;
        },
        setToPartialStorage(){
            this.entryStatus = "PARTIAL_STORAGE";
            this.saveData();
        },
        setToFullStorage(){
            this.entryStatus = "FULL_STORAGE";
            this.saveData();
        },
        mergeAndOpenPDFs,
        openWaybill,
        fetchTradeAgreements(){
          DatabaseConnector.fetchTradeAgreements().then((response) => {
            //   console.log("Trade Agreements: ", response);
              this.tradeAgreements = response;
          });
        },
        displayRatesTable() {
            if (
                this.customsEntryForm.waybillDate === undefined ||
                this.customsEntryForm.waybillDate === null ||
                this.customsEntryForm.waybillDate.trim().length === 0
            ) {
                SweetAlertConnector.showErrorAlert("Please Enter a valid Waybill Date to get rates of Exchange!");
                return;
            }
            if (this.customsEntryForm.ratesOfExchange.length === 0) this.updateRates(this.customsEntryForm.waybillDate);
            const currencies = [
                this.customsEntryForm.freightCurrency,
                this.customsEntryForm.insuranceCurrency,
                this.customsEntryForm.otherChargesCurrency,
            ];
            const ratesToDisplay = this.customsEntryForm.ratesOfExchange.filter((rate) =>
                currencies.includes(rate.base_currency)
            );
            SweetAlertConnector.displayRateOfExchangeTable(ratesToDisplay);
        },
        updateRates(shippedOnBoardDate) {
            console.log("Updating Rates", shippedOnBoardDate);
            DatabaseConnector.fetchRatesBasedOnShippedOnBoard(shippedOnBoardDate).then((rates) => {
                console.log("Rates:", rates);
                this.customsEntryForm.ratesOfExchange = rates;
                this.commercialInvoices.forEach((invoice) => {
                    if (invoice.currency && rates.length > 0) {
                        console.log("Invoice Currency: ", invoice.currency);
                        invoice.rate_of_exchange = rates.find((rate) => rate.base_currency === invoice.currency).rate;
                    }
                });
            });
        },
        verifyClassificationCodes() {
            let hasInvalidCode = false;

            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    console.log("Line: ", line.commodityCodeStatus);
                    if (line.commodityCodeStatus === "invalid") {
                        SweetAlertConnector.showErrorAlert(
                            `The following commodity code does not exist on Asycuda - ${line.tariff_code}. Please update this and try again.`
                        );
                        hasInvalidCode = true;
                    }
                });
            });

            return !hasInvalidCode;
        },
        partialStorage(){
            this.entryStatus = ENTRY_STATUS.PARTIAL_STORAGE;
            this.saveData();
        },
        fullStorage(){
            this.entryStatus = ENTRY_STATUS.FULL_STORAGE;
            this.saveData();
        },
        approveClassification() {
            if (!this.verifyClassificationCodes()) {
                return;
            }
            // this.saveCommodityCodeAccuracyPerformanceMetrics();
            console.log("Approving Classification");
            SweetAlertConnector.enterYourName().then((response) => {
                if(response === "CANCELED"){
                    console.log("Operation Cancelled");
                    return;
                }
                const currentDate = new Date();
                const name = response;
                console.log("Name: ", name);
                this.classificationApprovedBy = name;
                this.classificationApproved = 1;
                this.entryStatus = ENTRY_STATUS.CLASSIFICATION_OKAY;
                this.dateClassified = currentDate.toISOString();
                this.saveCommodityCodeAccuracyPerformanceMetrics();
                this.saveData();
            });
        },
        async inializeTaxes() {
            const CONCURRENCY_LIMIT = 20;
            const allLines = this.commercialInvoices.flatMap((invoice) =>
                invoice.lines.filter((line) =>
                    line.tariff_code !== undefined &&
                    line.tariff_code !== null &&
                    line.tariff_code.trim().length > 0 &&
                    line !== "N/A"
                )
            );

            // Process in chunks with concurrency limit
            for (let i = 0; i < allLines.length; i += CONCURRENCY_LIMIT) {
                const chunk = allLines.slice(i, i + CONCURRENCY_LIMIT);
                const promises = chunk.map((line) => this.updateTariffCode(line, "tariff_code", line.tariff_code));
                await Promise.all(promises); // Wait for current chunk to finish
            }
        },
        async classifyInvoiceItems() {
            console.log("Classifying Invoice Items");
            console.log("Invoice Data Array: ", this.commercialInvoices);

            const descriptions = this.commercialInvoices.flatMap((invoice) => {
                // Map the invoice lines to the desired format.
                let formattedItems =  invoice.lines
                .filter((item) => !item.tariff_code)
                .map((item) => ({
                    description: item.classification_description || item.description || "",
                    commodity_code: item.commodity_code || "",
                    product_number: item.product_number || "",
                }));
                // return formattedItems;

                // // Function to chunk the array into smaller arrays of a given size.
                const chunkSize = 20;
                const chunks = [];
                for (let i = 0; i < formattedItems.length; i += chunkSize) {
                    chunks.push([formattedItems.slice(i, i + chunkSize)]);
                }

                return chunks;
            });
            if (descriptions.length === 0) {
                console.log("No descriptions to classify.");
                return [];
            }
            console.log("Descriptions: ", [descriptions]);
            const commodityCodes = [];
            for (const chunk of descriptions) {
                console.log("Chunk: ", chunk);
                try { 
                    const result = await DatabaseConnector.getCommodityCode(chunk);
                    commodityCodes.push(...result);
                    this.processCommodityCodes(result[0]);
                } catch (error) {
                    console.error("Failed to classify items:", error);
                    continue;
                }
            }   
            // const commodityCodes = await DatabaseConnector.getCommodityCode([descriptions]);
            // this.processCommodityCodes(commodityCodes[0]);
            return commodityCodes;
        },
        insertFullStops(numberString) {
            if (numberString.length !== 11) {
                console.log("The string must contain exactly 11 numbers.", numberString);
                return "";
            }

            return numberString.slice(0, 4) + "." + numberString.slice(4, 6) + "." + numberString.slice(6, 8) + "." + numberString.slice(8);
        },
        processCommodityCodes(commodityCodes) {
            // console.log("Commodity Codes Processing: ", commodityCodes);
            commodityCodes.forEach((commodityCode) => {
                this.commercialInvoices.forEach((invoice) => {
                    invoice.lines.forEach((item) => {
                        if (commodityCode.description === `${item?.classification_description}` || commodityCode.description === `${item.description}`) {
                            if ( commodityCode.classification_model_suggested_code !== undefined && commodityCode.classification_model_suggested_code !== null) {
                                item.tariff_code = this.insertFullStops(
                                    commodityCode.classification_model_suggested_code.toString()
                                );
                                const itemPerformanceMetric = {
                                    llm_recommended_classification_code: item.tariff_code,
                                    approved_classification_code: "",

                                };
                                item.performanceMetric = itemPerformanceMetric;
                                this.updateTariffCode(item, "tariff_code", item.tariff_code);
                            }
                        }
                    });
                });
            });
        },

        async updateTariffCode(data, field, value) {
            this.updateInvoiceData(data, field, value);
            return new Promise((resolve) => {
                if (value === undefined || value === null || value.trim().length === 0 || value === "N/A") {
                    data.icdCharge = 0;
                    data.vatCharge = 0;
                    resolve();
                    return;
                }
                DatabaseConnector.getCommodityCharges(value.replace(/\./g, ""))
                .then((chargesResponse) => {
                    let icdObject = chargesResponse.charges["import custom duty"];
                    const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject[0].charge_amount);
                    let vatObject = chargesResponse.charges["value added tax"];
                    const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject[0].charge_amount);
                    data.icdCharge = icdCharge;
                    data.vatCharge = vatCharge;
                    data.commodityCodeStatus = "valid";
                    data.applicableTaxes = chargesResponse.charges;
                    data.commodity_description = chargesResponse.description;

                    let formattedCharges = Utilities.formatCharges(chargesResponse.charges, true);
                    data.commodity = {
                        code: value.replace(/\./g, ""),
                        id: chargesResponse.id,
                        charges: formattedCharges,
                    };
                    resolve();
                })
                .catch((error) => {
                    console.error("Failed to get commodity charges:", error);
                    data.commodityCodeStatus = "invalid";
                    data.icdCharge = 0;
                    data.vatCharge = 0;
                    resolve();
                });

            })
        },
        setInvoiceLineWorksheetData() {
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    item.cpcCodeName = this.availableCPCCodes.find((cpc) => cpc.cpcId === item.cpcCode)?.code;
                    item.tradeAgreementCode = this.tradeAgreements.find(
                        (tradeAgreement) => tradeAgreement.id === item.tradeAgreement
                    )?.code;
                    if(item.manualExemption !== undefined && item.manualExemption !== null && item.manualExemption === true){
                        this.manualExemptionApplied = true;
                        item.manualExemptionApplied = true;
                        item.commodity.charges.forEach((charge) => {
                            if(charge.name in item.Exemptions && parseFloat(item.Exemptions[charge.name].chargeAmount) !== parseFloat(charge.value)){
                                charge.relieved_rate = charge.value;
                                charge.value = item.Exemptions[charge.name].chargeAmount;
                            }
                        });
                    }
                    // console.log("Item: ", item);
                    // console.log("Trade Agreements: ", this.tradeAgreements);
                    // const promise = DatabaseConnector.getCommodityCharges(item.tariff_code.replace(/\./g, ""))
                    //     .then((chargesResponse) => {
                    //         let formattedCharges = Utilities.formatCharges(chargesResponse.charges, item.vatApplicable);
                    //         item.commodity = {
                    //             code: item.tariff_code.replace(/\./g, ""),
                    //             id: chargesResponse.id,
                    //             charges: formattedCharges,
                    //         };
                    //         if(item.manualExemption !== undefined && item.manualExemption !== null && item.manualExemption === true){
                    //             this.manualExemptionApplied = true;
                    //             item.manualExemptionApplied = true;
                    //             item.commodity.charges.forEach((charge) => {
                    //                 if(charge.name in item.Exemptions && parseFloat(item.Exemptions[charge.name].chargeAmount) !== parseFloat(charge.value)){
                    //                     charge.relieved_rate = charge.value;
                    //                     charge.value = item.Exemptions[charge.name].chargeAmount;
                    //                 }
                    //             });
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         SweetAlertConnector.closeCurrentAlert();
                    //         SweetAlertConnector.showErrorAlert(
                    //             `The following commodity code does not exist on Asycuda - ${item.tariff_code}. Please update this and try again. `
                    //         );
                    //         console.error("Failed to get commodity charges:", error);
                    //         return Promise.reject(error);
                    //     });
                    // promises.push(promise);
                });
            });

            // return Promise.all(promises);
        },
        generateCARICOM() {
            console.log("Generating CARICOM");
            const countryOfOrigin = this.countries.find(
                (country) => country.code === this.customsEntryForm.countryOfOrigin
            )?.name;
            const countryOfFinalDestination = this.countries.find(
                (country) => country.code === this.customsEntryForm.countryOfFinalDestination
            )?.name;
            const selectedIncoTerms = this.customsEntryForm.incotermsList.find(
                (incoTerm) => incoTerm.code === this.customsEntryForm.incoTerms
            )?.name;
            const selectedCurrency = this.currencies.find(
                (currency) => currency.code === this.commercialInvoices[0].currency
            )?.name;
            const selectedImporter = this.customsEntryForm.importers.find(
                (importer) => importer.id === this.customsEntryForm.importerId
            );
            const importerAddress = selectedImporter === undefined ? "" : selectedImporter.address;
            const importerCountryName = this.countries.find(
                (country) => country.code === selectedImporter.country
            )?.name;
            const importNameAddress =
                `${this.customsEntryForm.importerName}\n${importerAddress}\n${importerCountryName}`.toUpperCase();
            const selectedExporter = this.customsEntryForm.exporters.find(
                (exporter) => exporter.id === this.customsEntryForm.exporterId
            );
            const exporterAddress = selectedExporter === undefined ? "" : selectedExporter.address;
            const exporterCountryName = this.countries.find(
                (country) => country.code === selectedExporter.country
            )?.name;
            const exporterNameAddress =
                `${this.customsEntryForm.exporterName}\n${exporterAddress}\n${exporterCountryName}`.toUpperCase();

            const packageType = this.packageTypeOptions.find(
                (packageType) => packageType.value === this.customsEntryForm.packageType
            )?.text;

            SweetAlertConnector.caricomAlert()
                .then((response) => {
                    const caricomObject = Utilities.formatCaricomData(
                        importNameAddress,
                        exporterNameAddress,
                        this.entryDetails.entry_number,
                        countryOfOrigin,
                        selectedIncoTerms,
                        this.customsEntryForm.portOfDischargeName,
                        countryOfFinalDestination,
                        this.customsEntryForm.modeOfTransport,
                        this.customsEntryForm.vesselName,
                        selectedCurrency,
                        response,
                        this.customsEntryForm.grossWeight,
                        this.commercialInvoices,
                        this.customsEntryForm.numberOfPackages,
                        this.customsEntryForm.totalCargoValue,
                        this.customsEntryForm.freightAmount,
                        this.customsEntryForm.insuranceAmount,
                        this.customsEntryForm.otherCharges,
                        packageType
                    );
                    SweetAlertConnector.showLoadingAlert("Generating CARICOM Invoice...");
                    console.log("CARICOM Object: ", caricomObject);

                    DatabaseConnector.generateCaricomInvoice(caricomObject)
                        .then((response) => {
                            SweetAlertConnector.closeCurrentAlert();
                            SweetAlertConnector.showSuccessAlert("CARICOM Invoice Generated Successfully!");
                            const fileURL = window.URL.createObjectURL(
                                new Blob([response], {
                                    type: "application/pdf",
                                })
                            );
                            const fileLink = document.createElement("a");
                            const fileName = `CARICOM Invoice - ${this.entryDetails.entry_number}.pdf`;
                            fileLink.href = fileURL;
                            fileLink.setAttribute("download", fileName);
                            document.body.appendChild(fileLink);
                            fileLink.click();
                            fileLink.remove();
                        })
                        .catch((error) => {
                            console.error("Failed to generate CARICOM Invoice:", error);
                            SweetAlertConnector.showErrorAlert("Failed to generate CARICOM Invoice!");
                        });
                })
                .catch((error) => {
                    console.error("Failed to generate CARICOM Invoice:", error);
                    SweetAlertConnector.showErrorAlert("Failed to generate CARICOM Invoice!");
                });
        },
        buildWorksheetObject() {
            const worksheetObject = Utilities.formatWorkSheetData(
                this.entryDetails?.entry_number,
                "TT",
                "Ramnarine Rampersad",
                "BR0285",
                this.customsEntryForm.regimeTypeCode,
                this.customsEntryForm.importerName,
                this.customsEntryForm.importerTaxIdentificationNumber,
                this.customsEntryForm.exporterName,
                this.customsEntryForm.exporterTaxIdentificationNumber,
                this.customsEntryForm.vesselName,
                this.customsEntryForm.portOfDischargeName,
                this.customsEntryForm.depositAmount,
                this.customsEntryForm.containerCharges,
                this.customsEntryForm.additionalCharges,
                this.customsEntryForm.localFee,
                this.customsEntryForm.freightAmount,
                this.customsEntryForm.insuranceAmount,
                this.customsEntryForm.otherCharges
            );
            const invoiceSummary = Utilities.formatCommercialData(
                this.commercialInvoices,
                this.customsEntryForm.freightAmount,
                this.totalFOBValue
            );
            worksheetObject.invoices = invoiceSummary;
            return worksheetObject;
        },
        // generateWorkSheet() {
        //     if(!this.checkMandatoryData()){
        //         return;
        //     }
        //     console.log("Generating WorkSheet");
        //     console.log(this.commercialInvoices);

        //     this.setInvoiceLineWorksheetData()
        //         .then(() => {
        //             const arrivalDateString = this.customsEntryForm.estimatedArrivalDate ? Utilities.getDateStringInputField(new Date(this.customsEntryForm.estimatedArrivalDate)) : '';
        //             console.log("Arrival Date: ", arrivalDateString);
        //             console.log("Setting Invoice Line Worksheet Data");
        //             const worksheetObject = Utilities.formatWorkSheetData(
        //                 this.entryDetails?.entry_number,
        //                 "TT",
        //                 "Ramnarine Rampersad",
        //                 "BR0285",
        //                 this.customsEntryForm.regimeTypeCode,
        //                 this.customsEntryForm.importerName,
        //                 this.customsEntryForm.importerTaxIdentificationNumber,
        //                 this.customsEntryForm.exporterName,
        //                 this.customsEntryForm.exporterTaxIdentificationNumber,
        //                 this.customsEntryForm.vesselName,
        //                 this.customsEntryForm.portOfDischargeName,
        //                 this.customsEntryForm.depositAmount,
        //                 this.customsEntryForm.containerCharges,
        //                 this.customsEntryForm.additionalCharges,
        //                 this.customsEntryForm.localFee,
        //                 this.customsEntryForm.freightAmount,
        //                 this.customsEntryForm.insuranceAmount,
        //                 this.customsEntryForm.otherCharges,
        //                 this.customsEntryForm.freightCurrency,
        //                 this.customsEntryForm.freightRateOfExchange,
        //                 this.customsEntryForm.insuranceCurrency,
        //                 this.customsEntryForm.insuranceRateOfExchange,
        //                 this.customsEntryForm.otherChargesCurrency,
        //                 this.customsEntryForm.otherChargesRateOfExchange,
        //                 this.manualExemptionApplied,
        //                 arrivalDateString
        //             );
        //             const invoiceSummary = Utilities.formatCommercialData(
        //                 this.commercialInvoices,
        //                 this.customsEntryForm.freightAmount,
        //                 this.totalFOBValue
        //             );
        //             worksheetObject.invoices = invoiceSummary;
        //             console.log("Worksheet Object: ", worksheetObject);

        //             SweetAlertConnector.worksheetFormatAlert().then((formatResponse) => {
        //                 SweetAlertConnector.showLoadingAlert("Generating worksheet...");
        //                 DatabaseConnector.generateWorkSheet(worksheetObject, formatResponse)
        //                     .then((worksheetResponse) => {
        //                         SweetAlertConnector.closeCurrentAlert();
        //                         SweetAlertConnector.showSuccessAlert("Worksheet Generated Successfully!");
        //                         const fileURL = window.URL.createObjectURL(
        //                             new Blob([worksheetResponse], {
        //                                 type: "application/pdf",
        //                             })
        //                         );
        //                         const fileLink = document.createElement("a");
        //                         const fileName = `Worksheet - ${this.entryDetails?.entry_number} - ${formatResponse}.pdf`;
        //                         fileLink.href = fileURL;
        //                         fileLink.setAttribute("download", fileName);
        //                         document.body.appendChild(fileLink);
        //                         fileLink.click();
        //                         fileLink.remove();
        //                     })
        //                     .catch((error) => {
        //                         console.error("Failed to generate worksheet:", error);
        //                         SweetAlertConnector.closeCurrentAlert();
        //                         SweetAlertConnector.showErrorAlert("Failed to generate worksheet!");
        //                     });
        //             });
        //         })
        //         .catch((error) => {
        //             // SweetAlertConnector.closeCurrentAlert();
        //             // SweetAlertConnector.showErrorAlert('Failed to generate worksheet!');
        //             console.error("Failed to set invoice line worksheet data:", error);
        //         });
        // },
        generateWorkSheet() {
            if(!this.checkMandatoryData()){
                return;
            }
            console.log("Generating WorkSheet");
            console.log(this.commercialInvoices);
            this.setInvoiceLineWorksheetData();

            const arrivalDateString = this.customsEntryForm.estimatedArrivalDate ? Utilities.getDateStringInputField(new Date(this.customsEntryForm.estimatedArrivalDate)) : '';
            console.log("Arrival Date: ", arrivalDateString);
            console.log("Setting Invoice Line Worksheet Data");
            const worksheetObject = Utilities.formatWorkSheetData(
                this.entryDetails?.entry_number,
                "TT",
                "Ramnarine Rampersad",
                "BR0285",
                this.customsEntryForm.regimeTypeCode,
                this.customsEntryForm.importerName,
                this.customsEntryForm.importerTaxIdentificationNumber,
                this.customsEntryForm.exporterName,
                this.customsEntryForm.exporterTaxIdentificationNumber,
                this.customsEntryForm.vesselName,
                this.customsEntryForm.portOfDischargeName,
                this.customsEntryForm.depositAmount,
                this.customsEntryForm.containerCharges,
                this.customsEntryForm.additionalCharges,
                this.customsEntryForm.localFee,
                this.customsEntryForm.freightAmount,
                this.customsEntryForm.insuranceAmount,
                this.customsEntryForm.otherCharges,
                this.customsEntryForm.freightCurrency,
                this.customsEntryForm.freightRateOfExchange,
                this.customsEntryForm.insuranceCurrency,
                this.customsEntryForm.insuranceRateOfExchange,
                this.customsEntryForm.otherChargesCurrency,
                this.customsEntryForm.otherChargesRateOfExchange,
                this.manualExemptionApplied,
                arrivalDateString
            );
            const invoiceSummary = Utilities.formatCommercialData(
                this.commercialInvoices,
                this.customsEntryForm.freightAmount,
                this.totalFOBValue
            );
            worksheetObject.invoices = invoiceSummary;
            console.log("Worksheet Object: ", worksheetObject);

            SweetAlertConnector.worksheetFormatAlert().then((formatResponse) => {
                SweetAlertConnector.showLoadingAlert("Generating worksheet...");
                DatabaseConnector.generateWorkSheet(worksheetObject, formatResponse)
                    .then((worksheetResponse) => {
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("Worksheet Generated Successfully!");
                        const fileURL = window.URL.createObjectURL(
                            new Blob([worksheetResponse], {
                                type: "application/pdf",
                            })
                        );
                        const fileLink = document.createElement("a");
                        const fileName = `Worksheet - ${this.entryDetails?.entry_number} - ${formatResponse}.pdf`;
                        fileLink.href = fileURL;
                        fileLink.setAttribute("download", fileName);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        fileLink.remove();
                    })
                    .catch((error) => {
                        console.error("Failed to generate worksheet:", error);
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showErrorAlert("Failed to generate worksheet!");
                    });
            });
        },
        async getPackageTypes() {
            try {
                const response = await fetch("./assets/packageTypes.json");
                const data = await response.json();
                this.packageTypeOptions = data;
            } catch (error) {
                this.packageTypeOptions = [{ value: "CT", text: "Carton" }];
                console.error("Error fetching data:", error);
            }
        },
        getInvoiceNumbers() {
            return this.commercialInvoices
                .map((invoice) => {
                    return `${invoice.invoice_number} \t ${invoice.invoice_date} \t`;
                })
                .join("\n");
        },
        async generateOvertimeForm() {
            let overtimeObject = {
                date: Utilities.getCurrentDateString(),
                request: "TO OBTAIN SERVICES OF ONE CUSTOMS OFFICER TO EXAMINE AND RELEASE",
                details: "",
                shipper: this.customsEntryForm.exporterName,
                importer: this.customsEntryForm.importerName,
                referenceNumber: this.entryDetails?.entry_number,
                importerAddress: this.customsEntryForm.importerName,
                declarantNumber: "BR0285",
                declarantName: "RAMNARINE RAMPERSAD",
                port: this.customsEntryForm.portOfDischargeName,
                startTime: "4:00PM",
            };

            SweetAlertConnector.overtimeAlert().then((response) => {
                console.log("Importers List: ", this.customsEntryForm.importers);
                const selectedImporter = this.customsEntryForm.importers.find(
                    (importer) => importer.id === this.customsEntryForm.importerId
                );
                const importerAddress = selectedImporter === undefined ? "" : selectedImporter.address;
                overtimeObject.details = response.cargoDetails;
                overtimeObject.startTime = response.startTime;
                overtimeObject.importerAddress = `${this.customsEntryForm.importerName}\n${importerAddress}`;

                SweetAlertConnector.showLoadingAlert("Generating Overtime Form...");
                DatabaseConnector.generateOvertimeForm(overtimeObject)
                    .then((response) => {
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("Overtime Form Generated Successfully!");
                        const fileURL = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
                        const fileLink = document.createElement("a");
                        const fileName = `Overtime Form - ${this.entryDetails?.entry_number}.pdf`;
                        fileLink.href = fileURL;
                        fileLink.setAttribute("download", fileName);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        fileLink.remove();
                    })
                    .catch((error) => {
                        console.error("Failed to generate Overtime Form:", error);
                        SweetAlertConnector.showErrorAlert("Failed to generate Overtime Form!");
                    });
            });
        },
        async generateTTC84() {
            console.log("Generating TTC84");
            let c84Obj = {
                declarationNumber: this.entryDetails?.entry_number,
                declarationDate: Utilities.getCurrentDateString(),
                regimeCode: "C4",
                declarantName: "RAMNARINE RAMPERSAD",
                declarantNumber: "BR0285",
                referenceNumber: this.entryDetails?.entry_number,
                importer: this.customsEntryForm.importerName,
                importNumber: "",
                declarationContent: "",
                CPCCode: "4000",
                extensionCode: "",
                signatoryName: "",
                signatoryStatus: "",
                presigned: false,
            };
            const selectedImporter = this.customsEntryForm.importers.find(
                (importer) => importer.id === this.customsEntryForm.importerId
            );
            const importerTaxIdentificationNumber = selectedImporter?.taxIdentificationNumber;
            c84Obj.importNumber = importerTaxIdentificationNumber;
            let signatories = await DatabaseConnector.fetchDeclarants();
            console.log("Signatories: ", signatories);
            signatories = signatories.filter(
                (signatory) => signatory.importerCode === this.customsEntryForm.importerId
            );
            DatabaseConnector.getSpecialExemptionDeclarations(this.customsEntryForm.importerId)
                .then((response) => {
                    const declarationNumbers = response.map((declaration) => declaration.declaration_number);
                    SweetAlertConnector.form(declarationNumbers, signatories).then((selection) => {
                        c84Obj.presigned = selection.generatePreSigned;
                        const selectedDeclearationIndex = parseInt(selection.select1);
                        const selectedDelcaration = response[selectedDeclearationIndex];
                        const invoiceNumbers = this.commercialInvoices.reduce((acc, invoice) => {
                            const newSegment = `Inv# ${invoice.invoice_number} DD ${invoice.invoice_date}, `;
                            return `${acc}${newSegment}`;
                        }, "").trimEnd();
                        
                        const formattedInvoiceNumbers = invoiceNumbers.split(', ').reduce((acc, segment) => {
                            const newSegment = `${segment}, `;
                            // Check if adding the new segment to the current line exceeds 84 characters
                            if (acc.length - acc.lastIndexOf('\n') - 1 + newSegment.length > 84) {
                                // Start a new line if the condition is met
                                return `${acc.trimEnd()}\n${newSegment}`;
                            }
                            return `${acc}${newSegment}`;
                        }, "").trimEnd();
                        console.log("Invoice Numbers: ", formattedInvoiceNumbers);
                        
                        // c84Obj.declarationContent = `${selectedDelcaration.content.replace(/\\n/g, "\n")}\n\n${formattedInvoiceNumbers}`;
                        c84Obj.extensionCode = selectedDelcaration.extensionCode;

                        const selectedSignatory = signatories.find(
                            (signatory) => signatory.id === parseInt(selection.select2)
                        );
                        // c84Obj.signatoryName = selectedSignatory.name;
                        // c84Obj.signatoryStatus = selectedSignatory.status;
                        c84Obj.declarationContent = `${selectedDelcaration.content.replace(/\\n/g, "\n")}\n${formattedInvoiceNumbers}\n${selectedSignatory.name}\n${selectedSignatory.status}`;
                        DatabaseConnector.generateC84Form(c84Obj)
                            .then((response) => {
                                SweetAlertConnector.closeCurrentAlert();
                                SweetAlertConnector.showSuccessAlert("C84 Form Generated Successfully!");
                                const fileURL = window.URL.createObjectURL(
                                    new Blob([response], {
                                        type: "application/pdf",
                                    })
                                );
                                const fileLink = document.createElement("a");
                                const fileName = `C84 Form - ${this.entryDetails?.entry_number}.pdf`;
                                fileLink.href = fileURL;
                                fileLink.setAttribute("download", fileName);
                                document.body.appendChild(fileLink);
                                fileLink.click();
                                fileLink.remove();
                            })
                            .catch((error) => {
                                console.error("Failed to generate C84 Form:", error);
                                SweetAlertConnector.showErrorAlert("Failed to generate C84 Form!");
                            });
                    });
                    // c84Obj.specialExemptionDeclarations = response
                })
                .catch((error) => {
                    console.error("Failed to get special exemption declarations:", error);
                });
            console.log("C84 Object: ", c84Obj);
        },
        async generateDepositForm() {
            console.log("Generating Deposit Form");
            console.log(this.availableCPCCodes);
            console.log(this.customsEntryForm.regimeTypeOptions);
            const portObject = this.customsEntryForm.ports.find(
                (port) => port.id === this.customsEntryForm.portOfDischargeId
            );
            const portCode = portObject == undefined ? "" : portObject.portCode;
            const exporterCountryName = this.countries.find(
                (country) => country.code === this.customsEntryForm.countryOfOrigin
            )?.name;
            const selectedModeOfTransport = this.customsEntryForm.modeOfTransportOOptions.find(
                (mode) => mode.modeOfTransport === this.customsEntryForm.modeOfTransport
            );
            const freightTypeCode = selectedModeOfTransport.freightTypes.find(
                (freightType) => freightType.name === this.customsEntryForm.freightType
            )?.code;
            SweetAlertConnector.depositReasonAlert().then((reasonResponse) => {
                const deposityData = Utilities.formatDepositData(
                    this.customsEntryForm.regimeTypeCode,
                    this.customsEntryForm.waybillNumber,
                    this.customsEntryForm.waybillDate,
                    this.customsEntryForm.incoTerms,
                    this.customsEntryForm.importerName,
                    this.customsEntryForm.importerTaxIdentificationNumber,
                    "Ramnarine Rampersad",
                    "BR0285",
                    this.entryDetails?.entry_number,
                    this.customsEntryForm.exporterName,
                    exporterCountryName,
                    this.customsEntryForm.exporterCountry,
                    this.customsEntryForm.vesselName,
                    freightTypeCode,
                    this.customsEntryForm.portOfDischargeName,
                    portCode,
                    this.customsEntryForm.depositAmount,
                    reasonResponse
                );
                try {
                    SweetAlertConnector.showLoadingAlert("Generating Deposit Form...");
                    DatabaseConnector.generateDepositForm(deposityData)
                        .then((response) => {
                            SweetAlertConnector.closeCurrentAlert();
                            SweetAlertConnector.showSuccessAlert("Deposit Form Generated Successfully!");
                            const fileURL = window.URL.createObjectURL(
                                new Blob([response], {
                                    type: "application/pdf",
                                })
                            );
                            const fileLink = document.createElement("a");
                            const fileName = `Deposit Form - ${this.entryDetails?.entry_number}.pdf`;
                            fileLink.href = fileURL;
                            fileLink.setAttribute("download", fileName);
                            document.body.appendChild(fileLink);
                            fileLink.click();
                            fileLink.remove();
                        })
                        .catch((error) => {
                            SweetAlertConnector.showErrorAlert("Failed to generate Deposit Form!");
                            console.error("Failed to generate Deposit Form:", error);
                        });
                } catch (error) {
                    console.error("Failed to generate Deposit Form:", error);
                }
            });
        },
        goToCustomsDeclarationPage() {
            const invoiceNumbers = this.getInvoiceNumbers();
            let netPrice = this.netInvoicePrice + this.customsEntryForm.otherCharges
            if (this.customsEntryForm.incoTerms === "CIF") {
                netPrice = netPrice + this.customsEntryForm.freightAmount + this.customsEntryForm.insuranceAmount;
            }
            console.log("Invoice Numbers: ", invoiceNumbers);
            console.log("Incoterms: ", this.customsEntryForm.incoTerms);
            console.log("Invoice Total: ", this.netInvoicePrice);
            this.$router.push({
                name: "CustomsDeclarationForm",
                params: {
                    entryId: this.entryId,
                },
                query: {
                    buyerId: this.customsEntryForm.importerId,
                    sellerId: this.customsEntryForm.exporterId,
                    invoiceNumber: invoiceNumbers,
                    invoiceTotal: netPrice,
                    currency: this.commercialInvoices[0].currency,
                    rateOfExchange: this.commercialInvoices[0].rate_of_exchange,
                    freight: this.customsEntryForm.freightAmount * this.customsEntryForm.freightRateOfExchange,
                    insurance: this.customsEntryForm.insuranceAmount * this.customsEntryForm.insuranceRateOfExchange,
                    incoTerms: this.customsEntryForm.incoTerms,
                    referenceNumber: this.entryDetails.entry_number,
                },
            });
        },
        updateWaybillDate(event) {
            // Update entryDetails.waybill_date when the input changes
            this.entryDetails.waybill_date = event.target.value;
        },
        countryChange() {
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    if (
                        !item.country_of_origin ||
                        item.country_of_origin.trim().length === 0 ||
                        item.country_of_origin === "N/A" ||
                        item.country_of_origin === undefined ||
                        item.country_of_origin === null
                    ) {
                        item.country_of_origin = this.entryDetails.country_of_origin;
                    }
                });
            });
        },
        async fetchCountries() {
            try {
                const response = await fetch("https://restcountries.com/v3/all");
                const data = await response.json();
                this.countries = data.map((country) => ({
                    name: country.name.common,
                    code: country.cca2,
                }));
            } catch (error) {
                console.error("Failed to fetch countries:", error);
            }
        },
        formatInvoiceData(invoice) {
            return {
                invoice_id: invoice.invoice_id,
                invoice_number: invoice.invoice_number,
                invoice_date: invoice.invoice_date,
                invoice_total: invoice.invoice_total,
                sub_total: invoice.sub_total,
                supplier_name: invoice.vendor_name,
                taxed_amount: invoice.taxed_amount,
                supplier_address: invoice.supplier_address,
                purchase_order_number: invoice.purchase_order_number,
                inland: invoice.inland === "" ? "0.00" : parseFloat(invoice.inland),
                insurance: invoice.insurance === "" ? "0.00" : parseFloat(invoice.insurance),
                other_charges: invoice.otherCharges === "" ? "0.00" : parseFloat(invoice.otherCharges),
                entry_id: invoice.entry_id,
                currency: invoice.currency,
                rate_of_exchange: invoice.rate_of_exchange,
            };
        },
        getTotalNumberOfLines() {
            console.log("Calculating total number of lines");
            console.log(this.commercialInvoices);
            let total = 0;
            for (let invoice of this.commercialInvoices) {
                total += invoice.lines ? invoice.lines.length : 0;
            }
            console.log("Total Number of Lines: ", total);
            this.totalNumberOfLines = total;
        },
        formatInvoiceLinesData(invoiceLines) {
            return invoiceLines.map((line) => {
                return {
                    invoice_line_id: line.invoice_line_id,
                    description: line.description,
                    quantity: parseFloat(line.quantity),
                    unit_price: parseFloat(line.unit_price),
                    extension_price: parseFloat(line.extension_price),
                    tariff_code: line.tariff_code,
                    product_code: line.product_code,
                    country_of_origin: line.country_of_origin,
                    cpc_code: line.cpcCode,
                    npc_code: line.npcCode,
                    vat_applicable: line.vatApplicable,
                };
            });
        },
        async addInvoiceLines(){
            return DatabaseConnector.insertInvoiceLines(this.linesToBeAdded)
                .then((response) => {
                    console.log("Invoice Lines Added:", response);
                    this.linesToBeAdded = [];
                    return response;
                })
                .catch((error) => {
                    console.error("Failed to add invoice lines:", error);
                    return Promise.reject(error);
                });
        },
        async addInvoices(){
            console.log("Adding Invoices...", this.invoicesToBeAdded);
            const invoicesRequest = {
                entry_id : this.entryId,
                invoices: this.invoicesToBeAdded
            }
            return DatabaseConnector.insertNewInvoice(invoicesRequest)
                .then((response) => {
                    console.log("Invoices Added:", response);
                    this.invoicesToBeAdded = [];
                    return response;
                })
                .catch((error) => {
                    console.error("Failed to add invoices:", error);
                    return Promise.reject(error);
                });
        },
        saveData() {
            console.log("Saving data...");
            this.loading = true;

            let promises = [this.updateEntryData(), this.updateWaybillData(), this.saveInvoiceDataOptimized()];
            if(this.linesToBeAdded.length > 0){
                promises.push(this.addInvoiceLines());
            }
            if(this.invoicesToBeAdded.length > 0){
                promises.push(this.addInvoices());
            }
            if (this.linesToBeDeleted.length > 0) {
                SweetAlertConnector.displayConfirmationDialog().then((result) => {
                    if (result.isConfirmed) {
                        promises.push(this.deleteInvoiceLines());
                    }

                    SweetAlertConnector.showLoadingAlert("Saving Data...");
                    console.log(result);
                    console.time('saveInvoiceData Execution Time');
                    Promise.all(promises)
                        .then(([entryResponse, waybillResponse]) => {
                            console.log("Entry details updated:", entryResponse.data);
                            console.log("Waybill details updated:", waybillResponse.data);
                            SweetAlertConnector.closeCurrentAlert();
                            console.timeEnd('saveInvoiceData Execution Time');
                            SweetAlertConnector.showSuccessAlert("All Data Saved successfully!");
                        })
                        .catch((error) => {
                            console.timeEnd('saveInvoiceData Execution Time');
                            console.error("There was an error saving the data!", error);
                            SweetAlertConnector.showErrorAlert("Failed to save Entry Data!");
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                });
            } else {
                SweetAlertConnector.showLoadingAlert("Saving Data...");
                Promise.all(promises)
                    .then(([entryResponse, waybillResponse]) => {
                        console.log("Entry details updated:", entryResponse.data);
                        console.log("Waybill details updated:", waybillResponse.data);
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("All Data Saved successfully!");
                    })
                    .catch((error) => {
                        console.error("There was an error saving the data!", error);
                        SweetAlertConnector.showErrorAlert("Failed to save Entry Data!");
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        toggleOptions() {
            console.log(this.freightType);
        },
        updateEntryFields(fieldName, value) {
            this.entryDetails[fieldName] = value;
        },

        // updateInvoiceFields(invoiceId, fieldName, value) {
        //   const invoice = this.commercialInvoices.find(invoice => invoice.invoice_id === invoiceId);
        //   invoice[fieldName] = value;
        // },
        updateInvoiceData(data, field, value) {
            data[field] = value;
            console.log(data);
        },
        convertTinyIntoBoolean(tiny) {
            return tiny === 1 ? true : false;
        },
        async fetchAllDetails() {
            const api_url = process.env.VUE_APP_API_BASE_URL;
            console.log("Fetching details");
            this.loading = true;
            this.dataRetrieved = false;
            try {
                const entryDetailsPromise = axios.get(`${api_url}entry-details/${this.entryId}`);
                const commercialInvoicesPromise = axios.get(`${api_url}commercial-invoices-with-lines/${this.entryId}`);

                const [entryDetailsResponse, commercialInvoicesResponse] = await Promise.all([
                    entryDetailsPromise,
                    commercialInvoicesPromise,
                ]);
                this.dataRetrieved = true;

                let waybillDate = entryDetailsResponse.data.waybill_date;
                let estimatedArrivalDate = entryDetailsResponse.data.estimated_arrival_date;

                const dateString = waybillDate ? Utilities.formatDateReadFromDB(waybillDate) : "";

                const estimateArrivalDateString = estimatedArrivalDate ? Utilities.formatDateReadFromDB(estimatedArrivalDate): "";

                this.entryDetails = entryDetailsResponse.data;
                this.customsEntryForm.importerId = this.entryDetails.importer;
                this.customsEntryForm.exporterId = this.entryDetails.shipper;
                this.customsEntryForm.waybillNumber = this.entryDetails.waybill_number;
                this.customsEntryForm.waybillDate = dateString;
                this.waybillSourceURL = this.entryDetails.file_url;
                this.customsEntryForm.estimatedArrivalDate = estimateArrivalDateString;
                this.customsEntryForm.modeOfTransport = this.entryDetails.mode_of_transport;
                this.customsEntryForm.freightType = this.entryDetails.freight_type;
                this.customsEntryForm.vesselId = this.entryDetails.vessel;
                this.customsEntryForm.portOfDischargeId = this.entryDetails.port_of_discharge;
                this.customsEntryForm.netWeight = this.entryDetails.net_weight;
                this.customsEntryForm.grossWeight = this.entryDetails.gross_weight;
                this.customsEntryForm.numberOfPackages = this.entryDetails.package_quantity;
                this.customsEntryForm.packageType = this.entryDetails.package_type;
                this.customsEntryForm.marksAndNumbers = this.entryDetails.marks_and_numbers;
                this.customsEntryForm.totalCargoValue = this.entryDetails.invoice_total;
                this.customsEntryForm.freightAmount = this.entryDetails.freight_charge;
                this.customsEntryForm.insuranceAmount = this.entryDetails.insurance_amount ?? 0.0;
                this.customsEntryForm.otherCharges = this.entryDetails.other_charges ?? 0.0;
                this.customsEntryForm.freightCurrency = this.entryDetails.freight_currency || 'USD';
                this.customsEntryForm.insuranceCurrency = this.entryDetails.insurance_currency || 'USD';
                this.customsEntryForm.otherChargesCurrency = this.entryDetails.other_charges_currency || 'USD';
                this.customsEntryForm.rateOfExchange = this.entryDetails.rate_of_exchange;
                this.customsEntryForm.countryOfOrigin = this.entryDetails.country_of_origin;
                this.customsEntryForm.countryOfFinalDestination = this.entryDetails.country_of_final_destination;
                this.customsEntryForm.regimeType = this.entryDetails.regimeType;

                this.customsEntryForm.regimeTypeCode = this.entryDetails.regimeTypeCode;
                this.customsEntryForm.depositAmount = this.entryDetails.deposit;
                this.customsEntryForm.containerCharges = this.entryDetails.container_charges;
                this.customsEntryForm.additionalCharges = this.entryDetails.additional_charges;
                this.customsEntryForm.localFee = this.entryDetails.local_fee;
                this.customsEntryForm.declarantId = this.entryDetails.declarant;
                this.customsEntryForm.incoTerms = this.entryDetails.incoterms;
                this.customsEntryForm.countryLastProvinance = this.entryDetails.country_last_provenance;
                this.customsEntryForm.tradingCountry = this.entryDetails.trading_country;

                this.classificationApproved = this.entryDetails.classification_approved;
                this.classificationApprovedBy = this.entryDetails.classification_approved_by;
                this.entryStatus = this.entryDetails.entry_status || "";
                console.log("Commercial Invoice Raw Data: ", commercialInvoicesResponse.data);
                this.commercialInvoices = commercialInvoicesResponse.data;
                this.retrieveInvoiceDocumentSourceURL();
                this.inializeTaxes();
                this.parseInvoiceData();
                this.getTotalNumberOfLines();
                this.getRegimeTypes();

                console.log("Entry details:", this.entryDetails);
                console.log("Customs Entry:", this.customsEntryForm);
                console.log("Commercial invoices:", this.commercialInvoices);
            } catch (error) {
                this.error = "Failed to load entry details: " + (error.response?.data || error.message);
                console.error("Failed to load entry details:", error);
                SweetAlertConnector.showErrorAlert("Failed to load Entry Details! Please Reach out to Administrator");
            } finally {
                this.loading = false;
            }
        },
        retrieveInvoiceDocumentSourceURL() {
            for (let invoice of this.commercialInvoices) {
                const entry = {};
                entry.name = invoice.invoice_number;
                entry.url = invoice.file_url;
                this.invoiceDocumentSourceURLs.push(entry);
            }
        },
        formatDate(myDate) {
            const date = new Date(myDate);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        formatDateForInput(dateString) {
            return moment(dateString).tz("UTC").format("YYYY-MM-DD");
        },
        parseInvoiceData() {
            console.log("Parsing Invoice Data");
            this.commercialInvoices.forEach((invoice) => {
                invoice.vendor_name = invoice.supplier_name;
                invoice.otherCharges = parseFloat(invoice.other_charges);
                // invoice.invoice_date = this.formatDateForInput(invoice.invoice_date);
                invoice.lines.forEach((line) => {
                    line.quantity = parseFloat(line.quantity).toFixed(2);
                    line.unit_price = parseFloat(line.unit_price);
                    line.extension_price = parseFloat(line.extension_price).toFixed(2);
                    line.vatApplicable = this.convertTinyIntoBoolean(line.vat_applicable);
                    line.cpcCode = line.cpc_code;
                    line.npcCode = line.npc_code;
                });
            });
        },
        async getRegimeTypes() {
            DatabaseConnector.fetchCpcCodes("TT").then((data) => {
                this.customsEntryForm.regimeTypeOptions = data;
            });
        },
        async updateWaybillData() {
            this.waybill = {
                waybill_number: this.customsEntryForm.waybillNumber,
                waybill_date: this.customsEntryForm.waybillDate,
                estimated_arrival_date: this.customsEntryForm.estimatedArrivalDate,
                mode_of_transport: this.customsEntryForm.modeOfTransport,

                freight_type: this.customsEntryForm.freightType,
                marks_and_numbers: this.customsEntryForm.marksAndNumbers,
                package_type: this.customsEntryForm.packageType,
                cpc_code: this.entryDetails.cpc_code,
                npc_code: this.entryDetails.npc_code,
                package_quantity: this.customsEntryForm.numberOfPackages,
                country_of_origin: this.customsEntryForm.countryOfOrigin,
                country_of_final_destination: this.customsEntryForm.countryOfFinalDestination,
                importer: this.customsEntryForm.importerId,
                shipper: this.customsEntryForm.exporterId,
                vessel: this.customsEntryForm.vesselId,
                port_of_discharge: this.customsEntryForm.portOfDischargeId,
                entry_id: this.entryDetails.entry_id,
                waybill_id: this.entryDetails.waybill_id,
            };

            console.log("Saving Waybill Data: ", this.waybill);
            console.log(`${process.env.VUE_APP_API_BASE_URL}waybill/${this.waybill.waybill_id}`);
            return axios.put(`${process.env.VUE_APP_API_BASE_URL}waybill/${this.waybill.waybill_id}`, this.waybill);
        },

        async deleteInvoiceLines() {
            console.log("Deleting Invoice Lines");
            console.log(this.linesToBeDeleted);
            return DatabaseConnector.deleteInvoiceLines(this.linesToBeDeleted);
        },

        async updateEntryData() {
            this.entry = {
                mawi_invoice: this.entryDetails.mawi_invoice,
                entry_date: this.entryDetails.entry_date,
                invoice_total: this.entryDetails.invoice_total,
                supplier_name: this.entryDetails.supplier_name,
                net_weight: this.customsEntryForm.netWeight,
                gross_weight: this.customsEntryForm.grossWeight,
                entry_number: this.entryDetails.entry_number,
                freight_charge: this.customsEntryForm.freightAmount,
                insurance_amount: this.customsEntryForm.insuranceAmount ?? 0.0,
                other_charges: this.customsEntryForm.otherCharges ?? 0.0,
                freight_currency: this.customsEntryForm.freightCurrency,
                insurance_currency: this.customsEntryForm.insuranceCurrency,
                other_charges_currency: this.customsEntryForm.otherChargesCurrency,
                rate_of_exchange: this.entryDetails.rate_of_exchange,
                consignee: this.entryDetails.consignee,
                declarant: this.customsEntryForm.declarantId,
                incoterms: this.customsEntryForm.incoTerms,
                regime_type: this.customsEntryForm.regimeType,
                deposit: this.customsEntryForm.depositAmount,
                container_charges: this.customsEntryForm.containerCharges,
                additional_charges: this.customsEntryForm.additionalCharges,
                local_fee: this.customsEntryForm.localFee,
                country_last_provenance: this.customsEntryForm.countryLastProvinance,
                trading_country: this.customsEntryForm.tradingCountry,
                classification_status: this.classificationStatus,
                classification_approved: this.classificationApproved,
                classification_approved_by: this.classificationApprovedBy,
                date_classified: this.dateClassified,
                entry_status: this.entryStatus,
            };

            console.log("Saving Entry", this.entry);
            return axios.put(
                `${process.env.VUE_APP_API_BASE_URL}customs-entry/${this.entryDetails.entry_id}`,
                this.entry
            );
        },

        async saveInvoiceData() {
            
            const updatePromises = this.commercialInvoices.map((invoice) => {
                const formattedInvoiceData = this.formatInvoiceData(invoice);
                const formattedInvoiceLinesData = this.formatInvoiceLinesData(invoice.lines);

                const requestData = {
                    invoiceData: formattedInvoiceData,
                    invoiceLines: formattedInvoiceLinesData,
                };

                console.log("Updating invoice and invoice lines:", requestData);

                // Store the promise without calling then() or catch() on it yet
                return axios.put(
                    `${process.env.VUE_APP_API_BASE_URL}update-invoice-and-lines/${formattedInvoiceData.invoice_id}`,
                    requestData
                );
            });

            return Promise.all(updatePromises);
        },
        async saveInvoiceDataOptimized() {
            try {
                // Format all invoices
                console.time('saveInvoiceData Execution Time');
                const formattedInvoices = this.commercialInvoices.map(invoice => this.formatInvoiceData(invoice));

                // Format all invoice lines
                const formattedInvoiceLines = this.commercialInvoices.flatMap(invoice => this.formatInvoiceLinesData(invoice.lines));

                // Create request payloads
                const invoicesRequestData = { invoices: formattedInvoices };
                const invoiceLinesRequestData = { invoiceLines: formattedInvoiceLines };

                console.log("Updating multiple invoices:", invoicesRequestData);
                console.log("Updating multiple invoice lines:", invoiceLinesRequestData);

                // Send the update requests concurrently
                const [invoicesResponse, invoiceLinesResponse] = await Promise.all([
                    axios.put(`${process.env.VUE_APP_API_BASE_URL}update-multiple-invoices`, invoicesRequestData),
                    axios.put(`${process.env.VUE_APP_API_BASE_URL}update-multiple-invoice-lines`, invoiceLinesRequestData)
                ]);

                console.log('Invoices update response:', invoicesResponse.data);
                console.log('Invoice lines update response:', invoiceLinesResponse.data);

                console.timeEnd('saveInvoiceData Execution Time');

                return { invoicesResponse, invoiceLinesResponse };
            } catch (error) {
                console.error('Error updating invoices and invoice lines:', error);
                throw error;
            }
        },


        checkTHNCodes() {
            // Iterate through the array
            let invoiceData = this.commercialInvoices;
            invoiceData.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    // Check if the THN code is missing
                    if (
                        !line.tariff_code ||
                        line.tariff_code.trim() === "" ||
                        line.tariff_code === "N/A" ||
                        line.tariff_code === undefined ||
                        line.tariff_code === null
                    ) {
                        // Display an error message

                        return false; // Return false if any item is missing a THN code
                    }
                });
            });
            return true; // Return true if all items have a THN code
        },

        async sendRPARequest(){
            // const flowUrl = 'https://prod-127.westus.logic.azure.com:443/workflows/b219026190bf4867b84dd8498580e0b9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=P31ddYGAbtAtr7nEcJEc3TbOkgT75Ekk-viC_QcwcAg';
            const flowUrl = 'https://prod-80.westus.logic.azure.com:443/workflows/5d29cdede5a040f4985afce5f5aa9036/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ncChiHbstv9-5mgsi9y5NE7Vd3jFNIq93iiVmXvhwqc';
            console.log("Sending RPA Request");
            console.log("Entry Details: ", this.entryDetails);
            console.log("URL ", flowUrl);
  
            const payload = {
                key: this.entryDetails?.entry_number,
            };

            try {
                const response = await fetch(flowUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
                });

                if (response.ok) {
                    console.log('Flow triggered successfully');
                } else {
                    console.error('Failed to trigger flow:', response.statusText);
                }
            } catch (error) {
                console.error('Failed to trigger flow:', error);
            }
        },

        generateAsycudaXML() {
            console.log("Generating Asycuda XML");
            if(!this.checkMandatoryData()){
                return;
            }
            const selectedPort = this.customsEntryForm.ports.find(
                (port) => port.id === this.customsEntryForm.portOfDischargeId
            );
            const selectedImporter = this.customsEntryForm.importers.find(
                (importer) => importer.id === this.customsEntryForm.importerId
            );
            const selectedExporter = this.customsEntryForm.exporters.find(
                (exporter) => exporter.id === this.customsEntryForm.exporterId
            );
            const general_procedural_code =
                this.customsEntryForm.regimeType == undefined ? "" : this.customsEntryForm.regimeTypeCode?.slice(2);
            const typeOfDeclaration =
                this.customsEntryForm.regimeType == undefined ? "" : this.customsEntryForm.regimeTypeCode?.slice(0, 2);
            console.log("Tye Of Declaration: ", typeOfDeclaration);
            
            if (
                typeOfDeclaration === "IM" &&
                (selectedImporter.taxIdentificationNumber === undefined ||
                    null ||
                    selectedImporter.taxIdentificationNumber.trim().length === 0)
            ) {
                SweetAlertConnector.showErrorAlert(
                    "Missing Importer Tax Identification Number! Please Update Importer Details!"
                );
                return;
            }
            console.log("Exporter: ", selectedExporter);
            if (
                typeOfDeclaration === "EX" &&
                (selectedExporter.taxIdentificationNumber === undefined ||
                    null ||
                    selectedExporter.taxIdentificationNumber.trim().length === 0)
            ) {
                SweetAlertConnector.showErrorAlert(
                    "Missing Exporter Tax Identification Number! Please Update Exporter Details!"
                );
                return;
            }

            const selectedDeclarant = this.customsEntryForm.declarants.find(
                (declarant) => declarant.id === this.customsEntryForm.declarantId
            );
            const selectedCountry = this.countries.find(
                (country) => country.code === this.customsEntryForm.countryOfOrigin
            );
            const xmlData = Utilities.generateXMLData(
                this.commercialInvoices,
                this.entryDetails?.entry_number,
                this.customsEntryForm.waybillNumber,
                this.customsEntryForm.grossWeight,
                this.customsEntryForm.netWeight,
                this.customsEntryForm.numberOfPackages,
                this.customsEntryForm.packageType,
                this.customsEntryForm.marksAndNumbers,
                this.customsEntryForm.freightAmount,
                this.availableCPCCodes,
                this.totalFOBValue,
                this.tradeAgreements
            );

            const requestData = {
                reference_number: this.entryDetails?.entry_number,
                group_items: true,
                waybill: {
                    number: this.customsEntryForm.waybillNumber,
                    external_freight: this.customsEntryForm.freightAmount,
                    insurance:
                        this.customsEntryForm.insuranceAmount == undefined
                            ? 0.0
                            : this.customsEntryForm.insuranceAmount,
                    other_charges:
                        this.customsEntryForm.otherCharges == undefined ? 0.0 : this.customsEntryForm.otherCharges,
                    importer: {
                        name: `${selectedImporter?.name}\n${selectedImporter?.address}`,
                        tax_identification_number:
                            selectedImporter.taxIdentificationNumber == undefined || null
                                ? ""
                                : selectedImporter.taxIdentificationNumber,
                    },
                    exporter: {
                        name: `${selectedExporter?.name}\n${selectedExporter?.address}`,
                        tax_identification_number:
                            selectedExporter.taxIdentificationNumber == undefined || null
                                ? ""
                                : selectedExporter.taxIdentificationNumber,
                    },
                    declarant: {
                        name: selectedDeclarant?.name,
                        tax_identification_number:
                            selectedDeclarant.taxIdentificationNumber == undefined || null
                                ? ""
                                : selectedDeclarant.taxIdentificationNumber,
                    },
                    charges: {
                        external_freight: {
                            amount: this.customsEntryForm.freightAmount,
                            currency: this.customsEntryForm.freightCurrency,
                            rate_of_exchange: this.customsEntryForm.freightRateOfExchange,
                        },
                        insurance: {
                            amount: this.customsEntryForm.insuranceAmount,
                            currency: this.customsEntryForm.insuranceCurrency,
                            rate_of_exchange: this.customsEntryForm.insuranceRateOfExchange,
                        },
                        other_charges: {
                            amount: this.customsEntryForm.otherCharges,
                            currency: this.customsEntryForm.otherChargesCurrency,
                            rate_of_exchange: this.customsEntryForm.otherChargesRateOfExchange,
                        },
                    },
                    mode_of_transport:
                        this.customsEntryForm.modeOfTransport === "Ocean"
                            ? "1"
                            : this.customsEntryForm.modeOfTransport === "Air"
                            ? "4"
                            : "",
                    container_flag: false,
                    vessel: this.customsEntryForm.vesselName,
                    country_of_origin: this.customsEntryForm.countryOfOrigin,
                    country_of_destination:
                        this.customsEntryForm.countryOfFinalDestination == undefined
                            ? ""
                            : this.customsEntryForm.countryOfFinalDestination,
                    number_of_packages: this.customsEntryForm.numberOfPackages + "",
                    type_of_declaration: typeOfDeclaration,
                    general_procedural_code: general_procedural_code,
                    office_of_destination: selectedPort.asycudaCode,
                    country_last_provinance: this.customsEntryForm.countryLastProvinance,
                    trading_country: this.customsEntryForm.tradingCountry,
                    country_export: this.customsEntryForm.countryOfOrigin,
                    delivery_terms: this.customsEntryForm.incoTerms,
                    delivery_terms_place: this.customsEntryForm.countryOfOrigin,
                    place_of_terms: selectedCountry?.name,
                    local_currency: "TTD",
                    office_of_entry: selectedPort.asycudaCode,
                    location_of_goods: "",
                },
                invoices: xmlData,
            };
            console.log("XML Data: ", requestData);
            SweetAlertConnector.showLoadingAlert("Generating Asycuda XML...");
            DatabaseConnector.generateAsycudaXML(requestData)
                .then((response) => {
                    this.sendRPARequest();
                    SweetAlertConnector.closeCurrentAlert();
                    SweetAlertConnector.showSuccessAlert("Asycuda XML Generated Successfully!");
                    if(this.entryStatus !== null && this.entryStatus === "CLASSIFICATION_OKAY"){
                        console.log("Setting to partial storage");
                        this.setToPartialStorage();
                    }
                    const fileURL = window.URL.createObjectURL(new Blob([response], { type: "application/xml" }));
                    const fileLink = document.createElement("a");
                    const fileName = `Asycuda XML - ${this.entryDetails?.entry_number}.xml`;
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                })
                .catch((error) => {
                    console.error("Failed to generate Asycuda XML:", error);
                    SweetAlertConnector.showErrorAlert("Failed to generate Asycuda XML!");
                });
        },
    },
};

function verifyInvoiceData() {
    this.displayDocumentVerificationView = !this.displayDocumentVerificationView;
}

async function openWaybill(){
    const waybillURL = this.waybillSourceURL;
    if(!waybillURL || waybillURL.trim().length === 0){
        SweetAlertConnector.showErrorAlert("No Waybill Found!");
        return;
    }
    console.log("Opening PDF");
    SweetAlertConnector.showLoadingAlert("Opening PDF...");

    try {

        //Automagically download the file to the users machine. 
        const response = await fetch(waybillURL);
        if (!response.ok) {
            throw new Error('Failed to fetch the PDF');
        }

        // Create a Blob from the response
        const blob = await response.blob();
        const fileName = `${this.entryDetails?.entry_number} - Waybill.pdf`;

        // Automatically download the file
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);


        SweetAlertConnector.closeCurrentAlert();
        SweetAlertConnector.showSuccessAlert("PDF Opened Successfully!");

        const newWindow = window.open('', '_blank', 'width=800,height=600');

        if (newWindow) {
            newWindow.document.write(`<iframe src="${waybillURL}" style="width:100%; height:100%;" frameborder="0"></iframe>`);
            newWindow.document.title = "PDF Document";
        } else {
            console.error('Failed to open new window.');
        }
    } catch (error) {
        console.error('Error:', error);
        SweetAlertConnector.closeCurrentAlert();
        SweetAlertConnector.showErrorAlert('Failed to open PDF!');
    }
}

async function mergeAndOpenPDFs(){
    console.log("Merging and Opening PDFs");
    SweetAlertConnector.showLoadingAlert("Merging and Opening Invoices...");
    const pdfs = this.invoiceDocumentSourceURLs.map((invoice) => invoice.url);
    console.log(pdfs);
    try {
        const api_url = process.env.VUE_APP_API_BASE_URL;
        const response = await fetch(`${api_url}merge-pdfs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pdfUrls: pdfs }),
        });

        if (!response.ok) {
          throw new Error('Failed to merge PDFs');
        }
        SweetAlertConnector.closeCurrentAlert();
        SweetAlertConnector.showSuccessAlert("PDFs Merged Successfully!");

        const blob = await response.blob();
        //Automagically download the file to the users machine.
        const fileName = `${this.entryDetails?.entry_number} - Commercial Invoices.pdf`;

        // Automatically download the merged PDF
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);


        const url = URL.createObjectURL(blob);
        const newWindow = window.open('', '_blank', 'width=800,height=600');

        if (newWindow) {
          newWindow.document.write(`<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`);
          newWindow.document.title = "Merged PDF";
        } else {
          console.error('Failed to open new window.');
        }
    } catch (error) {
        console.error('Error:', error);
        SweetAlertConnector.closeCurrentAlert();
        SweetAlertConnector.showErrorAlert('Failed to merge and open PDFs!');
    }

}
</script>

<style>
.badge-container {
    margin: 10px;
}
.error {
    color: red;
}
/* Add your styles here */
button {
    margin: 5px;
    cursor: pointer;
}

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    /* text-align: center; */
}

.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 5px;
    background: #fafafa;
    color: #bdbdbd;
    margin: 10px;
}

.dropzone.dragover {
    border-color: #3f51b5;
    background-color: #eeeeee;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}
.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
}

.dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropdown ul li {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown ul li:hover {
    background-color: #f0f0f0;
}
.fas.fa-file-pdf {
    color: #ff0000; /* Red color for PDF icon */
    margin-left: 10px;
}
.valid-commodity-code {
    color: #09ba09;
    font-weight: bold;
}

.invalid-commodity-code {
    color: red;
    font-weight: bold;
}
.green-icon {
  color: green;
  font-size: 20px; /* Make the icon bigger */
}

.icon-button {
  background: none;
  border: 1px solid green; /* Add a green border */
  padding: 5px; /* Add some padding */
  margin-left: 5px; /* Adds some space between the text and the button */
  cursor: pointer;
  border-radius: 5px; /* Round the edges of the button */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.icon-button:hover {
  background-color: green; /* Change background to green on hover */
}

.icon-button:hover .green-icon {
  color: white; /* Change icon color to white on hover */
}

.icon-button:focus {
  outline: none; /* Remove the default outline */
}
.custom-switch .form-check-input {
    transform: scale(1.5); /* Increase the size of the switch */
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center; /* Center the checkbox vertically */
}
</style>
