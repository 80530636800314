<template>
    <div>
        <!-- C. Deductions -->
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="costAfterArrival"
                        ><strong
                            >19. Cost of transport after arrival at place of introduction on importation</strong
                        ></label
                    >
                    <input
                        type="number"
                        class="form-control"
                        id="costAfterArrival"
                        v-model="localData.costAfterArrival"
                        @input="updateSection"
                    />
                </div>
                <div class="form-group">
                    <label for="chargesAfterImportation"
                        ><strong
                            >20. Charges for construction, erection, assembly, maintenance or technical assistance
                            undertaken after importation</strong
                        ></label
                    >
                    <input
                        type="number"
                        class="form-control"
                        id="chargesAfterImportation"
                        v-model="localData.chargesAfterImportation"
                        @input="updateSection"
                    />
                </div>
                <div class="form-group">
                    <label for="otherCharges"><strong>21. Other charges (specify)</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="otherCharges"
                        v-model="localData.otherCharges"
                        @input="updateSection"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="customsDutiesTaxes"
                        ><strong
                            >22. Customs duties and taxes payable in Trinidad and Tobago by reason of the importation or
                            sale of the goods</strong
                        ></label
                    >
                    <input
                        type="number"
                        class="form-control"
                        id="customsDutiesTaxes"
                        v-model="localData.customsDutiesTaxes"
                        @input="updateSection"
                    />
                </div>
                <div class="form-group">
                    <label for="totalC"><strong>23. TOTAL C</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="totalC"
                        v-model="localData.totalC"
                        @input="updateSection"
                    />
                </div>
                <div class="form-group">
                    <label for="valueDeclared"><strong>24. VALUE DECLARED (A + B - C)</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="valueDeclared"
                        v-model="localData.valueDeclared"
                        @input="updateSection"
                    />
                </div>
            </div>
        </div>

        <!-- NOTES to Box 10 -->
        <div class="mb-3">
            <p><strong>NOTES to Box 10</strong></p>
            <p>Declaration may be made by:</p>
            <ul>
                <li>(a) the actual importer if an individual;</li>
                <li>(b) a partner in the case of partnership;</li>
                <li>(c) a director or the secretary in the case of an incorporated Company</li>
                <li>(d) any employee duly authorised in writing by one of the aforementioned persons.</li>
            </ul>
            <p>
                (*): Where amounts are payable in FOREIGN CURRENCY, indicate in this section the amount in foreign
                currency and the rate of exchange by reference to each relevant element and item.
            </p>

            <label for="foreignCurrency" class="form-label">Reference</label>
            <input
                type="text"
                class="form-control mb-2"
                id="foreignCurrency"
                placeholder="11(a)"
                v-model="localData.foreignCurrency"
                @input="updateSection"
            />

            <!-- <label for="amountForeignCurrency" class="form-label">Amount</label>
      <input type="number" class="form-control mb-2" id="amountForeignCurrency" placeholder="Enter amount in foreign currency" v-model="localData.amountForeignCurrency" @input="updateSection"> -->

            <!-- <label for="rateOfExchange" class="form-label">Rate of Exchange</label>
      <input type="number" class="form-control" id="rateOfExchange" placeholder="Enter rate of exchange" v-model="localData.rateOfExchange" @input="updateSection"> -->
        </div>
    </div>
</template>

<script>
export default {
    props: ["customsDeclarationSectionFour", "customsDeclarationSectionThree"],
    name: "CustomsDeclarationSectionFour",
    data() {
        return {
            localData: this.customsDeclarationSectionFour,
        };
    },
    methods: {
        updateSection() {
            this.$emit("update-section-four", this.localData);
        },
    },
    watch: {
        customsDeclarationSectionThree: {
            deep: true,
            handler(newVal) {
                let totalA =
                    (parseFloat(newVal.netPrice) + parseFloat(newVal.indirectPayments)) *
                    parseFloat(newVal.exchangeRate);
                let totalB =
                    parseFloat(newVal.transport) + parseFloat(newVal.loadingHandling) + parseFloat(newVal.insurance);
                let valueDeclared = totalA + parseFloat(totalB) - parseFloat(this.localData.totalC);
                this.localData.valueDeclared = parseFloat(valueDeclared).toFixed(2);
            },
        },
        customsDeclarationSectionFour: {
            deep: true,
            handler(newVal) {
                this.localData = { ...newVal };
                let totalA =
                    (parseFloat(this.customsDeclarationSectionThree.netPrice) +
                        parseFloat(this.customsDeclarationSectionThree.indirectPayments)) *
                    parseFloat(this.customsDeclarationSectionThree.exchangeRate);
                let totalB =
                    parseFloat(this.customsDeclarationSectionThree.transport) +
                    parseFloat(this.customsDeclarationSectionThree.loadingHandling) +
                    parseFloat(this.customsDeclarationSectionThree.insurance);
                let totalC =
                    parseFloat(this.localData.costAfterArrival) +
                    parseFloat(this.localData.chargesAfterImportation) +
                    parseFloat(this.localData.otherCharges) +
                    parseFloat(this.localData.customsDutiesTaxes);
                this.localData.totalC = parseFloat(totalC).toFixed(2);
                let totalValueDeclared = totalA + totalB - parseFloat(totalC).toFixed(2);
                this.localData.valueDeclared = totalValueDeclared.toFixed(2);
            },
        },
    },
};
</script>

<style scoped>
.form-group {
    margin-bottom: 1rem;
}
.form-check {
    display: flex;
    align-items: center;
    gap: 10px;
}
.form-check label {
    margin-right: 1rem;
}
</style>
