<template>
    <div class="customs-entry-form">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group"> 
                <label class="required" for="buyerNameAddress"><strong>Importer</strong></label>
                <v-select :options="localData.importers" v-model="localData.importerId" label="name" :searchable="true" :reduce="importer => importer.id"  :filterable="true" placeholder="Select an Importer">
                    <template #option="option">
                        <div 
                        class="option-wrapper" 
                        :data-bs-toggle="'tooltip'"
                        :data-bs-placement="'right'"
                        :title="option.tooltipContent"
                        >
                        {{ option.name }}
                        </div>
                    </template>
                </v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="sellerNameAddress"><strong>Exporter</strong></label>
                    <v-select :options="localData.exporters" v-model="localData.exporterId" label="name" :searchable="true" :reduce="exporter => exporter.id"  :filterable="true" placeholder="Select an Exporter">
                        <template #option="option">
                            <div 
                            class="option-wrapper" 
                            :data-bs-toggle="'tooltip'"
                            :data-bs-placement="'right'"
                            :title="option.tooltipContent"
                            >
                            {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="modeOfTransport"><strong>Mode of Transport</strong></label>
                    <select class="form-select" v-model="localData.modeOfTransport" ref="modeOfTransportSelect">
                        <option value="" disabled>Select a Mode of Transport</option>
                        <option v-for="(mode, index) in localData.modeOfTransportOOptions" :key="index" :value="mode.modeOfTransport">{{ mode.modeOfTransport }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="freightType"><strong>Freight Type</strong></label>
                    <select class="form-select" v-model="localData.freightType">
                        <option value="" disabled>Select a Freight Type</option>
                        <option v-for="(freight, index) in this.availableFreightTypes" :key="index" :value="freight.name">{{ freight.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="waybillNumber"><strong>Waybill Numbers</strong></label>
                    <input type="text" class="form-control" id="waybillNumber" v-model="localData.waybillNumber" placeholder="Please Enter Waybill Number" required>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="waybillDate"><strong>Waybill Date</strong></label>
                    <input type="date" class="form-control" id="waybillDate" v-model="localData.waybillDate" required>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="vessel"><strong>Vessel</strong></label>
                    <!-- <select class="form-select" v-model="localData.vesselId">
                        <option value="" disabled>Select a Vessel</option>
                        <option v-for="(vessel, index) in localData.vessels" :key="index" :value="vessel.id">{{ vessel.name }}</option>
                    </select> -->
                    <v-select :options="localData.vessels" v-model="localData.vesselId" label="name" :searchable="true" :reduce="vessel => vessel.id"  :filterable="true" placeholder="Select a Vessel"></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="portOfDischarge"><strong>Port of Discharge</strong></label>
                    <!-- <select class="form-select" v-model="localData.portOfDischargeId">
                        <option value="" disabled>Select a Port of Discharge</option>
                        <option v-for="(port, index) in localData.ports" :key="index" :value="port.id">{{ port.name }}</option>
                    </select> -->
                    <v-select :options="localData.ports" v-model="localData.portOfDischargeId" label="name" :searchable="true" :reduce="port => port.id"  :filterable="true" placeholder="Select a Port of Discharge"></v-select>
                </div>
            </div>
        </div>
        <div class="row"> 
            <div class="col-md-3">
                <div class="form-group">
                    <label for="grossWeight"><strong>Gross Weight</strong></label>
                    <input type="number" class="form-control" id="grossWeight" v-model="localData.grossWeight" required>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="netWeight"><strong>Net Weight</strong></label>
                    <input type="number" class="form-control" id="netWeight" v-model="localData.netWeight" required readonly>
                </div> 
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="numberOfPackages"><strong>Number of Packages</strong></label>
                    <input type="number" class="form-control" id="numberOfPackages" v-model="localData.numberOfPackages" required>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="packageType"><strong>Package Type</strong></label>
                    <!-- <select class="form-select" v-model="localData.packageType">
                        <option value="" disabled>Select a Package Type</option>
                        <option v-for="(type, index) in localData.packageTypes" :key="index" :value="type.value">{{ type.text }}</option>
                    </select> -->
                    <v-select :options="localData.packageTypes" v-model="localData.packageType" label="text" :searchable="true" :reduce="type => type.value"  :filterable="true" placeholder="Select a Package Type"></v-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="totalCargoValue"><strong>Total Cargo Value</strong></label>
                    <input type="number" class="form-control" id="totalCargoValue" v-model="localData.totalCargoValue" required>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <label for="currency"><strong>Freight</strong></label>
                    <v-select :clearable="false" :options="currencies" v-model="localData.freightCurrency" label="code" id="invoiceCurrency" :searchable="true" :filterable="true" :reduce="currency => currency.code"></v-select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label for="freightAmount" class="label-green-italic">{{localData.freightRateOfExchange}}</label>
                    <input type="number" class="form-control" id="freightAmount" v-model="localData.freightAmount" required>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <label for="currency"><strong>Insurance</strong></label>
                    <v-select :clearable="false" :options="currencies" v-model="localData.insuranceCurrency" label="code" id="invoiceCurrency" :searchable="true" :filterable="true" :reduce="currency => currency.code"></v-select>
                </div>
            </div>
            <div class="col-md-2">
               <label for="InsuranceAmount" class="label-green-italic">{{ localData.insuranceRateOfExchange }}</label>
                <input type="number" class="form-control" id="InsuranceAmount" v-model="localData.insuranceAmount" required>
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <label for="currency"><strong>Charges</strong></label>
                    <v-select :clearable="false" :options="currencies" v-model="localData.otherChargesCurrency" label="code" id="invoiceCurrency" :searchable="true" :filterable="true" :reduce="currency => currency.code"></v-select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label for="othercharges" class="label-green-italic">{{localData.otherChargesRateOfExchange}}</label>
                    <input type="number" class="form-control" id="otherCharges" v-model="localData.otherCharges">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="declarant"><strong>Declarant</strong></label>
                    <select class="form-select" v-model="localData.declarantId">
                        <option value="" disabled>Select a Declarant</option>
                        <option v-for="(declarant, index) in localData.declarants" :key="index" :value="declarant.id">{{ declarant.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="regimeType"><strong>Regime Type</strong></label>
                    <!-- <select class="form-select" v-model="localData.regimeType">
                        <option value="" disabled>Select a Regime Type</option>
                        <option v-for="(regime, index) in localData.regimeTypeOptions" :key="index" :value="regime.regimeTypeId">{{ regime.regimeType }}</option>
                    </select> -->
                    <v-select :options="localData.regimeTypeOptions" v-model="localData.regimeType" label="regimeType" :searchable="true" :reduce="regime => regime.regimeTypeId"  :filterable="true" placeholder="Select a Regime Type"></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <label for ="incoTerms"><strong>Incoterms</strong></label>
                <!-- <select class="form-select" v-model="localData.incoTerms">
                    <option value="" disabled>Select an Incoterm</option>
                    <option v-for="(incoterm, index) in localData.incotermsList" :key="index" :value="incoterm.code">{{ incoterm.code }} - {{ incoterm.name }}</option>
                </select> -->
                <v-select :options="localData.incotermsList" v-model="localData.incoTerms" label="name" :searchable="true" :reduce="incoterm => incoterm.code"  :filterable="true" placeholder="Select an Incoterm"></v-select>
            </div>
            <div class="col-md-3">
                <label for="country"><strong>Country of Origin</strong></label>
                <!-- <select class="form-select" v-model="localData.countryOfOrigin" id="country" @change="countryChange">
                  <option disabled value="">Please select one</option>
                  <option v-for="country in countryList" :key="country.code" :value="country.code">
                    {{ country.code }} - {{ country.name }}
                  </option>
                </select> -->
                <v-select :options="countryList" v-model="localData.countryOfOrigin" label="name" :searchable="true" :reduce="country => country.code"  :filterable="true" placeholder="Select a Country of Origin"></v-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="containerCharges"><strong>Container Charges</strong></label>
                    <input type="number" class="form-control" id="containerCharges" v-model="localData.containerCharges" required>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="localFee"><strong>Local Fee</strong></label>
                    <input type="number" class="form-control" id="localFee" v-model="localData.localFee" required>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="depositAmount"><strong>Deposit Amount</strong></label>
                    <input type="number" class="form-control" id="depositAmount" v-model="localData.depositAmount" required>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="additionalCharges"><strong>Additional Charges</strong></label>
                    <input type="number" class="form-control" id="additionalCharges" v-model="localData.additionalCharges" required>
                </div>
            </div>
            <!-- <div class="col-md-3">
                <label for="country"><strong>Country of Origin</strong></label>
                <select class="form-select" v-model="localData.countryOfOrigin" id="country" @change="countryChange">
                  <option disabled value="">Please select one</option>
                  <option v-for="country in countryList" :key="country.code" :value="country.code">
                    {{ country.code }} - {{ country.name }}
                  </option>
                </select>
            </div> -->
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="marksAndNumbers"><strong>Marks and Numbers</strong></label>
                    <input type="text" class="form-control" id="marksAndNumbers" v-model="localData.marksAndNumbers" required>
                </div>
            </div>
            <div class="col-md-3">
                <label for="country"><strong>Country of Final Destination</strong></label>
                <v-select :options="countryList" v-model="localData.countryOfFinalDestination" label="name" :searchable="true" :reduce="country => country.code"  :filterable="true" placeholder="Select a Country of Destination"></v-select>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="countryLastProvinance"><strong>Country of Last Provinance</strong></label>
                    <v-select :options="countryList" v-model="localData.countryLastProvinance" label="name" :searchable="true" :reduce="country => country.code"  :filterable="true" placeholder="Select a Country of Last Provinance"></v-select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="tradingCountry"><strong>Trading Country</strong></label>
                    <v-select :options="countryList" v-model="localData.tradingCountry" label="name" :searchable="true" :reduce="country => country.code"  :filterable="true" placeholder="Select a Trading Country"></v-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="estimatedArrivalDate"><strong>Arrival Date</strong></label>
                    <input type="date" class="form-control" id="estimatedArrivalDate" v-model="localData.estimatedArrivalDate" required>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import * as DatabaseConnector from '@/composables/DatabaseConnector';
  import * as PackageTypes from '@/composables/PackageTypes';
  import * as IncoTerms from '@/composables/IncoTerms';
  import * as BrokerCongifurations from '@/composables/BrokerConfiguration';
  import * as Currency from '@/composables/Currency'

  export default {
    name: 'CustomsEntryForm',
    props: ['customsEntryForm'],
    mounted() {
        console.log("Mounting")
        this.getConfigurations();
        this.localData.packageTypes = PackageTypes.usePackageTypes();
        this.localData.incotermsList = IncoTerms.getIncoTermsList();
        this.currencies = Currency.getCurrencyList();
        this.localData.modeOfTransportOOptions = BrokerCongifurations.getFreightTypes();
        this.initTooltips();
    },
    data() {
      return {
        freightTypeOptions: [],
        localData: this.customsEntryForm,
        countryList: [],
        currencies: [],
      }
    },
    computed: {
        availableFreightTypes(){
            const results = this.localData.modeOfTransportOOptions?.find(mode => mode.modeOfTransport === this.localData.modeOfTransport)?.freightTypes;
            return results;
        }
    },
    updated() {
        // Re-initialize Bootstrap tooltips if options change
        this.initTooltips();
    },
    methods: {
        initTooltips() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            tooltipTriggerList.forEach((tooltipTriggerEl) => {
                new window.bootstrap.Tooltip(tooltipTriggerEl);
            });
        },
        setModeOfTransport(modeOfTransport){
            this.$nextTick(() => {
                this.localData.modeOfTransport = modeOfTransport;
                this.$refs.modeOfTransportSelect.dispatchEvent(new Event('change'));
            })
        },
        updateModeOfTransport(event){
            const modeOfTransport = this.localData.modeOfTransportOOptions.find(mode => mode.modeOfTransport === event.target.value);
            this.freightTypeOptions = modeOfTransport.freightTypes;
        },
        updateField(field, value) {
            this.localData[field] = value;
            console.log(this.localData);
            this.$emit('update-section-one', this.localData);
        },
        async getConfigurations() {
            console.log('Fetching configurations...');
            this.localData.ports = await DatabaseConnector.fetchPorts();

            Promise.all([DatabaseConnector.fetchShippers(), DatabaseConnector.fetchBuyers(), DatabaseConnector.fetchVessels(), DatabaseConnector.fetchCustomsEntryDeclarants(), DatabaseConnector.fetchCountries()]).then(([exporters, importers, vessels, declarants, countries]) => {
                this.localData.exporters = exporters.map(exporter => {
                    return {
                        ...exporter,
                        tooltipContent: `${exporter.name}\n${exporter.address}\n${exporter.country}`
                    };
                });
                this.localData.importers = importers.map(importer => {
                    return {
                        ...importer,
                        tooltipContent: `${importer.name}\n${importer.address}\n${importer.country}`
                    };
                });
                console.log("Importers", importers);
                this.localData.vessels = vessels;
                // this.localData.ports = ports;
                this.localData.declarants = declarants;
                this.countryList = countries;
            });


            
        }
    },
    watch: {
        customsEntryForm: {
            deep: true,
            handler(newVal) {
                // console.log("Updating form")
                this.localData = newVal;
                this.localData.portOfDischargeName = this.localData.ports.find(port => port.id === this.localData.portOfDischargeId)?.name;
                this.localData.vesselName = this.localData.vessels.find(vessel => vessel.id === this.localData.vesselId)?.name;
                this.localData.regimeTypeCode = this.localData.regimeTypeOptions.find(regime => regime.regimeTypeId === this.localData.regimeType)?.regimeTypeCode;
                const importer = this.localData.importers.find(importer => importer.id === this.localData.importerId);
                const exporter = this.localData.exporters.find(exporter => exporter.id === this.localData.exporterId);
                this.localData.importerName = importer !== undefined ? importer.name : '';
                this.localData.importerTaxIdentificationNumber = importer !== undefined ? importer.taxIdentificationNumber : '';
                this.localData.exporterName = exporter !== undefined ? exporter.name : '';
                this.localData.exporterTaxIdentificationNumber = exporter !== undefined ? exporter.taxIdentificationNumber : '';
                this.localData.exporterCountry = exporter !== undefined ? exporter.country : '';

                if(this.localData.countryOfOrigin !== null && (this.localData.countryLastProvinance == null || this.localData.countryLastProvinance == '' || this.localData.countryLastProvinance == undefined)){
                    this.localData.countryLastProvinance = this.localData.countryOfOrigin;
                }

                if(this.localData.countryOfOrigin !== null && (this.localData.tradingCountry == null || this.localData.tradingCountry == '' || this.localData.tradingCountry == undefined)){
                    this.localData.tradingCountry = this.localData.countryOfOrigin;
                }

                if(this.localData.grossWeight !== null && this.localData.grossWeight > 0){
                    this.localData.netWeight = this.localData.grossWeight - (this.localData.grossWeight / 10);
                }

                // if(this.localData.waybillDate !== null && this.localData.waybillDate !== ''){
                //     console.log("Fetching rates of exchange");
                //     DatabaseConnector.fetchRatesBasedOnShippedOnBoard(this.localData.waybillDate).then(rates => {
                //         this.localData.ratesOfExchange = rates;
                //     });
                // }

                if(this.localData.ratesOfExchange !== null && this.localData.ratesOfExchange.length > 0){
                    this.localData.insuranceRateOfExchange = this.localData.insuranceCurrency ? this.localData.ratesOfExchange.find(rate => rate.base_currency === this.localData.insuranceCurrency).rate : 1;
                    this.localData.freightRateOfExchange = this.localData.freightCurrency  ? this.localData.ratesOfExchange.find(rate => rate.base_currency === this.localData.freightCurrency).rate : 1;
                    this.localData.otherChargesRateOfExchange = this.localData.otherChargesCurrency ? this.localData.ratesOfExchange.find(rate => rate.base_currency === this.localData.otherChargesCurrency).rate : 1;
                }

            }
        }
    }
  }
</script>
  
<style scoped>
@import 'vue3-select/dist/vue3-select.css';
.option-wrapper {
  padding: 5px;
  cursor: pointer;
}
.customs-entry-form {
    text-align: left;
    padding: 20px;
}
  .form-group {
  margin-bottom: 20px; /* Adds space below each form group */
}
.form-check {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px; /* Adds space below each form group */
}
.form-check label {
  margin-right: 1rem;
}

.required::after {
  content: ' *';
  color: red;
}
.label-green-italic {
  color: green;
  font-style: italic;
}
/* Custom Tooltip Styling */
.tooltip {
    background-color: #333; /* Darker background */
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Extra padding */
    font-size: 14px; /* Adjust font size */
    line-height: 1.5; /* Improved readability */
}

.tooltip .tooltip-arrow {
    display: none; /* Hide the default arrow */
}

.tooltip-inner {
    color: #fff; /* White text */
    background-color: #333; /* Darker background */
    padding: 8px 12px; /* Padding around the content */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
    text-align: left; /* Left-align the text */
}

/* Custom Arrow using :before */
.tooltip[data-popper-placement^="right"] .tooltip-inner::before,
.tooltip[data-popper-placement^="left"] .tooltip-inner::before,
.tooltip[data-popper-placement^="top"] .tooltip-inner::before,
.tooltip[data-popper-placement^="bottom"] .tooltip-inner::before {
    content: "";
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
    z-index: 1;
}

/* Right placement arrow */
.tooltip[data-popper-placement^="right"] .tooltip-inner::before {
    border-color: transparent #333 transparent transparent;
    border-width: 8px;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
}

/* Left placement arrow */
.tooltip[data-popper-placement^="left"] .tooltip-inner::before {
    border-color: transparent transparent transparent #333;
    border-width: 8px;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
}

/* Top placement arrow */
.tooltip[data-popper-placement^="top"] .tooltip-inner::before {
    border-color: #333 transparent transparent transparent;
    border-width: 8px;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
}

/* Bottom placement arrow */
.tooltip[data-popper-placement^="bottom"] .tooltip-inner::before {
    border-color: transparent transparent #333 transparent;
    border-width: 8px;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
}

</style>