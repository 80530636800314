<template>
  <div class="container mt-4">
    <h1 class="text-center mb-4">Trinidad & Tobago</h1>
    <h1 class="text-center mb-4">Customs Declaration Form E-C75</h1>
    <form @submit.prevent="generateC75Declaration" class="my-form-container">
      <CustomsDeclarationSectionOne
        :buyerIdProp="this.$route.query.buyerId"
        :customsDeclarationSectionOne="form.customsDeclarationSectionOne"
        @update-section-one="form.customsDeclarationSectionOne = $event"
      />
      <CustomsDeclarationSectionTwo
        :customsDeclarationSectionTwo="form.customsDeclarationSectionTwo"
        :customsDeclarationSectionOne="form.customsDeclarationSectionOne"
        @update-section-two="form.customsDeclarationSectionTwo = $event"
      />
      <CustomsDeclarationSectionThree
        :customsDeclarationSectionThree="form.customsDeclarationSectionThree"
        @update-section-three="form.customsDeclarationSectionThree = $event"
      />
      <CustomsDeclarationSectionFour
        :customsDeclarationSectionFour="form.customsDeclarationSectionFour"
        :customsDeclarationSectionThree="form.customsDeclarationSectionThree"
        @update-section-four="form.customsDeclarationSectionFour = $event"
      />
      
    </form>
    <table>
      <tr>
        <td>
            <button class="btn btn-primary" @click="save75Declaration">Save Valuation Form</button>
        </td>
        <td>
          <button class="btn btn-primary" @click="generateC75Declaration">Generate Valuation Form</button>
        </td>
        <!-- <td>
          <button class="btn btn-primary" @click="generatePreSignedValuation">Generate Pre-Signed Valuation</button>
        </td> -->
        <td>
          <button class="btn btn-primary" @click="generatePreSignedValuationVersionTwo">Generate Pre-Signed Valuation Form</button>
        </td>
        <td>
          <button class="btn btn-primary" @click="generateBlanlValuationForPreSigning">Generate Blank Valuation for Pre-Signing</button>
        </td>
      </tr>
    </table>
    
  </div>
</template>

<script>
import CustomsDeclarationSectionOne from './CustomsDeclarationSections/CustomsDeclarationSectionOne.vue';
import CustomsDeclarationSectionTwo from './CustomsDeclarationSections/CustomsDeclarationSectionTwo.vue';
import CustomsDeclarationSectionThree from './CustomsDeclarationSections/CustomsDeclarationSectionThree.vue';
import CustomsDeclarationSectionFour from './CustomsDeclarationSections/CustomsDeclarationSectionFour.vue';

import * as DatabaseConnector from '@/composables/DatabaseConnector';
import * as SweetAlertConnector from '../composables/SweetAlertConnector';
import * as Currency from '@/composables/Currency';

export default {
    name: 'CustomsDeclarationForm',
    components: {
        CustomsDeclarationSectionOne,
        CustomsDeclarationSectionTwo,
        CustomsDeclarationSectionThree,
        CustomsDeclarationSectionFour
    },
    props: {
        entryId: {
            type: [String, Number],
            required: true
        },
        buyerId: {
            type: [String, Number],
            required: true
        },
        sellerId: {
            type: [String, Number],
            required: true
        },
        invoiceNumber: {
            type: [String, Number],
            required: true
        },
        invoiceTotal: {
            type: [String, Number],
            required: true
        },
        currency: {
            type: [String, Number],
            required: true
        },
        rateOfExchange: {
            type: [String, Number],
            required: true
        },
        freight: {
            type: [String, Number],
            required: true
        },
        insurance: {
            type: [String, Number],
            required: true
        },
    },
    mounted() {
        this.fetchCustomsDeclarations();
        this.syncData();
        this.currencies = Currency.getCurrencyList();
        this.getCountries();
    },
    data() {
        return {
            newC75: true,
            entryNumber: '',
            currencies: [],
            countries: [],
            form: {
                customsDeclarationSectionOne: {
                  sellers: [],
                  buyers: [],
                  declarants: [],
                  sellerNameAddress: '',
                  buyerNameAddress: '',
                  declarantNameAddress: '',
                },
                customsDeclarationSectionTwo: {
                  date_signed: this.getCurrentDate(),
                  relatedParties: 'no',
                  influencePrice: 'no',
                  transactionValueApproximate: 'no',
                  restrictions: 'no',
                  conditions: 'no',
                  royalties: 'no',
                  resale_disposal_boolean: 'no',
                },
                customsDeclarationSectionThree: {
                    totalNationalCurrency : 0.0,
                    netPrice: 0.0,
                    indirectPayments: 0.0,
                    exchangeRate: 1.0,
                    transport: 0.0,
                    loadingHandling: 0.0,
                    insurance: 0.0,
                    totalB: 0.0,
                    transportIncluded: false,
                    insuranceIncluded: false,
                    invoiceCurrency: 'USD',
                },
                customsDeclarationSectionFour: {
                    costAfterArrival: 0.0,
                    chargesAfterImportation: 0.0,
                    otherCharges: 0.0,
                    customsDutiesTaxes: 0.0,
                    totalC: 0.0,
                    valueDeclared: 0.0
                }
            }
        }
    },

    methods: {
        save75Declaration(){
            if(this.newC75){
                this.create75Declaration();
            } else {
                this.updateC75Declaration();
            }
        },
        getCountries(){
          DatabaseConnector.fetchCountries().then(response => {
            this.countries = response;
          }).catch(error => {
            console.error('Failed to get countries:', error);
          });
        },
        getCurrentDate() {
          const date = new Date();
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();

          return `${year}-${month}-${day}`;
        },
        async create75Declaration() {
            console.log('Saving C75 Declaration...');

            const selectedSeller = this.form.customsDeclarationSectionOne.sellers.find(seller => seller.id === this.form.customsDeclarationSectionOne.sellerId);
            const selectedBuyer = this.form.customsDeclarationSectionOne.buyers.find(buyer => buyer.id === this.form.customsDeclarationSectionOne.buyerId);
            const selectedDeclarant = this.form.customsDeclarationSectionOne.declarants.find(declarant => declarant.id === this.form.customsDeclarationSectionOne.declarantId);

            const sellerNameAddress = selectedSeller ? `${selectedSeller.name} \n ${selectedSeller.address}` : '';
            const buyerNameAddress = selectedBuyer ? `${selectedBuyer.name} \n ${selectedBuyer.address}` : '';
            const declarantNameAddress = selectedDeclarant ? `${selectedDeclarant.name} \n ${selectedDeclarant.address}` : '';

            const declarantStatus = selectedDeclarant ? selectedDeclarant.status : '';
            const declarantPhone = selectedDeclarant?.phone ? selectedDeclarant.phone : '';
            const declarantName = selectedDeclarant ? selectedDeclarant.name : '';
            SweetAlertConnector.showLoadingAlert('Saving Data...');
            const declarationData = {
                "sellerNameAddress": sellerNameAddress,
                "buyerNameAddress": buyerNameAddress,
                "declarantNameAddress": declarantNameAddress,
                "incoTerms": this.form.customsDeclarationSectionOne.termsOfDelivery !== undefined ? this.form.customsDeclarationSectionOne.termsOfDelivery : "",
                "invoiceNumbersDates": this.form.customsDeclarationSectionOne.invoiceNumber !== undefined ? this.form.customsDeclarationSectionOne.invoiceNumber : "",
                "numberAndDateofContract": this.form.customsDeclarationSectionOne.contractNumber !== undefined ? this.form.customsDeclarationSectionOne.contractNumber : "",
                "sellerId": this.form.customsDeclarationSectionOne.sellerId !== undefined ? this.form.customsDeclarationSectionOne.sellerId : "",
                "buyerId": this.form.customsDeclarationSectionOne.buyerId !== undefined ? this.form.customsDeclarationSectionOne.buyerId : "",
                "declarantId": this.form.customsDeclarationSectionOne.declarantId !== undefined ? this.form.customsDeclarationSectionOne.declarantId : "",
                "relatedParties": this.form.customsDeclarationSectionTwo.relatedParties !== undefined ? this.form.customsDeclarationSectionTwo.relatedParties : "",
                "influencePrice": this.form.customsDeclarationSectionTwo.influencePrice !== undefined ? this.form.customsDeclarationSectionTwo.influencePrice : "",
                "transactionValueApproximate": this.form.customsDeclarationSectionTwo.transactionValueApproximate !== undefined ? this.form.customsDeclarationSectionTwo.transactionValueApproximate : "",
                "restrictions": this.form.customsDeclarationSectionTwo.restrictions !== undefined ? this.form.customsDeclarationSectionTwo.restrictions : "",
                "conditions": this.form.customsDeclarationSectionTwo.conditions !== undefined ? this.form.customsDeclarationSectionTwo.conditions : "",
                "royalties_boolean": this.form.customsDeclarationSectionTwo.royalties !== undefined ? this.form.customsDeclarationSectionTwo.royalties : "",
                "resale_disposal_boolean": this.form.customsDeclarationSectionTwo.resale_disposal_boolean !== undefined ? this.form.customsDeclarationSectionTwo.resale_disposal_boolean : "",
                "date_signed": this.form.customsDeclarationSectionTwo.date_signed !== undefined ? this.form.customsDeclarationSectionTwo.date_signed : "",
                "status_of_signatory": declarantStatus,
                "signatory_phone_number": declarantPhone,
                "signatory_name": declarantName,
                "net_invoice_price": this.form.customsDeclarationSectionThree.netPrice !== undefined ? this.form.customsDeclarationSectionThree.netPrice : "",
                "indirect_payment": this.form.customsDeclarationSectionThree.indirectPayment !== undefined ? this.form.customsDeclarationSectionThree.indirectPayment : "",
                "exchange_rate": this.form.customsDeclarationSectionThree.exchangeRate !== undefined ? this.form.customsDeclarationSectionThree.exchangeRate : "",
                "costs_commissions": this.form.customsDeclarationSectionThree.commissions !== undefined ? this.form.customsDeclarationSectionThree.commissions : "",
                "costs_brokerage": this.form.customsDeclarationSectionThree.brokerage !== undefined ? this.form.customsDeclarationSectionThree.brokerage : "",
                "costs_containers_packing": this.form.customsDeclarationSectionThree.containersPacking !== undefined ? this.form.customsDeclarationSectionThree.containersPacking : "",
                "goods_free_of_charge_materials": this.form.customsDeclarationSectionThree.materials !== undefined ? this.form.customsDeclarationSectionThree.materials : "",
                "goods_free_of_charge_tools": this.form.customsDeclarationSectionThree.tools !== undefined ? this.form.customsDeclarationSectionThree.tools : "",
                "goods_free_of_charge_materials_consumed": this.form.customsDeclarationSectionThree.materialsConsumed !== undefined ? this.form.customsDeclarationSectionThree.materialsConsumed : "",
                "goods_engineering_development": this.form.customsDeclarationSectionThree.engineering !== undefined ? this.form.customsDeclarationSectionThree.engineering : "",
                "royalties_license_fee": this.form.customsDeclarationSectionThree.royalties !== undefined ? this.form.customsDeclarationSectionThree.royalties : "",
                "procees_resale_disposal": this.form.customsDeclarationSectionThree.proceedsResale !== undefined ? this.form.customsDeclarationSectionThree.proceedsResale : "",
                "cost_delivery_transport": this.form.customsDeclarationSectionThree.transport !== undefined ? this.form.customsDeclarationSectionThree.transport : "",
                "cost_delivery_loading": this.form.customsDeclarationSectionThree.loadingHandling !== undefined ? this.form.customsDeclarationSectionThree.loadingHandling : "",
                "cost_delivery_insurance": this.form.customsDeclarationSectionThree.insurance !== undefined ? this.form.customsDeclarationSectionThree.insurance : "",
                "cost_transport_after_arrival": this.form.customsDeclarationSectionFour.costAfterArrival !== undefined ? this.form.customsDeclarationSectionFour.costAfterArrival : "",
                "charges_construction": this.form.customsDeclarationSectionFour.chargesAfterImportation !== undefined ? this.form.customsDeclarationSectionFour.chargesAfterImportation : "",
                "other_charges": this.form.customsDeclarationSectionFour.otherCharges !== undefined ? this.form.customsDeclarationSectionFour.otherCharges : "",
                "customs_duties_taxes": this.form.customsDeclarationSectionFour.customsDutiesTaxes !== undefined ? this.form.customsDeclarationSectionFour.customsDutiesTaxes : "",
                "entryId": this.entryId
            }
            try {
                console.log('Saving customs declaration...');
                console.log(declarationData);
                await DatabaseConnector.saveCustomsDeclaration(declarationData);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('All Data Saved successfully!');
                console.log('Customs declaration saved successfully.');
                this.newC75 = false;
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to save the declaration:', error);
            }
        },
        prepareDeclarationObject(){
          console.log("Prearing Declaration Object...")
          const selectedSeller = this.form.customsDeclarationSectionOne.sellers.find(seller => seller.id === this.form.customsDeclarationSectionOne.sellerId);
          const selectedBuyer = this.form.customsDeclarationSectionOne.buyers.find(buyer => buyer.id === this.form.customsDeclarationSectionOne.buyerId);
          const selectedDeclarant = this.form.customsDeclarationSectionOne.declarants.find(declarant => declarant.id === this.form.customsDeclarationSectionOne.declarantId);
          

          // console.log(this.countries)
          const sellerCountry = selectedSeller.country ? this.countries.find(country => country.code === selectedSeller.country)?.name : ''; 
          const buyerCountry = selectedBuyer.country ? this.countries.find(country => country.code === selectedBuyer.country)?.name : '';

          const sellerNameAddress = selectedSeller ? `${selectedSeller.name}\n${selectedSeller.address}, ${sellerCountry}` : '';
          const buyerNameAddress = selectedBuyer ? `${selectedBuyer.name}\n${selectedBuyer.address}, ${buyerCountry}` : '';
          const declarantNameAddress = selectedDeclarant ? `${selectedDeclarant.name}\n${selectedDeclarant.address}\n${selectedDeclarant.country}` : '';

          const declarantStatus = selectedDeclarant ? selectedDeclarant.status : '';
          const declarantPhone = selectedDeclarant?.phoneContact ? selectedDeclarant.phoneContact : '';
          const declarantName = selectedDeclarant ? selectedDeclarant.name : '';

          const selectedCurrency = this.currencies.find(currency => currency.code === this.form.customsDeclarationSectionThree.invoiceCurrency);
          const currencyFullName = selectedCurrency ? selectedCurrency.name : '';

          const transport = this.form.customsDeclarationSectionThree.transport !== undefined ? this.form.customsDeclarationSectionThree.transport : 0.0;

          const exchangeRate = this.form.customsDeclarationSectionThree.exchangeRate !== undefined ? this.form.customsDeclarationSectionThree.exchangeRate : 1.0;

          const transportConverted = parseFloat(transport);
          console.log("Transport Converted:", transportConverted, transport, exchangeRate);

          console.log("Selected Seller:", selectedSeller);
          console.log("Selected Buyer:", selectedBuyer);
          console.log("Selected Declarant:", selectedDeclarant);
          
          let declarationData = {
                "sellerNameAddress": sellerNameAddress,
                "buyerNameAddress": buyerNameAddress,
                "declarantNameAddress": declarantNameAddress,
                "incoTerms": this.form.customsDeclarationSectionOne.termsOfDelivery !== undefined ? this.form.customsDeclarationSectionOne.termsOfDelivery : "",
                "invoiceNumbersDates": this.form.customsDeclarationSectionOne.invoiceNumber !== undefined ? this.form.customsDeclarationSectionOne.invoiceNumber : "",
                "numberAndDateofContract": this.form.customsDeclarationSectionOne.contractNumber !== undefined ? this.form.customsDeclarationSectionOne.contractNumber : "",
                "sellerId": this.form.customsDeclarationSectionOne.sellerId !== undefined ? this.form.customsDeclarationSectionOne.sellerId : "",
                "buyerId": this.form.customsDeclarationSectionOne.buyerId !== undefined ? this.form.customsDeclarationSectionOne.buyerId : "",
                "declarantId": this.form.customsDeclarationSectionOne.declarantId !== undefined ? this.form.customsDeclarationSectionOne.declarantId : "",
                "relatedParties": this.form.customsDeclarationSectionTwo.relatedParties !== undefined ? this.form.customsDeclarationSectionTwo.relatedParties : "",
                "influencePrice": this.form.customsDeclarationSectionTwo.influencePrice !== undefined ? this.form.customsDeclarationSectionTwo.influencePrice : "",
                "transactionValueApproximate": this.form.customsDeclarationSectionTwo.transactionValueApproximate !== undefined ? this.form.customsDeclarationSectionTwo.transactionValueApproximate : "",
                "restrictions": this.form.customsDeclarationSectionTwo.restrictions !== undefined ? this.form.customsDeclarationSectionTwo.restrictions : "",
                "conditions": this.form.customsDeclarationSectionTwo.conditions !== undefined ? this.form.customsDeclarationSectionTwo.conditions : "",
                "royalties_boolean": this.form.customsDeclarationSectionTwo.royalties !== undefined ? this.form.customsDeclarationSectionTwo.royalties : "",
                "resale_disposal_boolean": this.form.customsDeclarationSectionTwo.resale_disposal_boolean !== undefined ? this.form.customsDeclarationSectionTwo.resale_disposal_boolean : "",
                "date_signed": this.form.customsDeclarationSectionTwo.date_signed !== undefined ? this.form.customsDeclarationSectionTwo.date_signed : "",
                "status_of_signatory": declarantStatus,
                "signatory_phone_number": declarantPhone !== undefined ? declarantPhone : "",
                "signatory_name": declarantName,
                "net_invoice_price": this.form.customsDeclarationSectionThree.netPrice !== undefined ? this.form.customsDeclarationSectionThree.netPrice : "",
                "indirect_payment": this.form.customsDeclarationSectionThree.indirectPayment !== undefined ? this.form.customsDeclarationSectionThree.indirectPayment : "",
                "exchange_rate": this.form.customsDeclarationSectionThree.exchangeRate !== undefined ? this.form.customsDeclarationSectionThree.exchangeRate : "",
                "costs_commissions": this.form.customsDeclarationSectionThree.commissions !== undefined ? this.form.customsDeclarationSectionThree.commissions : "",
                "costs_brokerage": this.form.customsDeclarationSectionThree.brokerage !== undefined ? this.form.customsDeclarationSectionThree.brokerage : "",
                "costs_containers_packing": this.form.customsDeclarationSectionThree.containersPacking !== undefined ? this.form.customsDeclarationSectionThree.containersPacking : "",
                "goods_free_of_charge_materials": this.form.customsDeclarationSectionThree.materials !== undefined ? this.form.customsDeclarationSectionThree.materials : "",
                "goods_free_of_charge_tools": this.form.customsDeclarationSectionThree.tools !== undefined ? this.form.customsDeclarationSectionThree.tools : "",
                "goods_free_of_charge_materials_consumed": this.form.customsDeclarationSectionThree.materialsConsumed !== undefined ? this.form.customsDeclarationSectionThree.materialsConsumed : "",
                "goods_engineering_development": this.form.customsDeclarationSectionThree.engineering !== undefined ? this.form.customsDeclarationSectionThree.engineering : "",
                "royalties_license_fee": this.form.customsDeclarationSectionThree.royalties !== undefined ? this.form.customsDeclarationSectionThree.royalties : "",
                "procees_resale_disposal": this.form.customsDeclarationSectionThree.proceedsResale !== undefined ? this.form.customsDeclarationSectionThree.proceedsResale : "",
                "cost_delivery_transport": transportConverted.toFixed(2),
                "cost_delivery_loading": this.form.customsDeclarationSectionThree.loadingHandling !== undefined ? this.form.customsDeclarationSectionThree.loadingHandling : "",
                "cost_delivery_insurance": this.form.customsDeclarationSectionThree.insurance !== undefined ? this.form.customsDeclarationSectionThree.insurance : "",
                "cost_transport_after_arrival": this.form.customsDeclarationSectionFour.costAfterArrival !== undefined ? this.form.customsDeclarationSectionFour.costAfterArrival : "",
                "charges_construction": this.form.customsDeclarationSectionFour.chargesAfterImportation !== undefined ? this.form.customsDeclarationSectionFour.chargesAfterImportation : "",
                "other_charges": this.form.customsDeclarationSectionFour.otherCharges !== undefined ? this.form.customsDeclarationSectionFour.otherCharges : "",
                "customs_duties_taxes": this.form.customsDeclarationSectionFour.customsDutiesTaxes !== undefined ? this.form.customsDeclarationSectionFour.customsDutiesTaxes : "",
                "transportIncluded": this.form.customsDeclarationSectionThree.transportIncluded !== undefined ? this.form.customsDeclarationSectionThree.transportIncluded : "",
                "insuranceIncluded": this.form.customsDeclarationSectionThree.insuranceIncluded !== undefined ? this.form.customsDeclarationSectionThree.insuranceIncluded : "",
                "invoice_currency": currencyFullName !== undefined ? currencyFullName : "",
                "referenceNumber": this.$route.query.referenceNumber !== undefined ? this.$route.query.referenceNumber : "",
            }
            console.log(declarationData)
            return declarationData;
        },
        async generateBlanlValuationForPreSigning(){
            console.log('Generating C75 Declaration...');
            try {
                console.log('Generating customs declaration...');
                SweetAlertConnector.showLoadingAlert('Generating customs declaration...');
                const response = await DatabaseConnector.generateBlankValuationForm();
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('Valuation Form Generated Successfully!');
                const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                const fileName = `Blank Valuation Form.pdf`;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                fileLink.remove();
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to generate document!');
                console.error('Failed to generate the declaration:', error);
            }
        },
        async generateC75Declaration() {
            const declarationData = this.prepareDeclarationObject();
            if(declarationData.declarantNameAddress === "" || declarationData.declarantNameAddress === undefined){
                SweetAlertConnector.showErrorAlert('Please select a declarant!');
                return;
            }
            console.log('Generating C75 Declaration...');
            try {
                console.log('Generating customs declaration...');
                SweetAlertConnector.showLoadingAlert('Generating customs declaration...');
                const response = await DatabaseConnector.generateCustomsDeclaration(declarationData);
                const referenceNumber = this.$route.query.referenceNumber;
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('C75 Generated Successfully!');
                const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                const fileName = `Customs Declaration - ${referenceNumber}.pdf`;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                fileLink.remove();
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to generate the declaration:', error);
            }
        },
        async generatePreSignedValuation() {
            const declarationData = this.prepareDeclarationObject();
            console.log('Generating C75 Declaration...');
            try {
                console.log('Generating customs declaration...');
                SweetAlertConnector.showLoadingAlert('Generating customs declaration...');
                const response = await DatabaseConnector.generatePreSignedValuationForm(declarationData);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('C75 Generated Successfully!');
                const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                const fileName = `Customs Declaration - ${this.entryNumber}.pdf`;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                fileLink.remove();
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to generate the declaration:', error);
            }
        },
        async generatePreSignedValuationVersionTwo(){
            const declarationData = this.prepareDeclarationObject();
            console.log('Generating C75 Declaration...');
            try {
                console.log('Generating customs declaration...');
                SweetAlertConnector.showLoadingAlert('Generating customs declaration...');
                const response = await DatabaseConnector.generatePreSignedValuationFormVersion2(declarationData);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('C75 Generated Successfully!');
                const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                const fileName = `Customs Declaration - ${this.entryNumber}.pdf`;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                fileLink.remove();
            } catch (error) {
                SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to generate the declaration:', error);
            }

        },
        async updateC75Declaration(){
            console.log('Updating C75 Declaration...');
            const declarationData = this.prepareDeclarationObject();
            try {
                SweetAlertConnector.showLoadingAlert('Saving Data...');
                console.log('Updating customs declaration...');
                await DatabaseConnector.updateCustomsDeclaration(this.entryId, declarationData);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert('All Data Saved successfully!');
                console.log('Customs declaration updated successfully.');
            } catch (error) {
              SweetAlertConnector.showErrorAlert('Failed to save Entry Data!');
                console.error('Failed to update the declaration:', error);
            }
        },
        async fetchCustomsDeclarations(){
            try {
                console.log('Getting C75 Entry...');
                const response = await DatabaseConnector.fetchCustomsDeclarations(this.entryId);
                if(response.status === 206){
                    console.log('No C75 Entry found.');
                    this.newC75 = true;
                    // this.form.customsDeclarationSectionThree.netPrice = response.data.invoiceTotal;
                    // this.form.customsDeclarationSectionOne.invoiceNumber = response.data.invoiceNumbers;
                    // this.form.customsDeclarationSectionThree.exchangeRate = response.data.rate_of_exchange;
                    this.entryNumber = response.data.entry_number? response.data.entry_number : '';
                    console.log('Entry Number:', this.entryNumber);
                    return;
                }
                this.newC75 = false;
                console.log('C75 Entry found.');
                this.updateFields(response.data);
            } catch (error) {
                console.error('Failed to get the C75 Entry:', error);
            }
        },
        syncData(){
          this.form.customsDeclarationSectionOne.buyerId = parseInt(this.$route.query.buyerId);
          this.form.customsDeclarationSectionOne.sellerId = parseInt(this.$route.query.sellerId);
          this.form.customsDeclarationSectionOne.invoiceNumber = this.$route.query.invoiceNumber;
          this.form.customsDeclarationSectionThree.netPrice = this.$route.query.invoiceTotal;
          this.form.customsDeclarationSectionThree.invoiceCurrency = this.$route.query.currency;
          this.form.customsDeclarationSectionThree.exchangeRate = this.$route.query.rateOfExchange;
          this.form.customsDeclarationSectionThree.transport = this.$route.query.freight;
          this.form.customsDeclarationSectionThree.insurance = this.$route.query.insurance;
          this.form.customsDeclarationSectionOne.termsOfDelivery = this.$route.query.incoTerms ? this.$route.query.incoTerms : '';

          Promise.all([DatabaseConnector.fetchShippers(), DatabaseConnector.fetchBuyers(), DatabaseConnector.fetchVessels(), DatabaseConnector.fetchDeclarants(), DatabaseConnector.fetchCountries()]).then(([exporters, importers, vessels, declarants, countries]) => { 
            this.form.customsDeclarationSectionOne.sellers = exporters;
            this.form.customsDeclarationSectionOne.buyers = importers;
            this.form.customsDeclarationSectionOne.vessels = vessels;
            this.form.customsDeclarationSectionOne.declarants = declarants;
            this.form.customsDeclarationSectionOne.countryList = countries;
           });
        },
        convertTinyIntoString(tiny){
            return tiny === 1 ? 'yes' : 'no';
        },
        updateFields(responseData){
            console.log('Updating fields...');
            this.form.customsDeclarationSectionOne.sellerNameAddress = responseData.seller_name_and_address;
            this.form.customsDeclarationSectionOne.buyerNameAddress = responseData.buyer_name_address;
            this.form.customsDeclarationSectionOne.declarantNameAddress = responseData.declarant_name_address;
            this.form.customsDeclarationSectionOne.termsOfDelivery = responseData.terms_of_delivery;
            this.form.customsDeclarationSectionOne.contractNumber = responseData.name_date_contract;
            this.form.customsDeclarationSectionOne.invoiceNumber = responseData.number_date_invoice || this.$route.query.invoiceNumber || "";
            this.form.customsDeclarationSectionOne.sellerId = responseData.seller_id;
            this.form.customsDeclarationSectionOne.buyerId = responseData.buyer_id;
            this.form.customsDeclarationSectionOne.declarantId = responseData.declarant_id;
            this.form.customsDeclarationSectionTwo.relatedParties = this.convertTinyIntoString(responseData.related_parties_boolean);
            this.form.customsDeclarationSectionTwo.influencePrice = this.convertTinyIntoString(responseData.influence_price_boolean);
            this.form.customsDeclarationSectionTwo.transactionValueApproximate = this.convertTinyIntoString(responseData.transaction_value_approximate_boolean);
            this.form.customsDeclarationSectionTwo.restrictions = this.convertTinyIntoString(responseData.restrictions_disposal_boolean);
            this.form.customsDeclarationSectionTwo.conditions = this.convertTinyIntoString(responseData.sale_price_condition_boolean);
            this.form.customsDeclarationSectionTwo.royalties = this.convertTinyIntoString(responseData.royalties_boolean);
            this.form.customsDeclarationSectionTwo.resale_disposal_boolean = this.convertTinyIntoString(responseData.resale_disposal_boolean);
            this.form.customsDeclarationSectionTwo.date_signed = responseData.date_signed;
            this.form.customsDeclarationSectionTwo.status_of_signatory = responseData.status_of_signatory;
            this.form.customsDeclarationSectionTwo.signatory_phone_number = responseData.signatory_phone_number;
            this.form.customsDeclarationSectionTwo.signatory_name = responseData.signatory_name;
            this.form.customsDeclarationSectionThree.netPrice = responseData.invoiceTotal || this.$route.query.invoiceTotal || 0.0;
            this.form.customsDeclarationSectionThree.indirectPayment = responseData.indirect_payment; // To Include
            this.form.customsDeclarationSectionThree.exchangeRate = responseData.rate_of_exchange || this.$route.query.rateOfExchange || 1.0;
            this.form.customsDeclarationSectionThree.commissions = responseData.costs_commissions;
            this.form.customsDeclarationSectionThree.brokerage = responseData.costs_brokerage;
            this.form.customsDeclarationSectionThree.containersPacking = responseData.costs_containers_packing;
            this.form.customsDeclarationSectionThree.materials = responseData.goods_free_of_charge_materials;
            this.form.customsDeclarationSectionThree.tools = responseData.goods_free_of_charge_tools;
            this.form.customsDeclarationSectionThree.materialsConsumed = responseData.goods_free_of_charge_materials_consumed;
            this.form.customsDeclarationSectionThree.engineering = responseData.goods_engineering_development;
            this.form.customsDeclarationSectionThree.royalties = responseData.royalties_license_fee;

            this.form.customsDeclarationSectionThree.proceedsResale = responseData.procees_resale_disposal;
            this.form.customsDeclarationSectionThree.transport = responseData.cost_delivery_transport;
            this.form.customsDeclarationSectionThree.loadingHandling = responseData.cost_delivery_loading;
            this.form.customsDeclarationSectionThree.insurance = responseData.cost_delivery_insurance;
            this.form.customsDeclarationSectionFour.costAfterArrival = responseData.cost_transport_after_arrival;
            this.form.customsDeclarationSectionFour.chargesAfterImportation = responseData.charges_construction;
            this.form.customsDeclarationSectionFour.otherCharges = responseData.other_charges;

            this.form.customsDeclarationSectionFour.customsDutiesTaxes = responseData.customs_duties_taxes;

        }
    }
}
</script>

<style scoped>
.my-form-container {
  text-align: left;
}
</style>
