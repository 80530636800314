<template lang="">
    <h1>Verify Document Data</h1>
    <div class="container-fluid text-center verify-document-data-body">
        <div class="row review-document-item" v-for="(invoice, index) in invoices" :key="invoice.name">
            <div class="col">
                <embed
                    :src="pdfFiles[index].url"
                    width="100%"
                    height="100%"
                    type="application/pdf"
                    class="pdf-viewer"
                />
            </div>
            <div class="col document-review-table">
                <DocumentTableView
                    :invoiceDataArray="[invoice]"
                    :customsEntryForm="customsEntryForm"
                    :documentVerificationFormat="true"
                />
            </div>
        </div>
    </div>
</template>
<script>
import DocumentTableView from "./DocumentTableView.vue";
export default {
    props: ["invoices", "customsEntryForm", "pdfFiles"],
    components: {
        DocumentTableView,
    },
};
</script>
<style lang="css">
.verify-document-data-body {
    padding-bottom: 2rem;
}

div.review-document-item {
    margin: 1rem;
    padding-block: 2rem;
    box-shadow: 0px 0px 18px 2px #d0cfcf;
    border-radius: 15px;
    margin-block: 2rem;
}

div.document-review-table {
    border: 5px solid #e4e4e4;
    border-radius: 15px;
    padding: 2rem;
    margin-inline: 0.8rem;
    max-height: 100vh;
    overflow-y: auto;
}
embed.pdf-viewer {
    border-radius: 15px;
}
</style>
