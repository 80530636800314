// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from '../components/HelloWorld.vue'
import ViewEntries from '../components/viewEntries.vue';
import EntryDetails from '@/components/EntryDetails.vue';
import CustomsDeclarationForm from '@/components/CustomsDeclarationForm.vue';
import BuyersPage from '@/components/ConfigurationPages/BuyersPage.vue';
import SellersPage from '@/components/ConfigurationPages/SellersPage.vue';
import DeclarantsPage from '@/components/ConfigurationPages/DeclarantsPage.vue';
import VesselsPage from '@/components/ConfigurationPages/VesselsPage.vue';
import ExchangeRatesPage from '@/components/ConfigurationPages/RateOfExchangePage.vue';
import VehicleEntryCreatePage from '@/components/VehicleEntryCreatePage.vue';
import VehicleEntryEditPage from '@/components/VehicleEntryEditPage.vue';

import ApprovedClassificationEntries from '@/components/ApprovedClassificationEntries.vue';
import PartialStorageEntries from '@/components/PartialStorageEntries.vue';
import FullStorageEntries from '@/components/FullStorageEntries.vue';
import PerformanceDashboard from '@/components/PerformanceAnalysis/PerformanceDashboard.vue';
import AllEntries from '@/components/AllEntries.vue';
import ClassificationAccuracyReport from '@/components/PerformanceAnalysis/ClassificationAccuracyReport.vue';
import Login from '@/components/LoginForm.vue';


const routes = [
  {
    path: '/',
    name: 'NewEntry',
    component: HelloWorld // Your existing 'New Entry' page
  },
  {
    path: '/view-entries',
    name: 'ViewEntries',
    component: ViewEntries // The 'View Entries' page you want to create
  },
  {
    path: '/entries/:entryId', // :entryId is a dynamic segment
    name: 'EntryDetails',
    component: EntryDetails,
    props: true // Pass route.params to the component as props
  },
  {
    path: '/customs-declaration/:entryId', // :entryId is a dynamic segment
    name: 'CustomsDeclarationForm',
    component: CustomsDeclarationForm,
    props: true // Pass route.params to the component as props
  },
  {
    path: '/buyers',
    name: 'BuyersPage',
    component: BuyersPage
  },
  {
    path: '/sellers',
    name: 'SellersPage',
    component: SellersPage
  },
  {
    path: '/declarants',
    name: 'DeclarantsPage',
    component: DeclarantsPage
  },
  {
    path: '/vessels',
    name: 'VesselsPage',
    component: VesselsPage
  },
  {
    path: '/rate-of-exchange',
    name: 'RateOfExchnagePage',
    component: ExchangeRatesPage
  },
  {
    path: '/create-vehicle-entry',
    name: 'VehicleEntry',
    component: VehicleEntryCreatePage
  },
  {
    path: '/edit-vehicle-entry/:entryId',
    name: 'VehicleEntryEditPage',
    component: VehicleEntryEditPage,
  },
  {
    path: '/approved-classification-entries',
    name: 'ApprovedClassificationEntries',
    component: ApprovedClassificationEntries
  },
  {
    path: '/partial-storage-entries',
    name: 'PartialStorageEntries',
    component: PartialStorageEntries
  },
  {
    path: '/full-storage-entries',
    name: 'FullStorageEntries',
    component: FullStorageEntries
  },
  {
    path: '/performance-dashboard',
    name: 'PerformanceDashboard',
    component: PerformanceDashboard
  },
  {
    path: '/all-entries/:entryIds',
    name: 'AllEntries',
    component: AllEntries,
    props: true
  },
  {
    path: '/classification-accuracy-report',
    name: 'ClassificationAccuracyReport',
    component: ClassificationAccuracyReport,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
];

const router = createRouter({
  history: createWebHistory(), // Use history mode to avoid hashes in URLs
  routes // short for `routes: routes`
});

export default router;
