<template>
    <div>
      <VehicleEntry @submit="handleCreate" />
    </div>
  </template>
  
  <script>
  import VehicleEntry from './VehicleEntry.vue';
  import * as DatabaseConnector from '@/composables/DatabaseConnector';
  import * as SweetAlertConnector from '../composables/SweetAlertConnector';
  
  export default {
    components: { VehicleEntry },
    methods: {
      async handleCreate(entryData) {
       try {
          SweetAlertConnector.showLoadingAlert('Saving your Entry...');
          DatabaseConnector.createToyotaEntry(entryData).then((response) => {
            console.log('Entry created successfully:', response);
            const entryId = response.entryId;
            SweetAlertConnector.closeCurrentAlert();
            SweetAlertConnector.showSuccessAlert('Entry created successfully');
            this.$router.push({ name: 'VehicleEntryEditPage', params: { entryId } });
          }).catch((error) => {
            console.error('Failed to create the Entry:', error);
            SweetAlertConnector.showErrorAlert('Failed to create the Entry. Please try again.');
          });
          
        } catch (error) {
          console.error('Failed to create the invoice:', error);
          SweetAlertConnector.showErrorAlert('Failed to create the Entry. Please try again.');
        }
      },
    },
  };
  </script>
  