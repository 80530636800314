<template>
  <div class="exchange-rates-page">
    <h1>Exchange Rates</h1>
    <form class="my-form-container" @submit.prevent="addExchangeRate">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="baseCurrency"><strong>Currency</strong></label>
            <v-select :options="currencies" v-model="baseCurrency" label="name" id="invoiceCurrency" :searchable="true" :filterable="true" :reduce="currency => currency.code"></v-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="rate"><strong>Exchange Rate</strong></label>
            <input type="text" class="form-control" id="rate" placeholder="Please Enter Exchange Rate" v-model="rate" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="effectiveDate"><strong>Effective Date</strong></label>
            <input type="date" class="form-control" id="effectiveDate" placeholder="Please Enter Effective Date" v-model="effectiveDate" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <p><strong></strong></p>
            <button class="btn btn-primary" type="submit">Add Rate</button>
          </div>
        </div>
      </div>
    </form>
    <div v-for="(rates, date) in groupedExchangeRates" :key="date" class="rate-group">
      <h3>{{ date }}</h3>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Currency Code</th>
            <th scope="col">Currency Name</th>
            <th scope="col">Exchange Rate</th>
            <th scope="col">Effective Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rate, index) in rates" :key="rate.id">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ rate.base_currency }}</td>
            <td>{{ rate.base_currency_name }}</td>
            <td>{{ rate.rate }}</td>
            <td>{{ formatDate(rate.last_updated) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
<script>
import * as DatabaseConnector from '@/composables/DatabaseConnector';
import * as Currency from '@/composables/Currency'
import * as SweetAlertConnector from '@/composables/SweetAlertConnector';
import * as Utilities from '@/composables/Utilities';
export default {
  name: 'ExchangeRatesPage',
  data() {
    return {
      exchangeRates: [],
      currencies: [],
      baseCurrency: '',
      rate: '',
      effectiveDate: this.getCurrentDate(),
    };
  },
  computed: {
    sortedExchangeRates() {
      return [...this.exchangeRates].sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
    },
    groupedExchangeRates() {
      return this.sortedExchangeRates.reduce((groups, rate) => {
        // const date = rate.last_updated.split('T')[0]; // Assuming last_updated is an ISO string
        const date = this.formatDate(rate.last_updated);
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(rate);
        return groups;
      }, {});
    }
  },
  methods: {
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
      const day = today.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    getCurrencyName(code) {
      const currency = this.currencies.find(currency => currency.code === code);
      return currency ? currency.name : '';
    },
    modifyExchangeRates() {
      this.exchangeRates = this.exchangeRates.map(rate => {
        return {
          ...rate,
          base_currency_name: this.getCurrencyName(rate.base_currency),
        };
      });
    },
    formatDate(dateString) {
      return Utilities.formatDate(dateString);
    },
    async getExchangeRates() {
      this.exchangeRates = await DatabaseConnector.fetchExchangeRates();
      console.log("Exchange Rates", this.exchangeRates);
      this.modifyExchangeRates();
    },
    async addExchangeRate() {
      const currentDate = new Date(this.effectiveDate).toISOString();
      const rate = {
        baseCurrency: this.baseCurrency,
        targetCurrency: 'TTD',
        rate: this.rate,
        lastUpdated: currentDate,
      };
      console.log("Rate", rate);
      DatabaseConnector.addExchangeRate(rate).then(() => {
        SweetAlertConnector.showSuccessAlert('Exchange Rate Added Successfully');
        this.getExchangeRates();
        this.baseCurrency = '';
        this.rate = '';
      }).catch(() => {
        SweetAlertConnector.showErrorAlert('Failed to Add Exchange Rate');
      });
      this.getExchangeRates();
    }
  },
  mounted() {
    this.getExchangeRates();
    console.log("Currencies", Currency.getCurrencyList());
    this.currencies = Currency.getCurrencyList();
  }
}
</script>

<style scoped>
.exchange-rates-page {
  padding: 20px;
  text-align: center;
}
.form-group {
    margin-bottom: 20px; /* Adds space below each form group */
}
.form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
}
.form-check label {
    margin-right: 1rem;
}
.my-form-container {
    text-align: left;
}
</style>
