import { ref } from 'vue'

export function getIncoTermsList() {
    const incoTermsList = ref([
        { code: "EXW", name: "Ex Works" },
        { code: "FCA", name: "Free Carrier" },
        { code: "CPT", name: "Carriage Paid To" },
        { code: "CIP", name: "Carriage and Insurance Paid To" },
        { code: "DAP", name: "Delivered At Place" },
        { code: "DAT", name: "Delivered At Terminal" },
        { code: "DDP", name: "Delivered Duty Paid" },
        { code: "FAS", name: "Free Alongside Ship" },
        { code: "FOB", name: "Free On Board" },
        { code: "CFR", name: "Cost and Freight" },
        { code: "CIF", name: "Cost, Insurance and Freight" }
    ])
    return incoTermsList
}