// Example: Error messages
export const ENTRY_TYPES = {
  STANDARD: 'STANDARD',
  VEHICLES: 'VEHICLES',
};

export const CLASSIFICATION_STATUS = {
  NOT_CLASSIFIED: 'Not Classified',
  NOT_STARTED: 'Classification Not Started',
  IN_PROGRESS: 'Classification In Progress',
  COMPLETED: 'Classification Completed',
  APPROVED: 'Classififcation Approved',
}

export const ENTRY_STATUS = {
  STORED_AND_NOT_CLASSIFIED: 'STORED_AND_NOT_CLASSIFIED',
  CLASSIFICATION_OKAY: 'CLASSIFICATION_OKAY',
  PARTIAL_STORAGE: 'PARTIAL_STORAGE',
  FULL_STORAGE: 'FULL_STORAGE',
}

export const INVOICE_AI_MODELS = {
  MICROSOFT_FORM_RECOGNIZER: 'MICROSOFT_FORM_RECOGNIZER',
  CLAUDE_LLM: 'CLAUDE_LLM',
}

export const LLM_PROMPTS = {
  READ_WAYBILL: `Extract data from the provided document. Only extract and include fields that are present within the document. Exclude items, documents, or pages that are irrelevant, such as additional notes or detailed packing lists that do not directly pertain to shipment or cargo information.
        Do not force the extraction of line items. If there are no logical line items within the document, assign an empty array to the key or omit the line item from the existing list.
        For each item, retrieve all pieces of information within the document that can aid in accurately describing the shipment, including but not limited to item description, weight, volume, and any handling instructions. Ensure to concatenate these pieces of information and assign them to the corresponding key.
        Please make sure to include all line items from the document, starting from the very first one. If there are no line items, assign an empty array to the key.
        Include the response in the <output></output> tags. Use the following JSON schema:
        [
            {
                "waybill_number": "",
                "shipment_date": "(yyyy-mm-dd)",
                "mode_of_transport": "(Air / Ocean)",
                "vessel_name": "",
                "country_of_origin": "",
                "carrier_name": "",
                "shipper_name": "",
                "shipper_address": "",
                "shipper_contact": "",
                "consignee_name": "",
                "consignee_address": "",
                "consignee_contact": "",
                "notify_party_name": "",
                "notify_party_address": "",
                "notify_party_contact": "",
                "place_of_receipt": "",
                "port_of_loading": "",
                "port_of_discharge": "",
                "place_of_delivery": "",
                "marks_and_numbers": "",
                "container_number": "",
                "seal_number": "",
                "number_of_packages": "",
                "package_type": "",
                "gross_weight": "",
                "net_weight": "",
                "volume": "",
                "freight_charges": "",
                "shipment_instructions": "",
                "description_of_goods": "",
                "item_details": [
                    {
                        "item_description": "",
                        "item_weight": "",
                        "item_volume": "",
                        "item_classification_description": "(Concatenate item description, weight, volume, and any other details)",
                        "item_quantity": "",
                        "country_of_origin": "",
                        "package_type": ""
                    }
                ],
                "total_weight": "(sum of item weights)",
                "total_volume": "(sum of item volumes)",
                "freight_charges_amount": "",
                "additional_charges_amount": "",
                "total_amount": "(freight charges + additional charges)"
            }
        ]
        If a field is not present, please provide an empty string value for the missing key.
        Do not include anything in the output tag that isn't parsable as JSON, and do not include unnecessary words in the <output></output> tags.
        Generate in batch with a maximum count of 40 line items. If there is more content to be generated after closing the <output> tag, insert <more-content>. If not, insert <end-of-content> after closing the <output> tag.`
}
