<template>
    <div id="app">
        <div class="page-header-info-container">
            <div>
                <button type="button" class="btn btn-primary upload-button" @click="verifyInvoiceData">
                    Invoice Split Screen
                </button>
                <button type="button" class="btn btn-primary upload-button" @click="mergeAndOpenPDFs">
                    Open Invoices
                </button>
                <button type="button" class="btn btn-primary upload-button" @click="openWaybill">
                    Open Waybill
                </button>
            </div>
            <div>
                <div class="badge-container">
                    <h3>
                        <span
                            :class="[
                                'badge',
                                {
                                    'bg-success': classificationStatus === 'Classification Completed',
                                    'bg-warning text-dark': classificationStatus === 'Classification In Progress',
                                    'bg-danger': classificationStatus === 'Not Classified',
                                },
                                'fixed-badge',
                            ]"
                        >
                            {{ this.classificationStatus }}
                        </span>
                    </h3>
                </div>
            </div>
        </div>

        <VerifyDocumentData
            :invoices="invoiceDataArray"
            :customsEntryForm="customsEntryForm"
            v-if="displayDocumentVerificationView"
            :pdfFiles="pdfFiles"
        />

        <div v-if="!displayDocumentVerificationView">
            <h2>New Entry</h2>
            <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-2">
                    <input
                        type="text"
                        class="form-control"
                        v-model="referenceNumber"
                        placeholder="Enter Reference Number"
                        @input="validateInput"
                    />
                </div>
                <div class="col-md-5"></div>
            </div>
            <div>
                <div
                    class="dropzone"
                    @dragover.prevent="dragOver"
                    @dragenter.prevent="dragEnter"
                    @dragleave.prevent="dragLeave"
                    @drop.prevent="handleDrop"
                >
                    Please Upload Commercial Invoices
                    <input
                        type="file"
                        multiple
                        @change="handleFileUpload"
                        style="display: none"
                        accept="application/pdf"
                        ref="invoices"
                    />
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="triggerFileInput('invoices')"
                    >
                        Choose Files for Invoices
                    </button>
                    <input
                        type="file"
                        @change="handleWaybillUpload"
                        style="display: none"
                        accept="application/pdf"
                        ref="waybill"
                    />
                    <button
                        type="button"
                        class="btn btn-primary upload-button"
                        @click="triggerFileInput('waybill')"
                    >
                        Choose Files for Waybills
                    </button>
                    <!-- <button type="button" class="btn btn-primary upload-button" @click="uploadFiles">
                        Upload Files AWS
                    </button> -->
                    <button type="button" class="btn btn-success upload-button" @click="uploadInvoicesAndWaybills('https://mawi-updraded-api.wonderfulflower-f4234c00.eastus.azurecontainerapps.io/analyze-invoices-OCR-LLM', 'CLAUDE_LLM')">
                        Upload Files LLM
                    </button>
                    <!-- <button type="button" class="btn btn-primary upload-button" @click="uploadInvoicesAndWaybills()">
                        Upload Files GABRIEL
                    </button> -->
                    
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <ul>
                            <li v-for="(file, index) in selectedFiles" :key="index">
                                {{ file.name }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul>
                            <li v-for="(file, index) in waybillFiles" :key="index">
                                {{ file.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <!-- <div class="container-fluid text-center">
                <div class="row">
                    <div class="col">
                        <FileDragAndDrop />
                    </div>
                    <div class="col">
                        <FileDragAndDrop />
                    </div>
                </div>
            </div> -->
            <CustomsEntryForm
                :customsEntryForm="customsEntryForm"
                @update-customs-entry-form="customsEntryForm = $event"
            />
            <DocumentTableView
                :invoiceDataArray="invoiceDataArray"
                :customsEntryForm="customsEntryForm"
                :documentVerificationFormat="false"
                :linesToBeDeleted="linesToBeDeleted"
            />
            
            <div v-if="selectedFiles.length">
                <h3>Entry Summary</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Number of Lines Read</th>
                            <th>Total Invoices Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ this.totalNumberOfLines }}</td>
                            <td>{{ this.totalFOBValue }}</td>
                        </tr>
                        <tr>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    :disabled="entryButtonDisabled"
                                    @click="createInvoices(invoiceDataArray)"
                                >
                                    Save Entry
                                </button>
                            </td>
                            <!-- <td><button type="button" class="btn btn-primary" :disabled="thnCodesCompleted" @click="generateWorkSheet">Generate WorkSheet</button></td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import * as DatabaseConnector from "@/composables/DatabaseConnector";
import * as Utilities from "@/composables/Utilities";
import * as SweetAlertConnector from "../composables/SweetAlertConnector";
import * as PackageTypes from "@/composables/PackageTypes";
import * as Configuration from "@/composables/BrokerConfiguration";
import VerifyDocumentData from "./VerifyDocumentData.vue";
import DocumentTableView from "./DocumentTableView.vue";
import FileDragAndDrop from "./FileDragAndDrop.vue";
import { PDFDocument } from 'pdf-lib';
import CustomsEntryForm from "./CustomsEntry/CustomsEntryForm.vue";
import { ENTRY_TYPES, ENTRY_STATUS } from "../constants";
import { CLASSIFICATION_STATUS, INVOICE_AI_MODELS } from "../constants";

export default {
    /* eslint-disable */
    name: "App",
    components: {
        CustomsEntryForm,
        VerifyDocumentData,
        DocumentTableView,
        FileDragAndDrop
    },
    data() {
        return {
            displayDocumentVerificationView: false,
            referenceNumber: "",
            selectedFiles: [],
            waybillFiles: [],
            pdfFiles: [],
            originalInvoiceDataArray: [],
            invoiceDataArray: [],
            linesToBeDeleted: [],
            isDragOver: false,
            isValidReference: false,
            invoicesDataExtractionCompleted: false,
            waybillDataExtractionCompleted: false,
            classificationCompleted: false,

            waybillNumber: "",
            waybillDate: new Date().toISOString().substr(0, 10),
            modeOfTransport: "",

            freightType: "",
            marksAndNumbers: "As Addressed",
            countryOfOrigin: "",
            cpcCode: "4000",
            npcCode: "000",

            kindOfPackage: "CT",
            numberOfPackages: "",

            consignee: "",
            shipper: "",
            supplier: "",
            totalCargoValue: 0.0,
            totalNumberOfLines: 0,
            freightCharge: 0.0,
            rateOfExchange: 1.0,
            netWeight: 0.0,
            grossWeight: 0.0,

            editableContent: "0",

            countries: [],
            modeOfTransportOOptions: ["Air", "Ocean"],
            freightTypeOptions: ["FCL", "LCL", "Bulk", "Break Bulk"],
            cpcCodeOptions: ["4000"],
            npcCodeOptions: ["000"],
            packageTypeOptions: [],

            showModeofTransportDropdown: false,

            invoicingProcessingStart: null,
            invoicingProcessingEnd: null,
            invoiceModelUtilized: "",

            classificationProcessingStart: null,
            classificationProcessingEnd: null,

            customsEntryForm: {
                exporters: [],
                importers: [],
                vessels: [],
                ports: [],
                declarants: [],
                incotermsList: [],
                ratesOfExchange: [],
                importerId: "",
                exporterId: "",
                importerName: "",
                exporterName: "",
                importerTaxIdentificationNumber: "",
                exporterTaxIdentificationNumber: "",
                declarantId: "",
                declarantTaxIdentificationNumber: "",
                modeOfTransport: "",
                freightType: "",
                waybillNumber: "",
                waybillDate: "",
                estimatedArrivalDate: "",
                vesselId: "",
                vesselName: "",
                incoTerms: "",
                portOfDischargeId: "",
                portOfDischargeName: "",
                netWeight: 0.0,
                grossWeight: 0.0,
                numberOfPackages: 0,
                packageType: "",
                packageTypes: [],
                marksAndNumbers: "As Addressed",
                totalCargoValue: 0.0,
                freightAmount: 0.0,
                insuranceAmount: 0.0,
                otherCharges: 0.0,
                freightCurrency: "USD",
                insuranceCurrency: "USD",
                otherChargesCurrency: "USD",
                insuranceRateOfExchange: 0.0,
                otherChargesRateOfExchange: 0.0,
                freightRateOfExchange: 0.0,
                rateOfExchange: 0.0,
                regimeType: "",
                regimeTypeCode: "",
                regimeTypeOptions: [],
                depositAmount: 0.0,
                containerCharges: 0.0,
                additionalCharges: 0.0,
                localFee: 0.0,
                countryOfOrigin: "",
                countryOfFinalDestination: "TT",
                countryLastProvinance: "",
                tradingCountry: "",
            },
        };
    },
    watch: {
        "customsEntryForm.countryOfOrigin"(newCountry, oldCountry) {
            this.invoiceDataArray.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    if (!item.country_of_origin || item.country_of_origin.trim().length === 0) {
                        item.country_of_origin = newCountry;
                    }
                });
            });
        },
        "customsEntryForm.regimeType"(newRegime) {
            this.invoiceDataArray.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                        (regime) => regime.regimeTypeId === newRegime
                    );
                    if(regimeData){
                        item.cpcCode = regimeData.cpcCodes[0].cpcId;
                    }
                });
            });
        },
        "customsEntryForm.waybillDate"(newDate) {
            this.updateRates(newDate);
        },
        invoiceDataArray: {
            handler: function (invoices) {
                if (this.customsEntryForm.ratesOfExchange.length > 0) {
                    invoices.forEach((invoice) => {
                        this.$watch(
                            () => invoice.currency,
                            (newCurrency) => {
                                if (newCurrency) {
                                    invoice.rate_of_exchange = this.customsEntryForm.ratesOfExchange.find(
                                        (rate) => rate.base_currency === newCurrency
                                    ).rate;
                                }
                            },
                            { deep: true }
                        );
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },

    setup() {
        return {};
    },
    mounted() {
        this.packageTypeOptions = PackageTypes.usePackageTypes();
        this.updateEntryFields("marksAndNumbers", "As Addressed");
        this.getTotalNumberOfLines();
        this.getRegimeTypes();
    },
    computed: {
        classificationStatus() {
            if (this.invoiceDataArray.length === 0) return CLASSIFICATION_STATUS.NOT_CLASSIFIED;
            return this.thnCodesCompleted ? CLASSIFICATION_STATUS.NOT_CLASSIFIED : CLASSIFICATION_STATUS.COMPLETED;
        },
        thnCodesCompleted() {
            return this.invoiceDataArray.some((invoice) => {
                return invoice.lines.some((line) => !line.tariff_code || line.tariff_code.trim() === "");
            });
        },
        totalFOBValue() {
            return this.invoiceDataArray
                .reduce((acc, invoice) => acc + parseFloat(invoice.invoice_total), 0)
                .toFixed(2);
        },
        entryButtonDisabled() {
            return !(this.hasInvoiceNumber || this.waybillNumber.trim().length > 0 || this.consignee.trim().length > 0);
        },
        hasInvoiceNumber() {
            for (let invoice of this.invoiceDataArray) {
                if (invoice.invoice_number && invoice.invoice_number.trim().length > 0) {
                    console.log("Invoice Number Found");
                    return true;
                }
            }
            return false;
        },
        availableCPCCodes() {
            if (this.customsEntryForm.regimeTypeCode !== undefined && this.customsEntryForm.regimeTypeCode !== null) {
                const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                    (regime) => regime.regimeTypeCode === this.customsEntryForm.regimeTypeCode
                );
                if (regimeData) {
                    return regimeData.cpcCodes;
                } else return [];
            } else {
                return this.customsEntryForm.regimeTypeOptions.flatMap((regime) => regime.cpcCodes);
            }
        },
    },
    methods: {
        verifyInvoiceData,
        dataExtractionLoadingMessage(){
            if(this.invoicesDataExtractionCompleted && this.waybillDataExtractionCompleted){
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert("Data Extraction Completed");
                if(!this.classificationCompleted){
                    SweetAlertConnector.asyncLoadingAlert("Classifying Invoice Items...");
                }
            }
            else if(this.invoicesDataExtractionCompleted && !this.waybillDataExtractionCompleted){
                // SweetAlertConnector.updateCurrentAlert("Invoice data read. Still Reading Waybill Data");
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.asyncLoadingAlert("Invoice Data Read. Reading Waybill Data...");
            }
            else if(!this.invoicesDataExtractionCompleted && this.waybillDataExtractionCompleted){
                // SweetAlertConnector.updateCurrentAlert("Waybill data read. Still Reading Invoice Data");
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.asyncLoadingAlert("Waybill Data Read. Reading Invoice Data...");
            }
        },
        triggerFileInput(refName) {
            console.log("Triggering File Input");
            this.$refs[refName].click();
        },
        updateTariffCode(data, itemIndex, field, value) {
            // console.log("Updating Tariff Code");
            // console.log("Item Index: ", itemIndex);
            this.updateItem(data, itemIndex, field, value);
            if (value === undefined || value === null || value.trim().length === 0 || value === "N/A") {
                data.icdCharge = 0;
                data.vatCharge = 0;
                return;
            }
            console.log("Fetching charges for: ", value);
            DatabaseConnector.getCommodityCharges(value.replace(/\./g, ""))
                .then((chargesResponse) => {
                    let icdObject = chargesResponse.charges["import custom duty"];
                    const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject[0].charge_amount);
                    let vatObject = chargesResponse.charges["value added tax"];
                    const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject[0].charge_amount);
                    // console.log("ICD Charge: ", icdCharge);
                    // console.log("VAT Charge: ", vatCharge);
                    data.lines[itemIndex]["icdCharge"] = icdCharge;
                    data.lines[itemIndex]["vatCharge"] = vatCharge;
                    data.lines[itemIndex]["commodityCodeStatus"] = "valid";
                    data.lines[itemIndex]["commodity_description"] = chargesResponse.description;
                })
                .catch((error) => {
                    console.error("Failed to get commodity charges:", error);
                    data.commodityCodeStatus = "invalid";
                    data.icdCharge = 0;
                    data.vatCharge = 0;
                });
        },
        updateRates(shippedOnBoardDate) {
            console.log("Updating Rates");
            DatabaseConnector.fetchRatesBasedOnShippedOnBoard(shippedOnBoardDate).then((rates) => {
                console.log("Rates:", rates);
                this.customsEntryForm.ratesOfExchange = rates;
                this.invoiceDataArray.forEach((invoice) => {
                    if (invoice.currency) {
                        console.log("Invoice Currency: ", invoice.currency);
                        invoice.rate_of_exchange = rates.find((rate) => rate.base_currency === invoice.currency).rate;
                    }
                });
            });
        },
        setDefaults() {
            const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                (regime) => regime.regimeTypeCode === Configuration.defaultRegimeTypeCode
            );
            this.customsEntryForm.regimeType = regimeData.regimeTypeId;
            console.log("Regime Data: ", regimeData);
        },
        async getRegimeTypes() {
            DatabaseConnector.fetchCpcCodes("TT").then((data) => {
                this.customsEntryForm.regimeTypeOptions = data;
                const regimeData = data.find(
                    (regime) => regime.regimeTypeCode === Configuration.getConfigurations().defaultRegimeType
                );
                this.customsEntryForm.regimeType = regimeData.regimeTypeId;
            });
        },
        setInvoiceLineWorksheetData() {
            let promises = [];
            this.invoiceDataArray.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    item.cpcCodeName = this.availableCPCCodes.find((cpc) => cpc.cpcId === item.cpcCode)?.code;
                    const promise = DatabaseConnector.getCommodityCharges(item.tariff_code.replace(/\./g, "")).then(
                        (chargesResponse) => {
                            let formattedCharges = Utilities.formatCharges(chargesResponse.charges);
                            item.commodity = {
                                code: item.tariff_code.replace(/\./g, ""),
                                id: chargesResponse.id,
                                charges: formattedCharges,
                            };
                            // console.log("Commodity Charges: ", item.commodity)
                        }
                    );
                    promises.push(promise);
                });
            });

            return Promise.all(promises);
        },
        generateWorkSheet() {
            console.log("Generating WorkSheet");
            this.setInvoiceLineWorksheetData();
            const worksheetObject = Utilities.formatWorkSheetData(
                this.referenceNumber,
                "TT",
                "Ramnarine Rampersad",
                "BR0285",
                this.customsEntryForm.regimeTypeCode,
                this.customsEntryForm.importerName,
                this.customsEntryForm.importerTaxIdentificationNumber,
                this.customsEntryForm.exporterName,
                this.customsEntryForm.exporterTaxIdentificationNumber,
                this.customsEntryForm.vesselName,
                this.customsEntryForm.portOfDischargeName,
                this.customsEntryForm.depositAmount,
                this.customsEntryForm.containerCharges,
                this.customsEntryForm.additionalCharges,
                this.customsEntryForm.localFee
            );
            const invoiceSummary = Utilities.formatInvoiceData(
                this.invoiceDataArray,
                this.customsEntryForm.freightAmount,
                this.totalFOBValue
            );
            worksheetObject.invoices = invoiceSummary;
            console.log("Worksheet Object: ", worksheetObject);
            DatabaseConnector.generateWorkSheet(worksheetObject)
                .then((worksheetResponse) => {
                    SweetAlertConnector.closeCurrentAlert();
                    SweetAlertConnector.showSuccessAlert("Worksheet Generated Successfully!");
                    const fileURL = window.URL.createObjectURL(
                        new Blob([worksheetResponse], {
                            type: "application/pdf",
                        })
                    );
                    const fileLink = document.createElement("a");
                    const fileName = `Worksheet - ${this.entryDetails?.entry_number}.pdf`;
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                })
                .catch((error) => {
                    console.error("Failed to generate worksheet:", error);
                    SweetAlertConnector.showErrorAlert("Failed to generate worksheet!");
                });
        },
        insertFullStops(numberString) {
            if (numberString.length !== 11) {
                console.log("The string must contain exactly 11 numbers.", numberString);
                return "";
            }

            return numberString.slice(0, 4) + "." + numberString.slice(4, 6) + "." + numberString.slice(6, 8) + "." + numberString.slice(8);
        },
        convertAWSData(invoices) {
            return invoices
                .map((invoiceArray) => {
                    return invoiceArray.map((invoice) => {
                        const summary = invoice.summary_fields;
                        const items = invoice.line_items.map((line) => {
                            // Initialize a temporary storage for the fields
                            const tempItem = {
                                product_code: "",
                                quantity: "",
                                unit_price: "",
                                description: "",
                                extension_price: "",
                                tariff_code: "",
                            };

                            // Loop through each object in the line array and assign values based on field_type
                            line.forEach((item) => {
                                switch (item.field_type) {
                                    case "PRODUCT_CODE":
                                        tempItem.product_code = item.cell_data.text;
                                        break;
                                    case "QUANTITY":
                                        tempItem.quantity = item.cell_data.text;
                                        break;
                                    case "UNIT_PRICE":
                                        tempItem.unit_price = item.cell_data.text;
                                        break;
                                    case "ITEM":
                                        tempItem.description = item.cell_data.text;
                                        break;
                                    case "PRICE":
                                        tempItem.extension_price = item.cell_data.text;
                                        break;
                                    case "recommended_commodity_code":
                                        console.log("THN Code: ", item.code);
                                        tempitem.tariff_code = this.insertFullStops(item.code.toString());
                                        break;
                                }
                            });
                            console.log("Item: ", line);
                            console.log("Temp Item: ", tempItem);
                            return tempItem;
                        });

                        return {
                            vendor_name: summary.VENDOR_NAME,
                            subtotal: summary.SUBTOTAL,
                            invoice_total: summary.TOTAL,
                            customer_name: summary.RECEIVER_NAME,
                            invoice_date: summary.INVOICE_RECEIPT_DATE,
                            invoice_number: summary.INVOICE_RECEIPT_ID,
                            lines: items,
                        };
                    });
                })
                .flat();
        },
        formatInvoiceData() {
            const allcpcCodes = this.customsEntryForm.regimeTypeOptions.flatMap((regime) => regime.cpcCodes);
            const defaultCPCId = allcpcCodes.find(
                (cpc) => cpc.code === Configuration.getConfigurations().defaultCPCCode
            ).cpcId;
            console.log("Before Update", this.invoiceDataArray);
            this.renameInvoiceObjectKeys();
            console.log("After Update", this.invoiceDataArray);
            console.log("All CPC Codes: ", allcpcCodes);
            console.log("Default CPC Code: ", defaultCPCId);
            this.invoiceDataArray.forEach((invoice) => {
                invoice.invoice_total = invoice.invoice_total ? this.parseFloatFromString(invoice.invoice_total) : (invoice.invoice_exworks_amount ? this.parseFloatFromString(invoice.invoice_exworks_amount) : 0.00)
                invoice.inland = invoice.invoice_internal_freight_amount ? this.parseFloatFromString(invoice.invoice_internal_freight_amount) : 0.00;
                invoice.insurance = invoice.invoice_insurance_amount ? this.parseFloatFromString(invoice.invoice_insurance_amount): 0.00;
                invoice.otherCharges = invoice.invoice_other_charges_amount ? this.parseFloatFromString(invoice.invoice_other_charges_amount) : 0.00;
                invoice.final_total = this.parseFloatFromString(invoice.final_total);
                invoice.invoice_number = invoice.invoice_id
                console.log("Invoice Date: ", invoice.invoice_date)
                invoice.invoice_date = invoice.invoice_date ? Utilities.getDateStringInputField(Utilities.parseDate(invoice.invoice_date)) : null;
                console.log("Invoice Date: ", invoice.invoice_date)
                invoice.currency = "USD";
                if(invoice.lines !== undefined && invoice.lines !== null){
                    invoice?.lines.forEach((item) => {
                        item.country_of_origin = "";
                        item.product_code = item.product_number || "",
                        item.quantity = this.parseFloatFromString(item.quantity);
                        item.unit_price = this.parseFloatFromString(item.unit_price);
                        item.extension_price = this.parseFloatFromString(item.amount).toFixed(2);
                        item.vatApplicable = true;
                        item.cpcCode = defaultCPCId;
                        item.npcCode = "000";
                        item.originalLine = structuredClone(item);
                        // item.vat_applicable = item.vat_applicable === 'Yes'
                    });
                }
                invoice.originalInvoice = structuredClone(invoice);
            });
        },
        renameInvoiceObjectKeys() {
            for (let i = 0; i < this.invoiceDataArray.length; i++) {
                let { invoice_items: lines, ...rest } = this.invoiceDataArray[i];
                this.invoiceDataArray[i] = { lines, ...rest };
            }
        },
        getTotalNumberOfLines() {
            let total = 0;
            for (let invoice of this.invoiceDataArray) {
                total += invoice.lines ? invoice.lines.length : 0;
            }
            this.totalNumberOfLines = total;
        },
        calculateTotalCargoValue() {
            let total = 0;
            for (let invoice of this.invoiceDataArray) {
                total += this.parseFloatFromString(invoice.invoice_total);
            }
            this.customsEntryForm.totalCargoValue = total;
        },
        validateIntegerInput(event) {
            // Get the entered text
            let newText = event.target.innerText.trim();

            // Check if the entered text is a valid integer
            if (/^\d+$/.test(newText)) {
                // Update the content if it's a valid integer
                this.editableContent = newText;
            } else {
                // Revert to the previous content if it's not a valid integer
                event.target.innerText = this.editableContent;
            }
            this.moveCursorToEnd(event.target);
        },
        updateEntryFields(fieldName, value) {
            this[fieldName] = value;
        },
        selectOption(option) {
            this.$refs.editableDiv.innerText = option;
            this.showModeofTransportDropdown = false;
        },
        verifyData(data) {
            if (data === undefined || data === null || data === "") {
                return false;
            }
            return true;
        },
        validateData() {
            console.log("Importer ID: ", this.verifyData(this.customsEntryForm.importerId));
            if (!this.verifyData(this.customsEntryForm.importerId)) {
                SweetAlertConnector.showErrorAlert("Please select an importer");
                return false;
            }
            if (!this.verifyData(this.customsEntryForm.exporterId)) {
                SweetAlertConnector.showErrorAlert("Please select an exporter");
                return false;
            }
            return true;
        },
        async saveFiles() {
            console.log("Saving Files...");
            const formData = new FormData();
            formData.append("reference", this.referenceNumber);
            this.selectedFiles.forEach((file, index) => {
                const newFileName = `${this.referenceNumber}_commercial_invoice_${index + 1}.${file.name.split('.').pop()}`;
                const renamedFile = new File([file], newFileName, { type: file.type });
                formData.append("files", renamedFile);
                // formData.append("files", file);
            });
            let promises = [DatabaseConnector.uploadFiles(formData)];

            if(this.waybillFiles.length > 0){
                const waybillFormData = new FormData();
                waybillFormData.append("reference", this.referenceNumber);
                this.waybillFiles.forEach((file, index) => {
                    const newFileName = `${this.referenceNumber}_waybill_${index + 1}.${file.name.split('.').pop()}`;
                    const renamedFile = new File([file], newFileName, { type: file.type });
                    waybillFormData.append("files", renamedFile);
                    // waybillFormData.append("files", file);
                });
                promises.push(DatabaseConnector.uploadFiles(waybillFormData));
            }

            return Promise.all(promises)
                .then(([invoiceResponse, waybillResponse]) => {
                    const fileResposnse = {
                        invoiceFiles: invoiceResponse,
                        waybillFiles: waybillResponse && waybillResponse[0] && waybillResponse[0].fileUrl ? waybillResponse[0].fileUrl : "",
                    };
                    return fileResposnse;
                })
                .catch((error) => {
                    console.error("Failed to upload the files:", error);
                });
        },
        async createInvoices(invoiceDataArray) {
            if (!this.validateData()) {
                return;
            }

            const entryData = {
                referenceNumber: this.referenceNumber,
                waybillNumber: this.customsEntryForm.waybillNumber,
                waybillDate: this.customsEntryForm.waybillDate,
                estimatedArrivalDate: this.customsEntryForm.estimatedArrivalDate,
                modeOfTransport: this.customsEntryForm.modeOfTransport,
                freightType: this.customsEntryForm.freightType,
                marksAndNumbers: this.customsEntryForm.marksAndNumbers,
                countryOfOrigin: this.customsEntryForm.countryOfOrigin,
                countryOfFinalDestination: this.customsEntryForm.countryOfFinalDestination,
                countryLastProvenance: this.customsEntryForm.countryLastProvinance,
                tradingCountry: this.customsEntryForm.tradingCountry,
                kindOfPackage: this.customsEntryForm.packageType,
                numberOfPackages: this.customsEntryForm.numberOfPackages,
                totalCargoValue: this.customsEntryForm.totalCargoValue,
                freightCharge: this.customsEntryForm.freightAmount ?? 0,
                insuranceAmount: this.customsEntryForm.insuranceAmount ?? 0,
                otherCharges: this.customsEntryForm.otherCharges ?? 0,
                freightCurrency: this.customsEntryForm.freightCurrency,
                insuranceCurrency: this.customsEntryForm.insuranceCurrency,
                otherChargesCurrency: this.customsEntryForm.otherChargesCurrency,
                rateOfExchange: this.customsEntryForm.rateOfExchange,
                netWeight: this.customsEntryForm.netWeight,
                grossWeight: this.customsEntryForm.grossWeight,
                invoiceList: invoiceDataArray,

                declarant: this.customsEntryForm.declarantId,
                incoTerms: this.customsEntryForm.incoTerms,
                importer: this.customsEntryForm.importerId,
                shipper: this.customsEntryForm.exporterId,
                vessel: this.customsEntryForm.vesselId,
                portOfDischarge: this.customsEntryForm.portOfDischargeId,
                regimeType: this.customsEntryForm.regimeType,
                deposit: this.customsEntryForm.depositAmount,
                containerCharges: this.customsEntryForm.containerCharges,
                additionalCharges: this.customsEntryForm.additionalCharges,
                localFee: this.customsEntryForm.localFee,
                entryType: ENTRY_TYPES.STANDARD,
                classificationStatus: this.classificationStatus,
                classificationApproved: 0,
                classificationApprovedBy: "",
                entryStatus: ENTRY_STATUS.STORED_AND_NOT_CLASSIFIED
            };
            console.log("Entry Data 1: ", entryData);
            try {
                SweetAlertConnector.showLoadingAlert("Saving your Entry...");
                this.saveFiles()
                    .then((filesResponse) => {
                        console.log("Files saved successfully:", filesResponse);
                        entryData.files = filesResponse.invoiceFiles;
                        entryData.waybillFiles = filesResponse.waybillFiles;
                        DatabaseConnector.createCommercialInvoice(entryData)
                            .then((response) => {
                                console.log("Entry created successfully:", response);
                                const totalFileSize = this.selectedFiles.reduce((sum, file) => sum + file.size, 0);
                                this.saveInvoicePerformanceMetric(this.invoicingProcessingStart, this.invoicingProcessingEnd, this.totalNumberOfLines, this.invoiceDataArray.length, totalFileSize, response.entryId);
                                this.saveClassificationPerformanceMetric(this.classificationProcessingStart, this.classificationProcessingEnd, this.invoiceDataArray.length, this.totalNumberOfLines, response.entryId);
                                this.redirectToEntryDetails(response.entryId);
                                SweetAlertConnector.closeCurrentAlert();
                                SweetAlertConnector.showSuccessAlert("Entry created successfully");
                            })
                            .catch((error) => {
                                console.error("Failed to create the Entry:", error);
                                SweetAlertConnector.showErrorAlert("Failed to create the Entry. Please try again.");
                            });
                    })
                    .catch((error) => {
                        console.error("Failed to save the files:", error);
                        SweetAlertConnector.showErrorAlert("Failed to save the files. Please try again.");
                    });
            } catch (error) {
                console.error("Failed to create the invoice:", error);
                SweetAlertConnector.showErrorAlert("Failed to create the Entry. Please try again.");
            }
        },
        redirectToEntryDetails(entryId) {
            this.$router.push({ name: "EntryDetails", params: { entryId } });
        },
        moveCursorToEnd(element) {
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(element);
            range.collapse(false); // Collapse range to end
            selection.removeAllRanges();
            selection.addRange(range);
        },
        validateInput() {
            this.isValidReference = this.referenceNumber.trim().length > 0;
        },
        handleFileUpload(event) {
            if (!this.isValidReference) return;
            this.selectedFiles = Array.from(event.target.files);
            this.createFileUrl();
        },
        handleWaybillUpload(event) {
            if (!this.isValidReference) return;
            this.waybillFiles = Array.from(event.target.files);
        },
        createFileUrl() {
            for (let file of this.selectedFiles) {
                if (file.type === "application/pdf") {
                    const url = URL.createObjectURL(file);
                    this.pdfFiles.push({
                        name: file.name,
                        url: url,
                    });
                } else {
                    alert(`File "${file.name}" is not a valid PDF file.`);
                }
            }
        },
        async openWaybill(){
            if (this.waybillFiles.length === 0) {
                SweetAlertConnector.showErrorAlert('No waybills Uploaded');
                return;
            }

            try {
                // Assuming only one file is selected for this function
                const file = this.waybillFiles[0];
                
                // Convert the file into an ArrayBuffer
                const arrayBuffer = await file.arrayBuffer();
                
                // Convert the ArrayBuffer into a Blob
                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                
                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);
                
                // Open a new window and display the PDF in an iframe
                const newWindow = window.open('', '_blank', 'width=800,height=600');

                if (newWindow) {
                    newWindow.document.write(`<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`);
                    newWindow.document.title = "PDF Document";
                } else {
                    console.error('Failed to open new window.');
                }
            } catch (error) {
                console.error('Error:', error);
                SweetAlertConnector.showErrorAlert('Failed to open PDF!');
            }
        },
        async mergeAndOpenPDFs() {
            if (this.selectedFiles.length === 0) {
                SweetAlertConnector.showErrorAlert('Please upload at least one file.');
                return;
            }

            try {
                const pdfDocs = await Promise.all(
                    this.selectedFiles.map(async file => {
                        const arrayBuffer = await file.arrayBuffer();
                        return PDFDocument.load(arrayBuffer);
                    })
                );

                const mergedPdf = await PDFDocument.create();

                for (const pdfDoc of pdfDocs) {
                    const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                    copiedPages.forEach(page => mergedPdf.addPage(page));
                }

                const mergedPdfBytes = await mergedPdf.save();
                const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const newWindow = window.open('', '_blank', 'width=800,height=600');

                if (newWindow) {
                    newWindow.document.write(`<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`);
                    newWindow.document.title = "Merged PDF";
                } else {
                    console.error('Failed to open new window.');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        },
        dragOver(event) {
            if (!this.isValidReference) return;
            this.isDragOver = true;
        },
        dragEnter(event) {
            if (!this.isValidReference) return;
            this.isDragOver = true;
        },
        dragLeave(event) {
            if (!this.isValidReference) return;
            this.isDragOver = false;
        },
        async saveClassificationPerformanceMetric(processingStart, processingEnd, numberOfInvoices, numberOfLines, entryId) {
            const processingTime = (processingEnd - processingStart) / 1000; // Time in seconds
            const metrics = {
                entry_id: entryId,
                processing_start: processingStart.toISOString(),
                processing_end: processingEnd.toISOString(),
                processing_time: processingTime,
                number_of_invoices: numberOfInvoices,
                number_of_lines: numberOfLines,
                
            };
            console.log('Classification Performance Metrics: ', metrics);
            await DatabaseConnector.sendClassificationPerformanceMetrics(metrics);
            console.log('Classification Performance Metrics saved successfully.');
        },
        async saveInvoicePerformanceMetric(processingStart , processingEnd, numberOfLines, numberOfInvoices, fileSize, entryId){
            const processingTime = (processingEnd - processingStart) / 1000; // Time in seconds
            const metrics = {
                file: {
                    size: fileSize,
                },
                entryId: entryId,
                processing_start: processingStart.toISOString(),
                processing_end: processingEnd.toISOString(),
                processing_time: processingTime,
                number_of_lines: numberOfLines,
                number_of_invoices: numberOfInvoices,
                status: 'success',
                error_message: null,
                model_utlized: this.invoiceModelUtilized,
            };
            console.log('Invoice AI Performance Metrics: ', metrics);
            await DatabaseConnector.sendInvoiceAIPerformanceMetrics(metrics);
            console.log('Invoice AI Performance Metrics saved successfully.');
        },
        readWaybillModelClaude(){
            const formData = new FormData();
            this.waybillFiles.forEach((file) => {
                formData.append("waybill", file);
            });
            DatabaseConnector.readWaybillClaude(formData).then((response) => {
                this.waybillDataExtractionCompleted = true;
                this.dataExtractionLoadingMessage();
                const waybillData = response[0]
                console.log("Waybill Data", waybillData)
                this.customsEntryForm.waybillNumber = waybillData.waybill_number
                this.customsEntryForm.numberOfPackages = this.parseFloatFromString(waybillData.number_of_packages);
                this.customsEntryForm.modeOfTransport = waybillData.mode_of_transport

                // Find closest match for importer
                const selectedImporter = Utilities.findClosestMatch(waybillData.consignee_name, this.customsEntryForm.importers)
                console.log("Selected Importer", selectedImporter)
                this.customsEntryForm.importerId = selectedImporter.id

                // Find closest match for exporter
                const selectedExporter = Utilities.findClosestMatch(waybillData.shipper_name, this.customsEntryForm.exporters) 
                console.log("Selected Exporter", selectedExporter)
                this.customsEntryForm.exporterId = selectedExporter.id

                // Find closest match for vessel
                if(waybillData.vessel_name != undefined && waybillData.vessel_name !== null && waybillData.vessel_name != ""){
                    const selectedVessel = Utilities.findClosestMatch(waybillData.vessel_name, this.customsEntryForm.vessels)
                    console.log("Selected Vessel", selectedVessel)
                    this.customsEntryForm.vesselId = selectedVessel.id
                }
                // Find closest match for port of discharge
                const selectedPort = Utilities.findClosestMatch(waybillData.port_of_discharge, this.customsEntryForm.ports)
                console.log("Selected Port", selectedPort)
                this.customsEntryForm.portOfDischargeId = selectedPort.id

                const shippedOnBoardString = waybillData.shipment_date;
                try{
                    const parsedDate = Utilities.parseDate(shippedOnBoardString)
                    this.customsEntryForm.waybillDate = Utilities.formatDateString(parsedDate);
                }
                catch(error){
                    console.error("Failed to parse date", error)
                }

                if(waybillData.total_amount !== undefined && waybillData.total_amount !== null && waybillData.total_amount.trim().length > 0){
                    const freightCharge = Utilities.parseFreightCharges(waybillData.total_amount);
                    this.customsEntryForm.freightAmount = freightCharge;
                }

                if(waybillData.gross_weight !== undefined && waybillData.gross_weight !== null){
                    this.customsEntryForm.grossWeight = this.parseFloatFromString(waybillData.gross_weight)
                }

                if(waybillData.package_type !== undefined && waybillData.package_type !== null){
                    const selectedPackageType = Utilities.findClosestMatch(waybillData.package_type, this.customsEntryForm.packageTypes, 'text')
                    console.log("Selected Package Type", selectedPackageType)
                    this.customsEntryForm.packageType = selectedPackageType.value
                }

                if(waybillData.country_of_origin !== undefined && waybillData.country_of_origin !== null){
                    
                    this.customsEntryForm.countryOfOrigin = waybillData.country_of_origin
                }
                if(waybillData.freight_type !== undefined && waybillData.freight_type !== null){
                    const allFreightTypes = this.customsEntryForm.modeOfTransportOOptions.flatMap(transport => 
                        transport.freightTypes.map(type => ({ name: type.name }))
                    );
                    console.log("All Freight Types", allFreightTypes)
                    console.log("Waybill Freight Type", waybillData.freight_type)
                    const selectedFreightType = Utilities.findClosestMatch(waybillData.freight_type, allFreightTypes)
                    console.log("Selected Freight Type", selectedFreightType)
                    this.customsEntryForm.freightType = selectedFreightType.name
                }
            }).catch((error) => {
                console.error("Failed to read waybill model", error)
                SweetAlertConnector.showErrorAlert("Failed to read waybill model. Please try again.")
            })
        },
        readWaybillModel(){
            const formData = new FormData();
            this.waybillFiles.forEach((file) => {
                formData.append("files", file);
            });
            DatabaseConnector.readWaybillModel(formData).then((response) => {
                this.waybillDataExtractionCompleted = true;
                this.dataExtractionLoadingMessage();
                const waybillData = response[0]
                console.log("Waybill Data", waybillData)
                this.customsEntryForm.waybillNumber = waybillData.waybill_number
                this.customsEntryForm.numberOfPackages = this.parseFloatFromString(waybillData.number_of_packages);
                this.customsEntryForm.modeOfTransport = waybillData.mode_of_transport
                const selectedImporter = Utilities.findClosestMatch(waybillData.consignee, this.customsEntryForm.importers)
                console.log("Selected Importer", selectedImporter)
                this.customsEntryForm.importerId = selectedImporter.id

                const selectedExporter = Utilities.findClosestMatch(waybillData.exporter, this.customsEntryForm.exporters)
                console.log("Selected Exporter", selectedExporter)
                this.customsEntryForm.exporterId = selectedExporter.id

                const selectedVessel = Utilities.findClosestMatch(waybillData.vessel, this.customsEntryForm.vessels)
                console.log("Selected Vessel", selectedVessel)
                this.customsEntryForm.vesselId = selectedVessel.id

                const selectedPort = Utilities.findClosestMatch(waybillData.port_of_discharge, this.customsEntryForm.ports)
                console.log("Selected Port", selectedPort)
                this.customsEntryForm.portOfDischargeId = selectedPort.id

                const shippedOnBoardString = waybillData.shipped_on_board_date;
                try{
                    const parsedDate = Utilities.parseDate(shippedOnBoardString)
                    this.customsEntryForm.waybillDate = Utilities.formatDateString(parsedDate);
                }
                catch(error){
                    console.error("Failed to parse date", error)
                }

                if(waybillData.freight_charges !== undefined && waybillData.freight_charges !== null && waybillData.freight_charges.trim().length > 0){
                    const freightCharge = Utilities.parseFreightCharges(waybillData.freight_charges);
                    this.customsEntryForm.freightAmount = freightCharge;
                }

                if(waybillData.gross_weight_in_kgs !== undefined && waybillData.gross_weight_in_kgs !== null){
                    this.customsEntryForm.grossWeight = this.parseFloatFromString(waybillData.gross_weight_in_kgs)
                }

                if(waybillData.package_type !== undefined && waybillData.package_type !== null){
                    const selectedPackageType = Utilities.findClosestMatch(waybillData.package_type, this.customsEntryForm.packageTypes, 'text')
                    console.log("Selected Package Type", selectedPackageType)
                    this.customsEntryForm.packageType = selectedPackageType.value
                }

            }).catch((error) => {
                console.error("Failed to read waybill model", error)
                SweetAlertConnector.showErrorAlert("Failed to read waybill model. Please try again.")
            })
        },

        async uploadInvoicesAndWaybills(url = "https://gabrielle-flask.azurewebsites.net/read_vendor_invoice_optimized", modelUtilized = INVOICE_AI_MODELS.MICROSOFT_FORM_RECOGNIZER){
            if (!this.isValidReference || this.selectedFiles.length === 0) {
                SweetAlertConnector.showErrorAlert('Please enter a valid reference number and select files before uploading.');
                return;
            }
            SweetAlertConnector.showLoadingAlert("Verifying Reference Number...");
            DatabaseConnector.verifyReferenceNumber(this.referenceNumber).then((response) => {
                if (response === false) {
                    SweetAlertConnector.closeCurrentAlert();
                    SweetAlertConnector.showErrorAlert(
                        `Reference Number ${this.referenceNumber} Already Exists. Please enter a new reference number.`
                    );
                    return;
                }
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showLoadingAlert("Reading your invoice and Waybill");
                this.invoiceModelUtilized = modelUtilized;
                this.uploadFilesMicrosoft(url);
                if(this.waybillFiles.length > 0){
                    // this.readWaybillModel();
                    this.readWaybillModelClaude();
                }
                else {
                    console.log("No Waybill Files")
                    this.waybillDataExtractionCompleted = true;
                }
            });
        },
        transformArray(data){
            // Check if the data is an array of arrays
            if (Array.isArray(data) && Array.isArray(data[0])) {
                // Flatten the array of arrays
                return data.flat();
            }
            // Return the data as is if it's already in the desired format
            return data;
        },
        async compressFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                const fileAsArray = new Uint8Array(e.target.result);
                const compressedFileArray = pako.deflate(fileAsArray);
                // const compressedFile = new Blob([compressedFileArray], { type: file.type });
                const compressedFile = compressedFileArray.buffer;
                const dataToUpload = new Blob([compressedFile], {type: file.type});
                const fileToUpload = new Blob([dataToUpload], {type: file.type});

                
                // Log the original and compressed file sizes
                console.log('Original file size:', file.size, 'bytes');
                console.log('Compressed file size:', fileToUpload.size, 'bytes');

                // Calculate and log the percentage decrease in file size
                const percentageDecrease = ((file.size - fileToUpload.size) / file.size) * 100;
                console.log('Percentage decrease in file size:', percentageDecrease.toFixed(2), '%');

                // Download the compressed file
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(fileToUpload);
                downloadLink.download = `compressed_${file.name}`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

                resolve(fileToUpload);
                };
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            });
        },
        // Calling function in your uploadFilesMicrosoft method

        async uploadFilesMicrosoft(url) {
            console.log("Uploading Files", url);
            const formData = new FormData();
            formData.append("reference", this.referenceNumber);
            this.selectedFiles.forEach((file, index) => {
                // formData.append("files", file);
                const newFileName = `${this.referenceNumber}_commercial_invoice_${index + 1}.${file.name.split('.').pop()}`;
                const renamedFile = new File([file], newFileName, { type: file.type });
                formData.append("commercialInvoice", renamedFile);
            });
            console.log("Form Data: ", formData);
            // SweetAlertConnector.showLoadingAlert("Reading your invoice...");
            this.invoicingProcessingStart = new Date();

            fetch(url, {
                method: "POST",
                body: formData,
            }).then((response) => response.json())
                .then(async (data) => {
                    this.invoicingProcessingEnd = new Date();
                    this.invoicesDataExtractionCompleted = true;
                    if(Utilities.discountCheck(data)){
                        console.log("Discount Detected. Please review the invoice data.");
                        SweetAlertConnector.closeCurrentAlert();
                        const discountResult = await SweetAlertConnector.discountAlert();
                        console.log("Discount Result: ", discountResult);
                    }
                    this.dataExtractionLoadingMessage();
                    console.log("Success:", data);
                    
                    this.invoiceDataArray = this.transformArray(data);
                    this.invoiceDataArray = this.invoiceDataArray.filter(invoice => invoice.invoice_items && Array.isArray(invoice.invoice_items))
                    console.log("Filtered Invoice Data: ", this.invoiceDataArray);
                    this.formatInvoiceData();
                    this.originalInvoiceDataArray = structuredClone(this.invoiceDataArray);
                    console.log("Invoice Data: ", this.invoiceDataArray);
                    // SweetAlertConnector.closeCurrentAlert();
                    // SweetAlertConnector.showSuccessAlert("Invoice data read successfully.");
                    this.calculateTotalCargoValue();
                    this.getTotalNumberOfLines();
                    if (this.invoicesDataExtractionCompleted && this.waybillDataExtractionCompleted) {
                        SweetAlertConnector.asyncLoadingAlert("Classifying Invoice Items...");
                    }
                    this.classificationProcessingStart = new Date();
                    await this.classifyItemsWithHistory();
                    this.classifyInvoiceItems().then((commodityCodes) => {
                        this.classificationCompleted = true;
                        SweetAlertConnector.closeCurrentAlert();
                        this.classificationProcessingEnd = new Date();
                        console.log("Commodity Code: ", commodityCodes);
                        SweetAlertConnector.showSuccessAlert("Invoice data read and classified.");
                        this.calculateTotalCargoValue();
                        this.getTotalNumberOfLines();
                    });
                })
                .catch((error) => {
                    console.error("Error:", error);
                    this.invoiceDataArray = [];
                });
        },
        uploadFiles() {
            if (!this.isValidReference || this.selectedFiles.length === 0) {
                alert("Please enter a valid reference number and select files before uploading.");
                return;
            }
            DatabaseConnector.verifyReferenceNumber(this.referenceNumber).then((response) => {
                if (response === false) {
                    SweetAlertConnector.closeCurrentAlert();
                    SweetAlertConnector.showErrorAlert(
                        `Reference Number ${this.referenceNumber} Already Exists. Please enter a new reference number.`
                    );
                    return;
                }
                SweetAlertConnector.closeCurrentAlert();
                const formData = new FormData();
                formData.append("reference", this.referenceNumber);
                this.selectedFiles.forEach((file) => {
                    formData.append("commercialInvoice", file);
                });
                SweetAlertConnector.showLoadingAlert("Reading your invoice...");

                fetch("https://mawi-updraded-api.wonderfulflower-f4234c00.eastus.azurecontainerapps.io/analyze-document", {
                    method: "POST",
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log("Success:", data);
                        this.invoiceDataArray = this.convertAWSData(data);
                        this.formatInvoiceData();
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("Invoice data read successfully.");
                        this.calculateTotalCargoValue();
                        this.getTotalNumberOfLines();
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        this.invoiceDataArray = [];
                    });
            });
        },
        removeUnsafeCharacters(str) {
            // Define a regular expression for characters that are considered safe in URLs
            const safeChars = /^[a-zA-Z0-9-_.~ ]*$/;

            // Filter the string to keep only safe characters
            return Array.from(str)
                .filter((char) => safeChars.test(char))
                .join("");
        },
        async classifyInvoiceItems() {
            console.log("Classifying Invoice Items");
            console.log("Invoice Data Array: ", this.invoiceDataArray);

            const descriptions = this.invoiceDataArray.flatMap((invoice) => {
                // Map the invoice lines to the desired format.
                let formattedItems =  invoice.lines
                .filter((item) => !item.tariff_code)
                .map((item) => ({
                    description: item.classification_description || item.description || "",
                    commodity_code: item.commodity_code || "",
                    product_number: item.product_number || "",
                }));
                return formattedItems;

                // // // Function to chunk the array into smaller arrays of a given size.
                // const chunkSize = 20;
                // const chunks = [];
                // for (let i = 0; i < formattedItems.length; i += chunkSize) {
                //     chunks.push([formattedItems.slice(i, i + chunkSize)]);
                // }

                // return chunks;
            });
            if (descriptions.length === 0) {
                console.log("No descriptions to classify.");
                return [];
            }
            console.log("Descriptions: ", [descriptions]);
            // const commodityCodes = [];
            // for (const chunk of descriptions) {
            //     console.log("Chunk: ", chunk);
            //     try { 
            //         const result = await DatabaseConnector.getCommodityCode(chunk);
            //         commodityCodes.push(...result);
            //         this.processCommodityCodes(result[0]);
            //     } catch (error) {
            //         console.error("Failed to classify items:", error);
            //         continue;
            //     }
            // }   
            const commodityCodes = await DatabaseConnector.getCommodityCode([descriptions]);
            this.processCommodityCodes(commodityCodes[0]);
            return commodityCodes;
        },

        async classifyItemsWithHistory(){
            const descriptions = this.invoiceDataArray.flatMap((invoice) => {
                return invoice.lines.map((item) => ({
                    description: item.description || "",
                    supplier_name: invoice.vendor_name || "",
                    product_number: item.product_code || "",
                }));
            });
            console.log("History Descriptions: ", descriptions);

            return DatabaseConnector.classifyItemsHistory(descriptions).then((commodityCodes) => {
                console.log("Commodity Codes from history: ", commodityCodes);
                
                this.processCommodityCodes(commodityCodes);
            }).catch((error) => {
                console.error("Failed to classify items with history: ", error);
            });
        },

        processCommodityCodes(commodityCodes) {
            // console.log("Commodity Codes Processing: ", commodityCodes);
            commodityCodes.forEach((commodityCode) => {
                this.invoiceDataArray.forEach((invoice) => {
                    invoice.lines.forEach((item, itemIndex) => {
                        if (commodityCode.description === `${item?.classification_description}` || commodityCode.description === `${item.description}`) {
                            if ( commodityCode.classification_model_suggested_code !== undefined && commodityCode.classification_model_suggested_code !== null) {
                                item.tariff_code = this.insertFullStops(
                                    commodityCode.classification_model_suggested_code.toString()
                                );
                                const itemPerformanceMetric = {
                                    llm_recommended_classification_code: item.tariff_code,
                                    approved_classification_code: "",

                                };
                                item.performanceMetric = itemPerformanceMetric;
                                this.updateTariffCode(invoice, itemIndex, "tariff_code", item.tariff_code);
                            }
                        }
                    });
                });
            });
        },
        updateItem(data, index, field, value) {
            data.lines[index][field] = value;
        },
        parseFloatFromString(inputString) {
            if (!inputString || inputString === undefined || inputString === null) {
                return 0;
            }

            if (typeof inputString === "number") {
                return inputString;
            }
            // Remove non-numeric characters except for period (.)
            let numericString = inputString.replace(/[^0-9.]/g, "");

            // Parse the numeric string as a float
            let floatValue = parseFloat(numericString);

            return floatValue;
        },
    },
    beforeUnmount() {
        // Clean up all object URLs when the component is destroyed
        this.pdfFiles.forEach((pdf) => URL.revokeObjectURL(pdf.url));
    },
};

function verifyInvoiceData() {
    this.displayDocumentVerificationView = !this.displayDocumentVerificationView;
}
</script>

<style>
/* Add your styles here */
@import "vue3-select/dist/vue3-select.css";
.badge-container {
    margin: 10px;
}
button {
    margin: 5px;
    cursor: pointer;
}

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    text-align: center;
}
.upload-button {
    margin-left: 5px;
}

.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 5px;
    background: #fafafa;
    color: #bdbdbd;
    margin: 10px;
}

.dropzone.dragover {
    border-color: #3f51b5;
    background-color: #eeeeee;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}
.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
}

.dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropdown ul li {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown ul li:hover {
    background-color: #f0f0f0;
}

.form-row {
    text-align: left;
    padding: 20px;
}
.page-header-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 0.5rem;
}
</style>
