<template>
  <div class="dashboard-container">
    <div class="date-picker-container">
      <div class="col-md-4"></div>
      <div class="col-md-4 form-group">
        <label for="report-date">Select Report Date:</label>
        <input
          type="date"
          id="report-date"
          v-model="selectedDate"
          @change="reloadCharts"
          class="form-control"
        />
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="grid-item">
      <InvoiceAIPerformance :reportDate="selectedDate" />
    </div>
    <div class="grid-item">
      <ClassificationScoreChart :reportDate="selectedDate" />
    </div>
    <div class="grid-item">
      <InvoiceBucketsPerformance :reportDate="selectedDate" />
    </div>
    <div class="grid-item">
      <ClassificationDailyPerformance :reportDate="selectedDate" />
    </div>
    <div class="grid-item">
      <ClassificationCodeMatchChart :reportDate="selectedDate" />
    </div>
    <div class="grid-item">
      <InvoiceLineBucketsPerformance :reportDate="selectedDate" />
    </div>
    <div class="grid-item">
      <InvoiceLineCorrection :reportDate="selectedDate" />
    </div>
    
  </div>
</template>

<script>
import InvoiceAIPerformance from '@/components/PerformanceAnalysis/InvoiceAIPerformance.vue';
import ClassificationScoreChart from '@/components/PerformanceAnalysis/ClassificationScoreChart.vue';
import ClassificationCodeMatchChart from '@/components/PerformanceAnalysis/ClassificationCodeMatchChart.vue';
import InvoiceBucketsPerformance from '@/components/PerformanceAnalysis/InvoiceBucketsPerformance.vue';
import ClassificationDailyPerformance from '@/components/PerformanceAnalysis/ClassificationDailyPerformance.vue';
import InvoiceLineBucketsPerformance from '@/components/PerformanceAnalysis/InvoiceLineBucketsPerformance.vue';
import InvoiceLineCorrection from '@/components/PerformanceAnalysis/InvoiceLineCorrection.vue';

export default {
  name: 'PerformanceDashboard',
  components: {
    InvoiceAIPerformance,
    ClassificationScoreChart,
    ClassificationCodeMatchChart,
    InvoiceLineBucketsPerformance,
    InvoiceBucketsPerformance,
    ClassificationDailyPerformance,
    InvoiceLineCorrection
  },
  data() {
    return {
      selectedDate: new Date().toISOString().substr(0, 10), // Default to today's date
    };
  },
  methods: {
    reloadCharts() {
      // This method will be triggered when the date changes.
      // It can be used to trigger any additional logic if needed.
    }
  }
};
</script>

<style>
.dashboard-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  grid-template-rows: auto 1fr 1fr; /* Adjusted for the date picker */
  gap: 20px; /* Space between the grid items */
  padding: 20px;
}

.date-picker-container {
  grid-column: span 2; /* Make the date picker span across both columns */
  text-align: left;
  margin-bottom: 20px;
}

.grid-item {
  background-color: #f8f9fa; /* Light gray background for the grid items */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for the grid items */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
