import Swal from 'sweetalert2'
import * as Utilities from '@/composables/Utilities';

export function showSuccessAlert(title) {
    Swal.fire({
        position: 'top-end',
        toast: true, 
        icon: 'success',
        title: title,
        showConfirmButton: false,
        timer: 2000, 
        timerProgressBar: true, 
    });
}

export function asyncLoadingAlert(text){
    Swal.fire({
        html: text,
        allowOutsideClick: true, // Allow users to interact with the page
        backdrop: false, // No backdrop
        showConfirmButton: false,
        width: '300px',
        didOpen: () => {
          Swal.showLoading();
        },
        position: 'top-end',
        customClass: {
          container: 'swal2-top-end'
        }
    });
}

export function closeCurrentAlert() {
    Swal.close();
}

export function updateCurrentAlert(text) {
    Swal.update({
        html: text
    });

}

export function showErrorAlert(text) {
    Swal.fire({
        icon: "error",
        title: "Oops...",
        text: text,
    });
}

export function showLoadingAlert(title) {
    Swal.fire({
        title: 'One moment...',
        html: title,
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        }
    });
}

export async function displayConfirmationDialog(){
    return await Swal.fire({
        title: 'I noticed you deleted something!',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        return result;
    });
}

export async function form(declarationList, signatories) {
    // Create options HTML for the first select field
    const options1 = Object.entries(declarationList).map(
        ([value, text]) => `<option value="${value}">${text}</option>`
    ).join('');

    // Create options HTML for the second select field
    const options2 = signatories.map(
        (signatory) => `<option value="${signatory.id}">${signatory.name}</option>`
    ).join('');

    return await Swal.fire({
        title: "Special Exemption Details",
        html: `
            <select id="swal-input1" class="swal2-input">
                <option value="" selected disabled>Please select declaration number</option>
                ${options1}
            </select>
            <div style="margin-top: 15px;"></div>
            <select id="swal-input2" class="swal2-input">
                <option value="" selected disabled>Please select signatory</option>
                ${options2}
            </select>
            <div style="margin-top: 15px;"></div>
            <div id="swal-radio-group" class="swal2-input">
                <label>Generate Pre-Signed:</label>
                <div>
                    <input type="radio" id="generate-yes" name="generate-pre-signed" value="yes">
                    <label for="generate-yes">Yes</label>
                    <input type="radio" id="generate-no" name="generate-pre-signed" value="no" checked>
                    <label for="generate-no">No</label>
                </div>
            </div>
        `,
        focusConfirm: false,
        showCancelButton: true,
        preConfirm: () => {
            const select1 = Swal.getPopup().querySelector('#swal-input1').value;
            const select2 = Swal.getPopup().querySelector('#swal-input2').value;
            const generatePreSigned = Swal.getPopup().querySelector('input[name="generate-pre-signed"]:checked').value;

            if (!select1 || !select2) {
                Swal.showValidationMessage(`Please select both declaration numbers`);
            }
            return { 
                select1, 
                select2, 
                generatePreSigned: generatePreSigned === 'yes' 
            };
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return result.value;
        }
    });
}

export async function overtimeAlert() {
    return await Swal.fire({
        title: "Cargo Details",
        html: `
        <div style="display: flex; flex-direction: column; align-items: center;">
            <label for="cargo-details" style="margin-bottom: 5px;">Please enter the cargo details:</label>
            <input type="text" id="cargo-details" class="swal2-input" placeholder="Enter cargo details here..." style="margin-bottom: 15px;">
            <label for="start-time" style="margin-bottom: 5px;">Please enter the start time:</label>
            <input type="time" id="start-time" class="swal2-input" value="16:00">
        </div>
        `,
        focusConfirm: false,
        showCancelButton: true,
        preConfirm: () => {
            const cargoDetails = Swal.getPopup().querySelector('#cargo-details').value;
            const startTime = Swal.getPopup().querySelector('#start-time').value;

            if (!cargoDetails || !startTime) {
                Swal.showValidationMessage(`Please enter both cargo details and start time`);
            }

            const [hours, minutes] = startTime.split(':');
            const hour = parseInt(hours, 10);
            const period = hour >= 12 ? 'PM' : 'AM';
            const adjustedHour = hour % 12 || 12;
            const formattedTime = `${adjustedHour}:${minutes} ${period}`;

            return { cargoDetails, startTime: formattedTime };
        }
    }).then((result) => {
        if (result.isConfirmed) {
            console.log('Cargo Details:', result.value.cargoDetails);
            console.log('Start Time:', result.value.startTime);
            return result.value;
        }
    });
}

export async function caricomAlert() {
    return await Swal.fire({
        title: "Enter Cargo Details",
        input: 'text',
        inputLabel: 'Cargo Details',
        inputPlaceholder: 'Enter the cargo details here...',
        showCancelButton: true,
        inputValidator: (value) => {
            if (!value) {
                return 'Cargo details are required!';
            }
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return result.value;
        }
    });
}

export async function enterYourName(){
    return await Swal.fire({
        title: "Enter Your Name",
        input: 'text',
        inputLabel: 'Name',
        inputPlaceholder: 'Enter your name here...',
        showCancelButton: true,
        inputValidator: (value) => {
            if (!value) {
                return 'Name is required!';
            }
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return result.value;
        }
        return "CANCELED"
    });
}

export async function discountAlert() {
    return await Swal.fire({
        title: 'Discount Detected',
        text: "A discount has been detected on an invoice. Would you like to use the discounted price or the full price?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Use Discounted Price',
        cancelButtonText: 'Use Full Price'
    }).then((result) => {
        return result.isConfirmed; // Return true if the user chooses discounted price, false otherwise
    });
}

export async function worksheetFormatAlert() {
    return await Swal.fire({
        title: 'Choose Document Format',
        text: "Please select the format in which you want to generate the document.",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Summarized',
        cancelButtonText: 'Detailed'
    }).then((result) => {
        if (result.isConfirmed) {
            return 'summarized';
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            return 'detailed';
        }
    });
}

export async function zeroValueAlert(){
    return await Swal.fire({
        title: 'Zero Value',
        text: "At least one item has a value of zero. Do you want to proceed?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes Proceed',
        cancelButtonText: 'No'
    }).then((result) => {
        if (result.isConfirmed) {
            return 'yes';
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            return 'no';
        }
    });
}

export async function depositReasonAlert() {
    return await Swal.fire({
        title: "Select Reason for Deposit",
        input: 'select',
        inputOptions: {
            'PENDING CARICOM AREA INVOICE': 'PENDING CARICOM AREA INVOICE',
            'PENDING STATUS OF SIGNATORY ON CARICOM CARE INVOICE': 'PENDING STATUS OF SIGNATORY ON CARICOM INVOICE'
        },
        inputPlaceholder: 'Select a reason...',
        showCancelButton: true,
        inputValidator: (value) => {
            if (!value) {
                return 'You need to select a reason!';
            }
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return result.value;
        }
    });
}

export async function displayRateOfExchangeTable(exchangeRates){
    const tableHtml = `
      <table border="1" style="width:100%; text-align:left;">
        <thead>
          <tr>
            <th>Base Currency</th>
            <th>Rate</th>
            <th>Effective Date</th>
          </tr>
        </thead>
        <tbody>
          ${exchangeRates.map(rate => `
            <tr>
              <td>${rate.base_currency}</td>
              <td>${rate.rate}</td>
              <td>${Utilities.formatDate(rate.last_updated)}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
    `;

    Swal.fire({
      title: 'Exchange Rates',
      html: tableHtml,
      width: '800px', // Adjust the width as needed
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: 'Close'
    });
}

export async function displayEditableTaxTable(taxData, extensionPrice = 0) {
    const tableHtml = `
        <table border="1" style="width:100%; text-align:left;">
            <thead>
                <tr>
                    <th>Tax Name</th>
                    <th>Tax Percentage</th>
                    <th>Amount Payable</th>
                </tr>
            </thead>
            <tbody>
                ${Object.entries(taxData).map(([taxName, taxArray], index) => {
                    const taxDetails = taxArray[0];
                    const chargeAmount = parseFloat(taxDetails.charge_amount);
                    if (chargeAmount > 0) {
                        return `
                            <tr>
                                <td id="taxName-${index}" data-index="${index}">${taxName}</td>
                                <td><input type="number" id="chargeAmount-${index}" class="chargeAmount" data-index="${index}" value="${chargeAmount}" /></td>
                                <td><input type="number" id="amountpayable-${index}" class="amountPayable" data-index="${index}" value="${(chargeAmount / 100 * extensionPrice).toFixed(2)}" /></td>
                            </tr>
                        `;
                    }
                    return '';
                }).join('')}
            </tbody>
        </table>
    `;

    const result = await Swal.fire({
        title: 'Tax Information',
        html: tableHtml,
        width: '800px',
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: 'OK',
        didOpen: () => {
            document.querySelectorAll('.chargeAmount').forEach(input => {
                input.addEventListener('input', function() {
                    const index = this.dataset.index;
                    const percentage = parseFloat(this.value) || 0;
                    const amountPayable = (percentage / 100 * extensionPrice).toFixed(2);
                    document.getElementById(`amountpayable-${index}`).value = amountPayable;
                });
            });
            document.querySelectorAll('.amountPayable').forEach(input => {
                input.addEventListener('input', function() {
                    const index = this.dataset.index;
                    const amountPayable = parseFloat(this.value) || 0;
                    const chargeAmount = (amountPayable / extensionPrice * 100).toFixed(2);
                    document.getElementById(`chargeAmount-${index}`).value = chargeAmount;
                });
            });
        },
        preConfirm: () => {
            // Collect all the data from the inputs
            const taxes = {};
            document.querySelectorAll('.chargeAmount').forEach(input => {
                const index = input.dataset.index;
                // const taxName = document.querySelector(`tbody tr:nth-child(${index + 1}) td:first-child`).innerHTML;
                const taxName = document.getElementById(`taxName-${index}`).innerHTML;
                const chargeAmount = parseFloat(document.getElementById(`chargeAmount-${index}`).value);
                const amountPayable = parseFloat(document.getElementById(`amountpayable-${index}`).value);
                taxes[taxName] = {
                    chargeAmount: chargeAmount,
                    amountPayable: amountPayable
                };
            });
            return taxes;
        }
    });
    if (result.isConfirmed && result.value) {
        // console.log(result.value); // This will log the object with all the taxes and their updated values
        return result.value; // Returns the object
    }
    return {};

}


export async function scoonerBLAlert() {
    return await Swal.fire({
        title: "Scooner BL Details",
        html: `
            <select id="freightPayableAt" class="swal2-input">
                <option value="">Freight Payable At</option>
                <option value="Freight Collect">Freight Collect</option>
                <option value="Freight Pre-Paid">Freight Pre-Paid</option>
            </select>
            <input type="text" id="portOfDischarge" class="swal2-input" placeholder="Port of Discharge">
        `,
        showCancelButton: true,
        preConfirm: () => {
            const freightPayableAt = Swal.getPopup().querySelector('#freightPayableAt').value;
            const portOfDischarge = Swal.getPopup().querySelector('#portOfDischarge').value;
            if (!freightPayableAt || !portOfDischarge) {
                Swal.showValidationMessage('Both fields are required!');
                return null;
            }
            return { freightPayableAt, portOfDischarge };
        }
    }).then((result) => {
        if (result.isConfirmed) {
            return result.value;
        }
    });
}


// export async function displayEditableTaxTable(taxData, extensionPrice = 0) {
//     const tableHtml = `
//         <table border="1" style="width:100%; text-align:left;">
//             <thead>
//                 <tr>
//                     <th>Tax Name</th>
//                     <th>Charge Amount</th>
//                     <th>Amount</th>
//                     <th>Percentage Exempted</th>
//                     <th>Amount Exempted</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 ${Object.entries(taxData).map(([taxName, taxArray], index) => {
//                     const taxDetails = taxArray[0];
//                     const chargeAmount = parseFloat(taxDetails.charge_amount);
//                     const taxAmount = (chargeAmount / 100) * extensionPrice;
//                     if (chargeAmount > 0) {
//                         return `
//                             <tr>
//                                 <td>${taxName}</td>
//                                 <td>${chargeAmount}%</td>
//                                 <td class="taxAmount">${taxAmount.toFixed(2)}</td>
//                                 <td><input type="number" id="percentageExempted-${index}" class="percentageExempted" data-index="${index}" value="0" /></td>
//                                 <td><input type="number" id="amountExempted-${index}" class="amountExempted" data-index="${index}" value="0" readonly /></td>
//                             </tr>
//                         `;
//                     }
//                     return '';
//                 }).join('')}
//             </tbody>
//         </table>
//     `;

//     await Swal.fire({
//         title: 'Tax Information',
//         html: tableHtml,
//         width: '800px', // Adjust the width as needed
//         showCloseButton: true,
//         focusConfirm: false,
//         confirmButtonText: 'OK',
//         didOpen: () => {
//             document.querySelectorAll('.percentageExempted').forEach(input => {
//                 input.addEventListener('input', function() {
//                     const index = this.dataset.index ;
//                     const percentage = parseFloat(this.value) || 0;
//                     // console.log(index)
//                     // console.log(document.querySelectorAll('.taxAmount')[index - 1]);
//                     const taxAmount = parseFloat(document.querySelectorAll('.taxAmount')[index - 1].innerText) || 0;
//                     document.getElementById('amountExempted-' + index).value = (percentage / 100 * taxAmount).toFixed(2);
//                 });
//             });
//         }
//     });
// }
