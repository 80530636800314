<template>
    <div>
      <h1>Partially Stored Entries</h1>
      <!-- Search field -->
      <input type="text" v-model="searchTerm" placeholder="Search entries" />
  
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Entry #</th>
            <th>Consignee</th>
            <th>Invoice Number</th>
            <th>Waybill Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in filteredEntries" :key="entry.entry_id">
            <td>{{ entry.entry_number }}</td>
            <td>{{ entry.importerName }}</td>
            <td>{{ entry.invoice_number ? entry.invoice_number.split('; ')[0] : '' }}</td>
            <td>{{ entry.waybill_number }}</td>
            <td>
              <button type="button" class="btn btn-success" @click="openEntry(entry.entry_id, entry.entry_type)">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="error" class="error-message">{{ error }}</div>
    </div>
  </template>

<script>
import axios from 'axios';
import { ENTRY_TYPES } from '../constants'
export default {
  data() {
    return {
      entries: [],
      error: null,
      searchTerm: '', // To hold the search term
    };
  },
  created() {
    this.fetchEntries();
  },
  methods: {
      openEntry(entryId, entryType) {
          if(entryType && entryType === ENTRY_TYPES.VEHICLES){
              this.$router.push({ name: 'VehicleEntryEditPage', params: { entryId } });
              return;
          }
          this.$router.push({ name: 'EntryDetails', params: { entryId } });
      },
      async fetchEntries() {
          try {
              const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}entries/PARTIAL_STORAGE`);
              this.entries = response.data;
          } catch (error) {
              this.error = error.message;
              console.error('There was an error!', error);
          }
      },
  },
  computed: {
    filteredEntries() {
      if (!this.searchTerm) {
        return this.entries;
      }
      const term = this.searchTerm.toLowerCase();
      return this.entries.filter(entry => {
        // Convert the properties to strings and lowercase for case-insensitive comparison
        return (
          (entry.entry_number && entry.entry_number.toString().toLowerCase().includes(term)) ||
          (entry.waybill_number && entry.waybill_number.toString().toLowerCase().includes(term)) ||
          // Assuming entry.invoiceNumbers is an array or a string that needs splitting. Check if it is not null or undefined before using it.
          (entry.invoice_number && entry.invoice_number.split('; ').some(invoice_number => invoice_number.toLowerCase().includes(term))) ||
          (entry.consignee && entry.consignee.toString().toLowerCase().includes(term))
        );
      });
    }
  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.error-message {
  color: red;
}
</style>