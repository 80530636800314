import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_BASE_URL; // Adjust this URL to match your server location

// const mawiApiUrl = 'https://mary-staging.whitestone-99c262a0.eastus.azurecontainerapps.io//'
const mawiApiUrl = 'https://mawi-updraded-api.wonderfulflower-f4234c00.eastus.azurecontainerapps.io/'

// Helper function for sending requests
const sendRequest = async (method, url, data = null) => {
    try {
        const response = await axios({ method, url, data });
        return response.data;
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};

// CRUD operations for Waybills
export const fetchWaybills = async () => {
    return await sendRequest('get', `${apiUrl}/waybills`);
};
export const createWaybill = async (waybill) => {
    return await sendRequest('post', `${apiUrl}/waybills`, waybill);
};
export const updateWaybill = async (id, waybill) => {
    return await sendRequest('put', `${apiUrl}/waybills/${id}`, waybill);
};
export const deleteWaybill = async (id) => {
    return await sendRequest('delete', `${apiUrl}/waybills/${id}`);
};

// CRUD operations for Customs Entries
export const fetchCustomsEntries = async () => {
    return await sendRequest('get', `${apiUrl}/customs_entries`);
};
export const createCustomsEntry = async (entry) => {
    return await sendRequest('post', `${apiUrl}/customs_entries`, entry);
};
export const updateCustomsEntry = async (id, entry) => {
    return await sendRequest('put', `${apiUrl}/customs_entries/${id}`, entry);
};
export const deleteCustomsEntry = async (id) => {
    return await sendRequest('delete', `${apiUrl}/customs_entries/${id}`);
};

// CRUD operations for THN Codes
export const fetchThnCodes = async () => {
    return await sendRequest('get', `${apiUrl}/thn_codes`);
};
export const createThnCode = async (code) => {
    return await sendRequest('post', `${apiUrl}/thn_codes`, code);
};
export const deleteThnCode = async (thnNumber) => {
    return await sendRequest('delete', `${apiUrl}/thn_codes/${thnNumber}`);
};

// CRUD operations for Products
export const fetchProducts = async () => {
    return await sendRequest('get', `${apiUrl}/products`);
};
export const createProduct = async (product) => {
    return await sendRequest('post', `${apiUrl}/products`, product);
};
export const updateProduct = async (code, product) => {
    return await sendRequest('put', `${apiUrl}/products/${code}`, product);
};
export const deleteProduct = async (code) => {
    return await sendRequest('delete', `${apiUrl}/products/${code}`);
};

// CRUD operations for Commercial Invoices
export const fetchCommercialInvoices = async () => {
    return await sendRequest('get', `${apiUrl}/commercial_invoices`);
};
export const createCommercialInvoice = async (invoice) => {
    return await sendRequest('post', `${apiUrl}commercial_invoices`, invoice);
};
export const updateCommercialInvoice = async (id, invoice) => {
    return await sendRequest('put', `${apiUrl}/commercial_invoices/${id}`, invoice);
};
export const deleteCommercialInvoice = async (id) => {
    return await sendRequest('delete', `${apiUrl}/commercial_invoices/${id}`);
};

export const createToyotaEntry = async (entry) => {
    return await sendRequest('post', `${apiUrl}commercial_invoices_vehicles`, entry);

}

// CRUD operations for Commercial Invoice Lines
export const fetchCommercialInvoiceLines = async () => {
    return await sendRequest('get', `${apiUrl}/commercial_invoice_lines`);
};
export const createCommercialInvoiceLine = async (line) => {
    return await sendRequest('post', `${apiUrl}/commercial_invoice_lines`, line);
};
export const updateCommercialInvoiceLine = async (id, line) => {
    return await sendRequest('put', `${apiUrl}/commercial_invoice_lines/${id}`, line);
};
export const deleteCommercialInvoiceLine = async (id) => {
    return await sendRequest('delete', `${apiUrl}/commercial_invoice_lines/${id}`);
};

export const getEntryDetails = async (entryId) => {
    try{
        const response = await axios({
            url: `${apiUrl}entry-details/${entryId}`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const getCommercialInvoiceDetails = async (entryId) => {
    try{
        const response = await axios({
            url: `${apiUrl}commercial-invoices-with-lines/${entryId}`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const getCommercialInvoiceDetailsWithVehicles = async (entryId) => {
    try{
        const response = await axios({
            url: `${apiUrl}commercial-invoices-with-lines-with-vehicles/${entryId}`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const generateCustomsDeclaration = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}generate-customs-declaration`, // Adjust as necessary for your server's URL
            method: 'POST',
            responseType: 'blob', // Important for handling the binary data
            data: requestData
        });
        return response.data;
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const generateBlankValuationForm = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}blank-valuation-form-trinidad`, // Adjust as necessary for your server's URL
            method: 'GET',
            responseType: 'blob', // Important for handling the binary data
        });
        return response.data;
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const generatePreSignedValuationForm = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}generate-pre-signed-valuation-form`, // Adjust as necessary for your server's URL
            method: 'POST',
            responseType: 'blob', // Important for handling the binary data
            data: requestData
        });
        return response.data;
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}
export const generatePreSignedValuationFormVersion2 = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}generate-pre-signed-valuation-form-v2`, // Adjust as necessary for your server's URL
            method: 'POST',
            responseType: 'blob', // Important for handling the binary data
            data: requestData
        });
        return response.data;
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const saveCustomsDeclaration = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}customs-declaration`,
            method: 'POST',
            data: requestData
        });
        return response.data;
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const fetchCustomsDeclarations = async (entryId) => {
    console.log(entryId);
    try{
        const response = await axios({
            url: `${apiUrl}customs-declaration/${entryId}`,
            method: 'GET'
        });
        return response
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
    // return await sendRequest('get', `${apiUrl}customs-declaration/${entryId}`);
}

export const fetchSellers = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}sellers`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const fetchBuyers = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}buyers`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const fetchDeclarants = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}declarants`,
            method: 'GET'
        });
        console.log(response);
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const fetchCustomsEntryDeclarants = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}customs-entry-declarants`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const fetchShippers = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}shippers`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const updateCustomsDeclaration = async (entryId, declaration) => {
    try{
        const response = await axios({
            url: `${apiUrl}customs-declaration/${entryId}`,
            method: 'PUT',
            data: declaration
        });
        return response
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
    // return await sendRequest('put', `${apiUrl}customs-declaration/${entryId}`, declaration);
}

export const fetchPackageTypes = async () => {
    return fetch("packageTypes.json").then(response => response.json()).then(data => {
      return data;
    });
}

export const fetchCountries = async () => {
    try {
      const response = await fetch('https://restcountries.com/v3/all');
      const data = await response.json();
      return data.map(country => ({
        name: country.name.common,
        code: country.cca2
      }));
    } catch (error) {
      console.error('Failed to fetch countries:', error);
    }
}

export const addSeller = async (seller) => {
    try{
        const response = await axios({
            url: `${apiUrl}sellers`,
            method: 'POST',
            data: seller
        });
        return response
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const addBuyer = async (buyer) => {
    try{
        const response = await axios({
            url: `${apiUrl}buyers`,
            method: 'POST',
            data: buyer
        });
        return response
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const updateBuyer = async (buyer) => {
    try {
        const response = await axios({
            url: `${apiUrl}buyers/${buyer.id}`,
            method: 'PUT',
            data: buyer
        });
        return response;
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
};

export const updateShipper = async (shipper) => {
    try {
        const response = await axios({
            url: `${apiUrl}shippers/${shipper.id}`,
            method: 'PUT',
            data: shipper
        });
        return response;
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
}

export const updateDeclarant = async (declarant) => {
    try {
        const response = await axios({
            url: `${apiUrl}declarants/${declarant.id}`,
            method: 'PUT',
            data: declarant
        });
        return response;
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
}

export const addShipper = async (shipper) => {
    try{
        const response = await axios({
            url: `${apiUrl}shippers`,
            method: 'POST',
            data: shipper
        });
        return response
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const addDeclarant = async (declarant) => {
    try{
        const response = await axios({
            url: `${apiUrl}declarants`,
            method: 'POST',
            data: declarant
        });
        return response
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const fetchCpcCodes = async (country) => {
    try{
        const response = await axios({
            url: `${apiUrl}cpc-codes/${country}`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const fetchNpcCodes = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}npc_codes`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const fetchNPCWithCPC = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}npc_codes_with_cpc`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const fetchPorts = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}ports/TT`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const fetchExchangeRates = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}rates-of-exchange/TTD`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const fetchRatesBasedOnShippedOnBoard = async (shippedOnBoardDate) => {
    try{
        const response = await axios({
            url: `${apiUrl}exchange-rates/TTD/${shippedOnBoardDate}`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const addExchangeRate = async (exchangeRate) => {
    try{
        const response = await axios({
            url: `${apiUrl}rates-of-exchange`,
            method: 'POST',
            data: exchangeRate
        });
        return response
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const fetchVessels = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}vessels`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const insertVessel = async (vessel) => {
    try{
        const response = await axios({
            url: `${apiUrl}vessels`,
            method: 'POST',
            data: vessel
        });
        return response
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const updateVessel = async (vessel) => {
    try{
        const response = await axios({
            url: `${apiUrl}vessels/${vessel.id}`,
            method: 'PUT',
            data: vessel
        });
        return response
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const getTaxDetails = async (requestData) => {
    try{
        const response = await axios({
            url: `${mawiApiUrl}generate-tax-and-duties`,
            method: 'POST',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const generateWorkSheet = async (requestData, format) => {

    let url = `${mawiApiUrl}generate-worksheet-json`
    if(format === 'summarized')
        url += '?merge_invoices=True'
    try{
        const response = await axios({
            url: url,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const generateToyotaXML = async (requestData) => {
    try{
        const response = await axios({
            url: `${mawiApiUrl}generate-asycuda-toyota-xml`,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const generateToyotaWorksheet = async (requestData) => {
    try{
        const response = await axios({
            url: `${mawiApiUrl}generate-toyota-worksheet`,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const generateAsycudaXML = async (requestData) => {
    try{
        const response = await axios({
            url: `${mawiApiUrl}generate-asycuda-xml-json`,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const getCommodityCharges = async (commodity) => {
    try{
        const response = await axios({
            url: `${mawiApiUrl}commodity-codes/${commodity}`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const generateDepositForm = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}deposit-form`,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const generateScoonerBL = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}generate-sconner-bl`,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const generateC84Form = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}tt-c84`,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const generateOvertimeForm = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}overtime-form`,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const getSpecialExemptionDeclarations = async (importerTin) => {
    try{
        const response = await axios({
            url: `${apiUrl}special-exemptions-declarations/${importerTin}`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const readVehicleFiles = async (requestData) => {
    
    try{
        console.log("Request Data", requestData)
        const response = await axios({
            url: `${mawiApiUrl}analyze-toyota-documents-LLM`,
            method: 'POST',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const getCommodityCode = async(itemDescriptions) => {
    try{
        const response = await axios({
            url: `${mawiApiUrl}classify-items`,
            method: 'POST',
            data: itemDescriptions
        });
        return response.data
    }
    catch(error){
        if(error.response && error.response.status === 404 || error.response.status === 500){
            return null
        }
        else{
            console.error('API error:', error);
            throw error;
        }
    }
}

export const generateCaricomInvoice = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}generate-caricom`,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const updateEntryData = async (entryId, entryData) => {
    try{
        const response = await axios({
            url: `${apiUrl}customs-entry/${entryId}`,
            method: 'PUT',
            data: entryData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const updateWaybillData = async (waybillId, waybillData) => {
    try{
        const response = await axios({
            url: `${apiUrl}waybill/${waybillId}`,
            method: 'PUT',
            data: waybillData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const uploadFiles = async (files) => {
    try{
        // const response = await axios({
        //     url: `${apiUrl}upload-invoices`,
        //     method: 'POST',
        //     data: files
        // });
        const response = await axios.post(`${apiUrl}upload-invoices`, files, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const deleteInvoiceLines = async (InvoiceIDList) => {
    console.log("DB Connector", InvoiceIDList)
    try{
        const response = await axios({
            url: `${apiUrl}invoice-lines/`,
            method: 'DELETE',
            data: InvoiceIDList
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const verifyReferenceNumber = async(referenceNumber) => {
    try{
        await axios({
            url: `${apiUrl}check-entry-number/${referenceNumber}`,
            method: 'GET'
        });
        return true
    }
    catch(error){
        if (error.response && error.response.status === 409) {
            return false; // If the response is 409, return false
        }
        console.error('API error:', error);
        throw error;
    }
}

export const fetchTradeAgreements = async () => {
    try{
        const response = await axios({
            url: `${apiUrl}trade_agreements`,
            method: 'GET'
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const sendInvoiceAIPerformanceMetrics = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}invoice-ai-performance`,
            method: 'POST',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const sendClassificationPerformanceMetrics = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}classification-performance`,
            method: 'POST',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }

}

export const insertInvoiceLines = async (invoiceLines) => {
    try{
        const response = await axios({
            url: `${apiUrl}invoice-lines`,
            method: 'POST',
            data: invoiceLines
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const readWaybillModel = async (requestData) => {
    try{
        const response = await axios({
            url: `https://gabrielle-flask.azurewebsites.net/read_waybill`,
            method: 'POST',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const readWaybillClaude = async (requestData) => {
    try{
        const response = await axios({
            url: `${mawiApiUrl}analyze-waybill-OCR-LLM`,
            method: 'POST',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const updateClassificationAccuracyPerformanceMetrics = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}update-classification-accuracy-performance-metrics`,
            method: 'PUT',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const classifyItemsHistory = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}get-commodity-supplier-description`,
            method: 'POST',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const updateInvoiceDataOptimized = async (requestData) => {
    try{
        // const response = await axios({
        //     url: `${apiUrl}update-multiple-invoices`,
        //     method: 'PUT',
        //     data: requestData
        // });
        const response = axios.put(`${apiUrl}update-multiple-invoices`, requestData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const updateInvoiceLineDataOptimized = async(requestData) => {
    console.log("checksss", requestData)
    try{
        // const response = await axios({
        //     url: `${apiUrl}update-multiple-invoice-lines`,
        //     method: 'PUT',
        //     data: requestData
        // });
        const response = axios.put(`${apiUrl}update-multiple-invoice-lines`, requestData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}
export const insertNewInvoice = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}commercial_invoices-with-performance-metrics`,
            method: 'POST',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const generateBreakdownSheet = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}generate-invoice-breakdown-excel`,
            method: 'POST',
            responseType: 'blob',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}

export const saveInvoiceCorrection = async (requestData) => {
    try{
        const response = await axios({
            url: `${apiUrl}invoice-correction`,
            method: 'POST',
            data: requestData
        });
        return response.data
    }
    catch(error){
        console.error('API error:', error);
        throw error;
    }
}
