<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">M.A.R.Y.</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                New Entry 
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <router-link class="dropdown-item" to="/">Standard Entry</router-link>
                <router-link class="dropdown-item" to="/create-vehicle-entry">Vehicle Entry</router-link>
              </ul>
              <!-- <router-link class="nav-link active" to="/">New Entry</router-link> -->
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/view-entries">Stored and Not Classified</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/approved-classification-entries">Classification Okay</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/partial-storage-entries">Partial Storage </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/full-storage-entries">Full Storage </router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Configuration
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <router-link class="dropdown-item" to="/buyers">Importers</router-link>
                <router-link class="dropdown-item" to="/sellers">Shippers</router-link>
                <router-link class="dropdown-item" to="/declarants">Declarants</router-link>
                <router-link class="dropdown-item" to="/vessels">Vessels</router-link>
                <router-link class="dropdown-item" to="/rate-of-exchange">Rates Of Exchnage</router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    
    <!-- This is where routed components will be rendered -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
nav {
  /* Style your navbar */
}
nav a {
  text-decoration: none;
  margin-right: 15px;
  /* Style your links */
}
body, html {
  margin: 0;
  padding: 0;
}
.navbar {
  margin-bottom: 0; /* Ensures no additional space below navbar */
}
</style>
