<template>
  <div class="buyers-page">
    <h1>Importers List</h1>
    <form class="my-form-container" @submit.prevent="addBuyer">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerName"><strong>Name</strong></label>
            <input type="text" class="form-control" id="buyerName" placeholder="Please Enter Buyer Name" v-model="buyerName" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerAddressLine1"><strong>Address Line 1</strong></label>
            <input type="text" class="form-control" id="buyerAddressLine1" placeholder="Please Enter Address Line 1" v-model="buyerAddressLine1" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerAddressLine2"><strong>Address Line 2</strong></label>
            <input type="text" class="form-control" id="buyerAddressLine2" placeholder="Please Enter Address Line 2" v-model="buyerAddressLine2" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerCity"><strong>City</strong></label>
            <input type="text" class="form-control" id="buyerCity" placeholder="Please Enter City" v-model="buyerCity" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="buyerCountry"><strong>Country</strong></label>
            <!-- <input type="text" class="form-control" id="buyerCountry" v-model="buyerCountry" /> -->
            <select class="form-select" v-model="buyerCountry" id="buyerCountry">
              <option disabled value="">Please select one</option>
              <option v-for="country in this.countries" :key="country.code" :value="country.code">
                {{ country.code }} - {{ country.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <label for="tinNumber"><strong>TIN Number</strong></label>
            <input type="text" class="form-control" id="tinNumber" placeholder="TIN Number" v-model="tinNumber" />
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <p><strong></strong></p>
            <button class="btn btn-primary" type="submit">Add Importer</button>
           </div>
        </div>
      </div> 
    </form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Address </th>
          <th scope="col">Country</th>
          <th scope="col">TIN Number</th>
          <th scope="col">Expand</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(buyer, index) in buyers" :key="index">
          <tr >
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ buyer.name }}</td>
            <td>{{ buyer.address }}</td>
            <td>{{ buyer.country }}</td>
            <td>{{ buyer.taxIdentificationNumber }}</td>
            <td>
              <button type="button" class="btn btn-primary" @click="toggleDetails(index)">
                  <span v-if="expandedIndex === index">
                      <i class="fas fa-chevron-up" style="color: white"></i>
                  </span>
                  <span v-else>
                      <i class="fas fa-chevron-down" style="color: white"></i>
                  </span>
              </button>
            </td>
          </tr>
          <tr v-if="expandedIndex === index">
            <td colspan="6">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="buyerName"><strong>Name</strong></label>
                    <input type="text" class="form-control" id="buyerName" placeholder="Please Enter Buyer Name" v-model="buyer.name" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="tinNumber"><strong>Tax Identification Number</strong></label>
                    <input type="text" class="form-control" id="tinNumber" placeholder="Please Enter TIN Number" v-model="buyer.taxIdentificationNumber" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="buyerAddress"><strong>Address</strong></label>
                    <textarea  type="text" class="form-control" id="buyerAddressLine1" placeholder="Please Enter Address" v-model="buyer.address" rows="4"></textarea>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="buyerCountry"><strong>Country</strong></label>
                    <v-select :options="countries"
                    v-model="buyer.country"
                    label="name"
                    :searchable="true"
                    :filterable="true"
                    :reduce="option => option.code"
                    ></v-select>
                  </div>
                </div>
              </div>  
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <button class="btn btn-primary edit-form-button" @click="updateBuyer(buyer)">Update Buyer</button>
                    <button class="btn btn-danger edit-form-button" @click="deleteBuyer(buyer)">Delete Buyer</button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
        
      </tbody>
    </table>
  </div>
</template>

<script>

import * as DatabaseConnector from '@/composables/DatabaseConnector';
import * as SweetAlertConnector from '@/composables/SweetAlertConnector';

export default {
  name: 'BuyersPage',
  data() {
    return {
      buyers : [],
      countries: [],
      buyerName: '',
      buyerAddressLine1: '',
      buyerAddressLine2: '',
      buyerCity: '',
      buyerCountry: '',
      tinNumber: '',
      expandedIndex: null,
    };
  },
  methods: {
    toggleDetails(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
    },
    async getBuyers() {
      this.buyers = await DatabaseConnector.fetchBuyers();
      console.log(this.buyers);
    },
    async updateBuyer(buyer){
      SweetAlertConnector.showLoadingAlert('Updating Buyer');
      DatabaseConnector.updateBuyer(buyer).then(() => {
        SweetAlertConnector.showSuccessAlert('Buyer Updated Successfully');
        this.getBuyers();
      }).catch((error) => {
        SweetAlertConnector.showErrorAlert('Failed to Update Buyer', error);
      });
    },
    async deleteBuyer(buyer){
      buyer.active = 0;
      SweetAlertConnector.showLoadingAlert('Deleting Buyer');
      DatabaseConnector.updateBuyer(buyer).then(() => {
        SweetAlertConnector.showSuccessAlert('Buyer Deleted Successfully');
        this.getBuyers();
      }).catch((error) => {
        SweetAlertConnector.showErrorAlert('Failed to Delete Buyer', error);
      });
    },
    async addBuyer() {
      const newBuyer = {
        name: this.buyerName,
        address: `${this.buyerAddressLine1}\n${this.buyerAddressLine2}\n${this.buyerCity}`,
        country: this.buyerCountry,
        taxIdentificationNumber: this.tinNumber,
      };
      SweetAlertConnector.showLoadingAlert('Adding Buyer');
      DatabaseConnector.addBuyer(newBuyer).then(() => {
        this.buyerName = '';
        this.buyerAddressLine1 = '';
        this.buyerAddressLine2 = '';
        this.buyerCity = '';
        this.buyerCountry = '';
        SweetAlertConnector.showSuccessAlert('Buyer Added Successfully');
        this.getBuyers();
      }).catch((error) => {
        SweetAlertConnector.showErrorAlert('Failed to Add Buyer', error);
      });
    },
    async getCountries() {
      this.countries = await DatabaseConnector.fetchCountries();
      console.log("Countries", this.contries);
    },
  },
  mounted() {
    this.getBuyers();
    this.getCountries();
    // Any code you want to run after the component is mounted
  }
}
</script>

<style scoped>
.buyers-page {
  padding: 20px;
  text-align: center;
}

.customs-declaration-section-two {
    /* Your styles here */
  }
  .form-group {
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check label {
    margin-right: 1rem;
  }
  .my-form-container {
    text-align: left;
  }
  .edit-form-button {
    margin-right: 10px;
  }
</style>
