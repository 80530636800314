<template lang="">
    <div v-for="(data, dataIndex) in invoiceDataArray" :key="dataIndex">
        <h2 v-if="!documentVerificationFormat">Invoice Data #{{ dataIndex + 1 }}</h2>
        <h3>Header Information</h3>
        <div class="form-row">
            <div class="row">
                <div class="col-md-3">
                    <label for="InvoiceNumber"><strong>Invoice Number</strong></label>
                    <input
                        type="text"
                        v-model="data.invoice_number"
                        class="form-control"
                        id="InvoiceNumber"
                        placeholder="Enter Invoice Number"
                    />
                </div>
                <div class="col-md-3">
                    <label for="SupplierName"><strong>Supplier Name</strong></label>
                    <input
                        type="text"
                        v-model="data.vendor_name"
                        class="form-control"
                        id="SupplierName"
                        placeholder="Enter Supplier Name"
                    />
                </div>
                <div
                    :class="{
                        'col-md-2': !documentVerificationFormat,
                        'col-md-3': documentVerificationFormat,
                    }"
                >
                    <label for="InvoiceDate"><strong>Invoice Date</strong></label>
                    <input type="date" v-model="data.invoice_date" class="form-control" id="InvoiceDate" />
                </div>

                <div
                    :class="{
                        'col-md-2': !documentVerificationFormat,
                        'col-md-3': documentVerificationFormat,
                    }"
                >
                    <label for="invoiceCurrency"><strong>Invoice Currency</strong></label>
                    <v-select
                        v-model="data.currency"
                        :options="currencies"
                        label="name"
                        id="invoiceCurrency"
                        :searchable="true"
                        :reduce="(currency) => currency.code"
                        :filterable="true"
                        placeholder="Select a Currency"
                    ></v-select>
                </div>
                <div class="col-md-1" v-if="!documentVerificationFormat">
                    <label for="rateOfExchange"><strong>Exchange Rate</strong></label>
                    <input
                        type="number"
                        class="form-control"
                        id="rateOfExchange"
                        v-model="data.rate_of_exchange"
                    />
                </div>
                <div class="col-md-1" v-if="!documentVerificationFormat">
                    <label for="discountApplied"><strong>Apply Discount</strong></label>
                    <div class="form-check form-switch ms-2 custom-switch d-flex align-items-center">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="data.discount_applied" @change="handleInvoiceDiscountApplication(data)">
                    </div>
                </div>
            </div>
        </div>

        <h3>Line Details</h3>
        <table>
            <thead>
                <tr>
                    <th>Line #</th>
                    <th>Product Code</th>
                    <th>Description</th>
                    <th>THN Code</th>
                    <th v-if="!documentVerificationFormat">Duty</th>
                    <th v-if="!documentVerificationFormat">VAT</th>
                    <th>Unit Quantity</th>
                    <th>Unit Price</th>
                    <th>Extension Price</th>
                    <th></th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="data.lines">
                    <template v-for="(item, itemIndex) in data.lines" :key="itemIndex">
                        <tr>
                            <td>{{ itemIndex + 1 }}</td>
                            <td
                                contenteditable="true"
                                @blur="updateItem(data, itemIndex, 'product_code', $event.target.innerText)"
                            >
                                {{ item.product_code || "N/A" }}
                            </td>
                            <td
                                contenteditable="true"
                                @blur="updateItem(data, itemIndex, 'description', $event.target.innerText)"
                            >
                                {{ item.description || "N/A" }}
                            </td>
                            <td
                                :class="[
                                    item.commodityCodeStatus === 'valid'
                                        ? 'valid-commodity-code'
                                        : 'invalid-commodity-code',
                                ]"
                                contenteditable="true"
                                @input="handleInput($event)"
                                @blur="updateTariffCode(data, itemIndex, 'tariff_code', $event.target.innerText)"
                                :title="item.commodity_description"
                                data-bs-toggle="tooltip"
                            >
                                {{ item.tariff_code || "N/A" }}
                            </td>
                            <td v-if="!documentVerificationFormat">{{ item.icdCharge }}%</td>
                            <td v-if="!documentVerificationFormat">{{ item.vatCharge }}%</td>
                            <td
                                contenteditable="true"
                                @blur="updateItem(data, itemIndex, 'quantity', $event.target.innerText)"
                                :style="naStyle(item.quantity)"
                            >
                                {{ item.quantity || "N/A" }}
                            </td>
                            <td
                                contenteditable="true"
                                @blur="updateItem(data, itemIndex, 'unit_price', $event.target.innerText)"
                                :style="naStyle(item.unit_price)"
                            >
                                {{ item.unit_price || "N/A" }}
                            </td>
                            <td
                                contenteditable="true"
                                @blur="updateItem(data, itemIndex, 'extension_price', $event.target.innerText)"
                                :style="extensionStyle(item)"
                            >
                                {{ item.extension_price || "N/A" }}
                            </td>
                            <td>
                                <button v-if="verifyExtensionPrice(item)" @click="fixExtensionPrice(item)" class="icon-button">
                                <i class="fa-solid fa-hammer green-icon"></i>
                                </button>
                            </td>
                            <td>
                                <button type="button" class="btn btn-primary" @click="toggleDetails(itemIndex)">
                                    <span v-if="expandedIndex === itemIndex">
                                        <i class="fas fa-chevron-up" style="color: white"></i>
                                    </span>
                                    <span v-else>
                                        <i class="fas fa-chevron-down" style="color: white"></i>
                                    </span>
                                </button>
                                <!-- <button class="btn btn-danger" @click="deleteRow(data, itemIndex)"><i class="fa fa-trash"></i></button> -->
                            </td>
                        </tr>
                        <tr v-if="expandedIndex === itemIndex">
                            <td colspan="10">
                                <div class="row">
                                    <div class="col-md-2">
                                        <label for="countryOfOrigin" class="form-label"
                                            ><strong>Country of Origin</strong></label
                                        >
                                        <v-select
                                            v-model="item.country_of_origin"
                                            :options="countries"
                                            label="name"
                                            id="countryOfOrigin"
                                            :searchable="true"
                                            :reduce="(country) => country.code"
                                            :filterable="true"
                                            placeholder="Select a Country of Origin"
                                        ></v-select>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="cpcCode" class="form-label"><strong>CPC Code</strong></label>
                                        <v-select
                                            v-model="item.cpcCode"
                                            :options="availableCPCCodes"
                                            label="cpcDisplay"
                                            id="cpcCode"
                                            :searchable="true"
                                            :reduce="(cpc) => cpc.cpcId"
                                            :filterable="true"
                                            placeholder="Select 1 a CPC Code"
                                            @change="onCPCCodeChange(item)"
                                        ></v-select>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="npcCode" class="form-label"><strong>NPC Code</strong></label>
                                        <v-select
                                            v-model="item.npcCode"
                                            :options="availableNPCCodes(item.cpcCode)"
                                            label="code_description"
                                            id="npcCode"
                                            :searchable="true"
                                            :reduce="(npc) => npc.code"
                                            :filterable="true"
                                            placeholder="Select a NPC Code"
                                        ></v-select>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="tradeAgreement" class="form-label"
                                            ><strong>Trade Agreement</strong></label
                                        >
                                        <v-select
                                            v-model="item.tradeAgreement"
                                            :options="tradeAgreements"
                                            label="name"
                                            id="tradeAgreement"
                                            :searchable="true"
                                            :reduce="(tradeAgreement) => tradeAgreement.id"
                                            :filterable="true"
                                            placeholder="Select a Trade Agreement"
                                        ></v-select>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="applicableTaxes" class="form-label"><strong>Manually Exempt Tax</strong></label>
                                        <div>
                                            <button class="btn btn-primary" @click="displayManualExemptionAlert(item)"> Manually Exempt Tax
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="discount" class="form-label"><strong>Apply Discount</strong></label>
                                        <div class="form-check form-switch ms-2 custom-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="item.discount_applied" @change="handleLineDiscountApplication()">
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="delete" class="form-label"><strong>Delete</strong></label>
                                        <div>
                                            <button class="btn btn-danger" @click="deleteRow(data, itemIndex)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="7">
                        <button @click="addNewLine(data)">Add New Line</button>
                    </td>
                </tr>
            </tfoot>
        </table>

        <h3>Footer Information</h3>
        <table>
            <thead>
                <tr>
                    <th>Invoice Total</th>
                    <th>Inland</th>
                    <th>Insurance</th>
                    <th>Other Charges</th>
                    <th>Final Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td
                        contenteditable="true"
                        @blur="updateData(data, 'invoice_total', $event.target.innerText)"
                        :style="naStyle(data.invoice_total)"
                    >
                        {{ data.invoice_total || 0.0 }}
                    </td>
                    <td
                        contenteditable="true"
                        @blur="updateData(data, 'inland', $event.target.innerText)"
                        :style="naStyle(data.inland)"
                    >
                        {{ data.inland || 0.0 }}
                    </td>
                    <td
                        contenteditable="true"
                        @blur="updateData(data, 'insurance', $event.target.innerText)"
                        :style="naStyle(data.insurance)"
                    >
                        {{ data.insurance || 0.0 }}
                    </td>
                    <td
                        contenteditable="true"
                        @blur="updateData(data, 'otherCharges', $event.target.innerText)"
                        :style="naStyle(data.otherCharges)"
                    >
                        {{ data.otherCharges || 0.0 }}
                    </td>
                    <td @blur="updateData(data, 'final_total', $event.target.innerText)">
                        {{ calculateInvoiceTotal(data) }}
                    </td>
                </tr>
            </tbody>
        </table>

        <h3>Calculated Check</h3>
        <table>
            <thead>
                <tr>
                    <th>Total Extension Prices</th>
                    <th>Number of Line Items</th>
                    <th>Matches Invoice Total?</th>
                </tr>
            </thead>
            <tbody>
                <tr :style="checkInvoiceTotal(data)">
                    <td>
                        {{ calculateTotalExtensionPrice(data.lines) }}
                    </td>
                    <td>{{ data.lines ? data.lines.length : 0 }}</td>
                    <td>
                        {{
                            calculateTotalExtensionPrice(data.lines) === parseFloat(data.invoice_total).toFixed(2)
                                ? "Yes"
                                : "No"
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <table>
        <tr>
            <td>
                <!-- <button type="button" class="btn btn-primary" @click="addNewInvoice">Add New Invoice</button> -->
                 <input type="file" multiple @change="handleFileUpload"  accept="application/pdf" style="display: none" ref="newInvoice"/>
                <button type="button" class="btn btn-primary"  @click="triggerFileInput('newInvoice')">Add New Invoice</button>
            </td>
            <td>
                <ul>
                    <li v-for="(file, index) in newInvoices" :key="index">
                        {{ file.name }}
                    </li>
                </ul>
            </td>
            <td>
                <div v-if="newInvoices.length > 0">
                    <button type="button" class="btn btn-primary" @click="uploadFilesLLM()">Upload Invoices</button>
                </div>
            </td>
        </tr>
    </table>
</template>
<script>
import * as DatabaseConnector from "@/composables/DatabaseConnector";
import * as Currency from "@/composables/Currency";
import * as SweetAlertConnector from "../composables/SweetAlertConnector";
import * as Utilities from "@/composables/Utilities";
export default {
    props: ["invoiceDataArray", "customsEntryForm", "documentVerificationFormat", "linesToBeDeleted", "linesToBeAdded", "invoicesToBeAdded"],
    data() {
        return {
            countries: [],
            expandedIndex: null,
            currencies: [],
            cpcCodeOptions: ["4000"],
            npcCodeOptions: ["000"],
            applicableTaxes : [],
            newInvoices : [],
        };
    },
    methods: {
        calculateTotalExtensionPrice,
        checkInvoiceTotal,
        calculateInvoiceTotal,
        naStyle,
        addNewLine,
        updateData,
        deleteRow,
        toggleDetails,
        extensionStyle,
        updateTariffCode,
        parseFloatFromString,
        fetchCountries,
        updateItem,
        fetchNpcCodes,
        fetchTradeAgreements,
        handleInput,
        formatValue,
        truncateString,
        moveCursorToEnd,
        verifyExtensionPrice,
        fixExtensionPrice,
        displayManualExemptionAlert,
        availableNPCCodes,
        onCPCCodeChange(item) {
            console.log("CPC Code Changed: ", item);
            item.npcCode = availableNPCCodes(item.cpcCode)[0].code || "";
        },
        addNewInvoice,
        triggerFileInput,
        handleFileUpload,
        uploadFilesLLM,
        classifyInvoiceItems,
        processCommodityCodes,
        handleLineDiscountApplication(item){
            console.log("Handling Line Discount Application", item);
        },
        handleInvoiceDiscountApplication(invoice){
            invoice.lines.forEach((item) => {
                item.discount_applied = invoice.discount_applied;
            });
        },
        initTooltips() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            tooltipTriggerList.forEach((tooltipTriggerEl) => {
                new window.bootstrap.Tooltip(tooltipTriggerEl);
            });
        },
    },
    mounted() {
        this.fetchCountries();
        this.currencies = Currency.getCurrencyList();
        this.fetchNpcCodes();
        this.fetchTradeAgreements();
        this.initTooltips();
        
    },
    computed: {
        availableCPCCodes,
    },
};

async function uploadFilesLLM(url = "https://mawi-updraded-api.wonderfulflower-f4234c00.eastus.azurecontainerapps.io/analyze-invoices-OCR-LLM"){
    SweetAlertConnector.showLoadingAlert("Reading your invoices. Please wait...");
    Utilities.readFilesLLM(url, this.newInvoices).then((response) => {
        SweetAlertConnector.closeCurrentAlert();
        const invoices = response.invoiceData;
        invoices.forEach((invoice) => {
            this.invoiceDataArray.push(invoice);
            this.invoicesToBeAdded.push(invoice);
            this.newInvoices = [];
        });
        SweetAlertConnector.showLoadingAlert("Invoices read successfully. Classifying Invoice Items. Please wait...");
        this.classifyInvoiceItems(invoices).then(() => {
            SweetAlertConnector.closeCurrentAlert();
            SweetAlertConnector.showSuccessAlert("Invoices classified successfully.");
            console.log("Invoices classified successfully.", this.invoiceDataArray);
            
        }).catch((error) => {
            SweetAlertConnector.closeCurrentAlert();
            SweetAlertConnector.showErrorAlert("Failed to classify invoices. Please try again.");
            console.error("Failed to classify invoices: ", error);
        });
        
    }).catch((error) => {
        SweetAlertConnector.closeCurrentAlert();
        SweetAlertConnector.showErrorAlert("Failed to read invoices. Please try again.");
        this.newInvoices = [];
        console.error("Failed to read invoices: ", error);
    });
    
}

async function classifyInvoiceItems(invoices){
    const descriptions = invoices.flatMap((invoice) => {
        // Map the invoice lines to the desired format.
        return invoice.lines
        .filter((item) => !item.tariff_code)
        .map((item) => ({
            description: item.classification_description || item.description || "",
            commodity_code: item.commodity_code || "",
            product_number: item.product_number || "",
        }));
    });
    if (descriptions.length === 0) {
        console.log("No descriptions to classify.");
        return [];
    }
    console.log("Descriptions: ", descriptions);
    const commodityCodes = await DatabaseConnector.getCommodityCode([descriptions]);
    this.processCommodityCodes(commodityCodes[0], invoices);
}

function processCommodityCodes(commodityCodes, invoices){
    commodityCodes.forEach((commodityCode) => {
        invoices.forEach((invoice) => {
            invoice.lines.forEach((item, itemIndex) => {
                if (commodityCode.description === `${item?.classification_description}` || commodityCode.description === `${item.description}`) {
                    if ( commodityCode.classification_model_suggested_code !== undefined && commodityCode.classification_model_suggested_code !== null) {
                        item.tariff_code = insertFullStops(
                            commodityCode.classification_model_suggested_code.toString()
                        );
                        const itemPerformanceMetric = {
                            llm_recommended_classification_code: item.tariff_code,
                            approved_classification_code: "",

                        };
                        item.performanceMetric = itemPerformanceMetric;
                        this.updateTariffCode(invoice, itemIndex, "tariff_code", item.tariff_code);
                    }
                }
            });
        });
    });
}

function calculateTotalExtensionPrice(items) {
    if(items === undefined || items === null || items.length === 0){
        return 0;
    }
    return items.reduce((acc, item) => acc + this.parseFloatFromString(item.extension_price || 0), 0).toFixed(2);
}

function triggerFileInput(refName) {
    this.$refs[refName].click();
}

function handleFileUpload(event){
    console.log("Handling File Upload");
    this.newInvoices = Array.from(event.target.files);
}

function addNewInvoice(){
    const newInvoice = {
    "lines": [
        {
            "amount": 519.48,
            "description": "LETS JAM SHINE&COND GEL EX HOLD 4.4",
            "classification_description": "LETS JAM SHINE&COND GEL EX HOLD 4.4, Supplier: SOFISHEEN-CARSON, Item composition: Hair gel",
            "quantity": 234,
            "unit_price": 2.77,
            "discounted_unit_price": 2.22,
            "commodity_code": "3305.90.00",
            "country_of_origin": "",
            "product_number": "O0291113",
            "extension_price": "648.18",
            "vatApplicable": true,
            "cpcCode": 30,
            "npcCode": "000"
        }
    ],
    "customer_address": "BRYDEN PI LIMITED, 5A CTC DRIVE, MACOYA, TUNAPUNA, TRINIDAD & TABOGO, 99999, TT",
    "customer_address_recipient": "BRYDEN PI LIMITED",
    "customer_name": "BRYDEN PI LIMITED",
    "due_date": "2024-04-15",
    "invoice_inco_terms": "FCA DESTINATION PER SHIP",
    "invoice_date": "2024-02-15",
    "invoice_id": "687013501",
    "invoice_exworks_amount": 4334.88,
    "invoice_total_amount": 4334.88,
    "shipping_address": "BRYDEN PI LIMITED-EXP TO TRINIDAD, 1 IBIS AVENUE, SAN JUAN, 99999, TT",
    "shipping_address_recipient": "BRYDEN PI LIMITED-EXP TO TRINIDAD",
    "vendor_address": "L'OREAL USA S/D, INC., Cranbury, NJ 08512",
    "vendor_address_recipient": "L'OREAL USA S/D, INC.",
    "vendor_name": "L'OREAL USA S/D, INC.",
    "file_name": "RLL151623 - INV 3 CLASSED.pdf",
    "invoice_total": "5705.10",
    "inland": 0,
    "insurance": 0,
    "otherCharges": 0,
    "final_total": 0,
    "total_tax": 0,
    "subtotal": 4334.88,
    "invoice_number": "687013501",
    "currency": "USD"
};
    this.invoiceDataArray.push(newInvoice);
    this.invoicesToBeAdded.push(newInvoice);
}


function fixExtensionPrice(item) {
      const expected = (parseFloat(item.unit_price) * parseFloat(item.quantity)).toFixed(2);
      const actual = parseFloat(item.extension_price).toFixed(2);
      if (expected !== actual) {
        item.extension_price = expected;
      }
}
function verifyExtensionPrice(item) {
      const expected = (parseFloat(item.unit_price) * parseFloat(item.quantity)).toFixed(2);
      const actual = parseFloat(item.extension_price).toFixed(2);
      return expected !== actual || [item.unit_price, item.quantity, item.extension_price].some(v => [null, undefined, '', false].includes(v));
}
function checkInvoiceTotal(data) {
    const totalExtensions = this.calculateTotalExtensionPrice(data.lines);
    const invoiceTotal = parseFloat(data.invoice_total || 0).toFixed(2);
    return totalExtensions !== invoiceTotal ? { backgroundColor: "red", color: "white" } : {};
}

function calculateInvoiceTotal(invoice) {
    const invoiceTotal = parseFloat(invoice.invoice_total) || 0;
    const inland = parseFloat(invoice.inland) || 0;
    const insurance = parseFloat(invoice.insurance) || 0;
    const otherCharges = parseFloat(invoice.otherCharges) || 0;

    const total = invoiceTotal + inland + insurance + otherCharges;

    return total.toFixed(2);
}
function naStyle(value) {
    if ([null, undefined, "", false].includes(value)) {
        // return { backgroundColor: 'yellow', color: 'black' };
        return { "font-weight": "bold", color: "#B8860B" };
    }
    return {};
}

function addNewLine(data) {
    console.log("Adding New Line");
    console.log("Data: ", data);
    const newItem = {
        product_code: "",
        description: "",
        tariff_code: "",
        quantity: "",
        unit_price: "",
        extension_price: "",
        invoice_id: data.invoice_id,
        country_of_origin: "",
        cpcCode: 30,
        npcCode: "000"
    };
    data.lines.push(newItem);
    if (this.linesToBeAdded !== undefined) {
        this.linesToBeAdded.push(newItem);
    }
}
function updateData(data, field, value) {
    data[field] = value;
}

function deleteRow(data, index) {
    console.log("Deleting Row: ", index);
    console.log("Data: ", data.lines);
    if (data.lines[index].invoice_line_id !== undefined)
        this.linesToBeDeleted.push(data.lines[index].invoice_line_id);
    data.lines.splice(index, 1);
}

function toggleDetails(index) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
}
function extensionStyle(item) {
    if(item.extension_price === undefined || item.extension_price === null || item.extension_price === "" || item.extension_price === "N/A" || item.extension_price === "0.00" || item.extension_price === 0){ 
        return { "font-weight": "bold", color: "red" };
    }
    const expected = (parseFloat(item.unit_price) * parseFloat(item.quantity)).toFixed(2);
    const actual = parseFloat(item.extension_price).toFixed(2);
    if ([item.unit_price, item.quantity, item.extension_price].some((v) => [null, undefined, "", false].includes(v))) {
        // return { backgroundColor: 'yellow', color: 'black' };
        return { "font-weight": "bold", color: "#B8860B" };
    } else if (expected !== actual) {
        // return { backgroundColor: 'red', color: 'white' };
        return { "font-weight": "bold", color: "red" };
    }
    return {};
}
function updateTariffCode(data, itemIndex, field, value) {
    console.log("Updating Tariff Code");
    // console.log("Item Index: ", itemIndex);
    this.updateItem(data, itemIndex, field, value);
    if (value === undefined || value === null || value.trim().length === 0 || value === "N/A") {
        data.icdCharge = 0;
        data.vatCharge = 0;
        return;
    }
    console.log("Fetching charges for: ", value);
    DatabaseConnector.getCommodityCharges(value.replace(/\./g, ""))
        .then((chargesResponse) => {
            // console.log("Charges Response: ", chargesResponse);
            let icdObject = chargesResponse.charges["import custom duty"];
            const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject[0].charge_amount);
            let vatObject = chargesResponse.charges["value added tax"];
            const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject[0].charge_amount);
            // console.log("ICD Charge: ", icdCharge);
            // console.log("VAT Charge: ", vatCharge);
            data.lines[itemIndex]["icdCharge"] = icdCharge;
            data.lines[itemIndex]["vatCharge"] = vatCharge;
            data.lines[itemIndex]["commodityCodeStatus"] = "valid";
            data.lines[itemIndex]["applicableTaxes"] = chargesResponse.charges;
            data.lines[itemIndex]["commodity_description"] = chargesResponse.description;
            // console.log(data.lines[itemIndex]);

            let formattedCharges = Utilities.formatCharges(chargesResponse.charges, true);
            data.lines[itemIndex]["commodity"] = {
                code: value.replace(/\./g, ""),
                id: chargesResponse.id,
                charges: formattedCharges,
            };
        })
        .catch((error) => {
            console.error("Failed to get commodity charges:", error);
            data.lines[itemIndex]["commodityCodeStatus"] = "invalid";
            data.lines[itemIndex]["icdCharge"] = 0;
            data.lines[itemIndex]["vatCharge"] = 0;
            data.lines[itemIndex]["commodity_description"] = "";
        });
}
function parseFloatFromString(inputString) {
    if (!inputString || inputString === undefined || inputString === null) {
        return 0;
    }

    if (typeof inputString === "number") {
        return inputString;
    }
    // Remove non-numeric characters except for period (.)
    let numericString = inputString.replace(/[^0-9.]/g, "");

    // Parse the numeric string as a float
    let floatValue = parseFloat(numericString);

    return floatValue;
}
async function fetchCountries() {
    try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        this.countries = data.map((country) => ({
            name: country.name.common,
            code: country.cca2,
        }));
    } catch (error) {
        console.error("Failed to fetch countries:", error);
    }
}

function updateItem(data, index, field, value) {
    data.lines[index][field] = value;
}

function availableCPCCodes() {
    if (this.customsEntryForm.regimeTypeCode !== undefined && this.customsEntryForm.regimeTypeCode !== null) {
        const regimeData = this.customsEntryForm.regimeTypeOptions.find(
            (regime) => regime.regimeTypeCode === this.customsEntryForm.regimeTypeCode
        );
        if (regimeData) {
            return regimeData.cpcCodes;
        } else return [];
    } else {
        return this.customsEntryForm.regimeTypeOptions.flatMap((regime) => regime.cpcCodes);
    }
}

function availableNPCCodes(cpcId){
    const filteredList = this.npcCodeOptions.filter(npc => npc.cpcId === cpcId);
    if(filteredList.length > 0){
        return filteredList;
    }
    const uniqueNPCCodes = this.npcCodeOptions.filter((npc, index, self) =>
        index === self.findIndex((t) => (
            t.code === npc.code
        ))
    );
    return uniqueNPCCodes;

}

function handleInput(event) {
    const value = event.target.innerText;
    // Remove any existing dots and non-numeric characters
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    // Format the value as XXXX.XX.XX
    const formattedValue = this.formatValue(sanitizedValue);
    // Update the text content of the cell
    event.target.innerText = formattedValue;
    this.moveCursorToEnd(event.target);
    // Update the item data after handling the input
}
function formatValue(value) {
    let formattedValue = "";
    // Insert dots after the first four characters and after the next two characters
    for (let i = 0; i < value.length; i++) {
        if (i === 4 || i === 6 || i === 8) {
            formattedValue += ".";
        }
        formattedValue += value[i];
    }
    return this.truncateString(formattedValue);
}

function insertFullStops(numberString){
    if (numberString.length !== 11) {
        console.log("The string must contain exactly 11 numbers.", numberString);
        return "";
    }

    return numberString.slice(0, 4) + "." + numberString.slice(4, 6) + "." + numberString.slice(6, 8) + "." + numberString.slice(8);
}

function truncateString(str) {
    if (str.length > 13) {
        return str.substring(0, 14);
    }
    return str;
}
function moveCursorToEnd(element) {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false); // Collapse range to end
    selection.removeAllRanges();
    selection.addRange(range);
}
async function fetchNpcCodes() {
    DatabaseConnector.fetchNPCWithCPC().then((data) => {
        this.npcCodeOptions = data;
        // console.log("NPC Codes: ", data);
        
    });
}

async function fetchTradeAgreements() {
    DatabaseConnector.fetchTradeAgreements().then((data) => {
        this.tradeAgreements = data;
        // console.log("Trade Agreements: ", data);
    });
}
function displayManualExemptionAlert(item){
    if(item.commodityCodeStatus === 'invalid' || item.applicableTaxes === undefined || item.applicableTaxes === null || item.applicableTaxes.length === 0){
        SweetAlertConnector.showErrorAlert("Invalid or No Commodity Code Provided. Please enter a valid commodity code before proceeding.");
        return;
    }
    console.log("Displaying Manual Exemption Alert", item);
    console.log("Applicable Taxes: ", item.applicableTaxes);
    // if(item.manualExemptions === undefined || item.manualExemptions === null || item.manualExemptions.length === 0){
    //     item.manualExemptions = convertArrayFormat(this.applicableTaxes);
    // }
    SweetAlertConnector.displayEditableTaxTable(item.applicableTaxes, item.extension_price).then((taxResults ) => {
        console.log("Tax Results: ", taxResults );
        if (Object.keys(taxResults).length === 0) {
            console.log("No data returned or the user canceled the modal.");
        } else {
            console.log("Received tax data:", taxResults);
            item.manualExemption = true;
            item.Exemptions = taxResults;
            if("import custom duty" in taxResults){
                item.icdCharge = taxResults["import custom duty"].chargeAmount
            }
            if("value added tax" in taxResults){
                item.vatCharge = taxResults["value added tax"].chargeAmount
            }
        }
    });
}
// function convertArrayFormat(array) {
//     return array.map(item => ({
//         shortName: item.shortName,
//         amountExempt: 0.00
//     }));
// }
</script>


<style lang=""></style>
