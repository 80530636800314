<template>
  <div class="vessels-page">
    <h1>Vessels List</h1>
    <form class="my-form-container" @submit.prevent="addVessel">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group">
            <label for="vesselName"><strong>Vessel Name</strong></label>
            <input type="text" class="form-control" id="vesselName" placeholder="Please Enter Vessel Name" v-model="vesselName" />
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <label for="imoNumber"><strong>IMO Number</strong></label>
            <input type="text" class="form-control" id="imoNumber" placeholder="Please Enter IMO Number" v-model="imoNumber" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <p><strong></strong></p>
            <button class="btn btn-primary" type="submit">Add Vessel</button>
          </div>
        </div>
      </div>
    </form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Vessel Name</th>
          <th scope="col">IMO Number </th>
          <th scope="col">Expand</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(vessel, index) in vessels" :key="index">
          <tr >
            <th scope="row">{{ index + 1 }}</th>
            <td>{{vessel.name}}</td>
            <td>{{vessel.imoNumber}}</td>
            <td>
              <button type="button" class="btn btn-primary" @click="toggleDetails(index)">
                  <span v-if="expandedIndex === index">
                      <i class="fas fa-chevron-up" style="color: white"></i>
                  </span>
                  <span v-else>
                      <i class="fas fa-chevron-down" style="color: white"></i>
                  </span>
              </button>
            </td>
          </tr>
          <tr v-if="expandedIndex === index">
            <td colspan="3">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="vesselName"><strong>Vessel Name</strong></label>
                    <input type="text" class="form-control" id="vesselName" placeholder="Please Enter Vessel Name" v-model="vessel.name" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="imoNumber"><strong>IMO Number</strong></label>
                    <input type="text" class="form-control" id="imoNumber" placeholder="Please Enter IMO Number" v-model="vessel.imoNumber" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <button class="btn btn-primary edit-form-button" type="submit" @click="updateVessel(vessel)">Update Vessel</button>
                    <button class="btn btn-danger edit-form-button" type="submit" @click="deleteVessel(vessel)">Delete Vessel</button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>    
  </div> 
</template>
  
  <script>
    import * as DatabaseConnector from '@/composables/DatabaseConnector';
    import * as SweetAlertConnector from '@/composables/SweetAlertConnector';
  export default {
    name: 'VesselsPage',
    data(){
        return {
          vessels: [],
          expandedIndex: null,
          vesselName: '',
          imoNumber: ''
        }
    },
    mounted() {
        this.getVessels();
    },
    methods: {
        toggleDetails(index) {
          this.expandedIndex = this.expandedIndex === index ? null : index;
        },
        async updateVessel(vessel){
          SweetAlertConnector.showLoadingAlert('Updating Vessel');
          DatabaseConnector.updateVessel(vessel).then(() => {
            SweetAlertConnector.showSuccessAlert('Vessel Updated Successfully');
            this.getVessels();
          }).catch((error) => {
            SweetAlertConnector.showErrorAlert('Failed to Update Vessel', error);
          });
        },
        async deleteVessel(vessel){
          vessel.active = 0;
          SweetAlertConnector.showLoadingAlert('Deleting Vessel');
          DatabaseConnector.updateVessel(vessel).then(() => {
            SweetAlertConnector.showSuccessAlert('Vessel Deleted Successfully');
            this.getVessels();
          }).catch((error) => {
            SweetAlertConnector.showErrorAlert('Failed to Delete Vessel', error);
          });
        },
        async getVessels() {
            this.vessels = await DatabaseConnector.fetchVessels();
        },
        async addVessel(){
          const newVessel = {
            name: this.vesselName,
            imoNumber: this.imoNumber
          }
          SweetAlertConnector.showLoadingAlert('Adding Vessel');
          DatabaseConnector.insertVessel(newVessel).then(() => {
            SweetAlertConnector.showSuccessAlert('Vessel Added Successfully');
            this.vesselName = '';
            this.imoNumber = '';
            this.getVessels();
          }).catch((error) => {
            SweetAlertConnector.showErrorAlert('Failed to Add Vessel', error);
          });
        }
    },
  }
  </script>
  
  <style scoped>

  .vessels-page {
    padding: 20px;
    text-align: center;
  }
 .form-group {
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check label {
    margin-right: 1rem;
  }
  .my-form-container {
    text-align: left;
  }
  .edit-form-button {
    margin-right: 10px;
  }
  </style>
  