<template>
  <Bar
    v-if="chartDataReady"
    id="classification-score-chart"
    :options="chartOptions"
    :data="chartData"
    ref="myChart"
  />
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'ClassificationScoreChart',
  components: { Bar },
  data() {
    return {
      chartData: {
        labels: [], // Initialize as an empty array
        datasets: [
          {
            label: 'Score 5 - 1- 6 digits incorrect',
            backgroundColor: 'rgba(255, 99, 132, 0.7)', // Red color
            data: [], // Data for score 5
            stack: 'stack1',
            datalabels: {
              align: 'end',
              anchor: 'end',
              backgroundColor: 'rgba(255, 99, 132, 0.7)', // Match dataset background
              borderColor: 'white',
              borderRadius: 5,
              borderWidth: 2,
              padding: 6,
              color: 'white',
              font: {
                weight: 'bold'
              }
            }
          },
          {
            label: 'Score 3 - 7 - 8 digits incorrect',
            backgroundColor: 'rgba(54, 162, 235, 0.7)', // Blue color
            data: [], // Data for score 3
            stack: 'stack1',
            datalabels: {
              align: 'end',
              anchor: 'end',
              backgroundColor: 'rgba(54, 162, 235, 0.7)', // Match dataset background
              borderColor: 'white',
              borderRadius: 5,
              borderWidth: 2,
              padding: 6,
              color: 'white',
              font: {
                weight: 'bold'
              }
            }
          },
          {
            label: 'Score 1 - 9 - 11 digits incorrect',
            backgroundColor: 'rgba(75, 192, 86, 0.7)', // Green color
            data: [], // Data for score 1
            stack: 'stack1',
            datalabels: {
              align: 'end',
              anchor: 'end',
              backgroundColor: 'rgba(75, 192, 86, 0.7)', // Match dataset background
              borderColor: 'white',
              borderRadius: 5,
              borderWidth: 2,
              padding: 6,
              color: 'white',
              font: {
                weight: 'bold'
              }
            }
          }
        ]
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            stacked: true, // Enable stacking
            title: {
              display: true,
              text: 'Total Score',
            },
            beginAtZero: true,
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Classification Accuracy Score - Past 5 days',
            font: {
              size: 18, // Title font size
            },
            padding: {
              top: 10,
              bottom: 20,
            }
          },
          legend: {
            position: 'top',
          },
        },
      },
      chartDataReady: false // Control when to render the chart
    };
  },
  async mounted() {
    await this.fetchData(); // Fetch data when the component mounts
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}classification-scores`);
        const data = await response.json();
        console.log('Classification scores:', data);

        if (data && data.length > 0) {
          // Format dates and populate the chart data
          this.chartData.labels = data.map((entry) => new Date(entry.entry_date).toLocaleDateString('en-US'));
          this.chartData.datasets[0].data = data.map((entry) => entry.total_score_5);
          this.chartData.datasets[1].data = data.map((entry) => entry.total_score_3);
          this.chartData.datasets[2].data = data.map((entry) => entry.total_score_1);

          // Trigger the chart rendering
          this.chartDataReady = false;
          this.$nextTick(() => {
            this.chartDataReady = true;
          });
        }
        console.log('Updated chart data:', this.chartData);
      } catch (error) {
        console.error('Error fetching classification scores:', error);
      }
    }
  }
}
</script>

<style scoped>
/* Add any necessary styling here */
</style>
