<template>
  <div class="chart-container">
    <Doughnut
      v-if="chartDataReady"
      id="classification-code-match-chart"
      :options="chartOptions"
      :data="chartData"
      ref="myChart"
    />
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: 'ClassificationCodeMatchChart',
  components: { Doughnut },
  props: {
    reportDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: ['Match', 'Non-Match'], // Labels for the doughnut chart
        datasets: [
          {
            backgroundColor: ['#36A2EB', '#FF6384'], // Colors for the match and non-match sections
            data: [], // Initialize as an empty array
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: '#000',
              backgroundColor: 'rgba(211, 211, 211, 0.8)', // Light grey background
              borderColor: 'rgba(0, 0, 0, 0.1)', // Subtle border
              borderWidth: 1,
              borderRadius: 4, // Rounded corners
              padding: 4,
              font: {
                weight: 'bold'
              }
            }
          }
        ]
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Classification Code Match vs Non-Match',
            font: {
              size: 18, // Title font size
            },
            padding: {
              top: 10,
              bottom: 20,
            }
          }
        }
      },
      chartDataReady: false, // Control when to render the chart
    };
  },
  async mounted() {
    await this.fetchData(this.reportDate); // Fetch data when the component mounts
  },
  watch: {
    reportDate(newDate) {
      this.fetchData(newDate); // Re-fetch data when the date prop changes
    }
  },
  methods: {
    async fetchData(date) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}classification-code-match?date=${date}`);
        const data = await response.json();
        console.log('Classification code match percentages:', data);

        if (data) {
          // Populate the chart data with the percentages
          this.chartData.datasets[0].data = [data.match_percentage, data.non_match_percentage];

          // Trigger the chart rendering
          this.chartDataReady = false;
          this.$nextTick(() => {
            this.chartDataReady = true;
          });
        } else {
          // Reset the chart if no data is returned
          this.chartData.datasets[0].data = [];
          this.chartDataReady = true;
        }
        console.log('Updated chart data:', this.chartData);
      } catch (error) {
        console.error('Error fetching classification code match percentages:', error);
      }
    }
  }
};
</script>

<style scoped>
.chart-container {
  max-width: 400px;
  margin: auto;
}
</style>
