<template>
  <Bar
    v-if="chartDataReady"
    id="invoice-processing-time-chart"
    :options="chartOptions"
    :data="chartData"
    ref="myChart"
  />
</template>

<script>
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, LineController, PointElement);

export default {
  name: 'InvoiceBucketsPerformance',
  components: { Bar },
  props: {
    reportDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetchedData: [],
      chartData: {
        labels: [], // Labels for the buckets
        datasets: [
          {
            label: 'Number of Records',
            backgroundColor: 'rgba(75, 192, 192, 0.7)', // Bar color
            data: [], // Data for the number of records
            yAxisID: 'y', // Link to the primary y-axis
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: '#000',
              backgroundColor: 'rgba(211, 211, 211, 0.8)', // Light grey background
              borderColor: 'rgba(0, 0, 0, 0.1)', // Subtle border
              borderWidth: 1,
              borderRadius: 4, // Rounded corners
              padding: 4,
              font: {
                weight: 'bold'
              }
            }
          }
        ]
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Processing Time Buckets',
            },
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: 'Number of Entries',
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false, // Only draw grid lines for the primary y-axis
            },
            title: {
              display: true,
              text: 'Average File Size (KB)',
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: "Invoice AI Time Bucket Performance",
            font: {
              size: 18, // Title font size
            },
            padding: {
              top: 10,
              bottom: 20,
            }
          },
          legend: {
            position: 'top',
          },
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const elementIndex = elements[0].index;
            // const label = this.chartData.labels[elementIndex];
            const data = this.chartData.datasets[0].data[elementIndex];
            
            // Trigger your custom event or action here
            this.handleBarClick(elementIndex, data);
          }
        },
      },
      chartDataReady: false, // Control when to render the chart
    };
  },
  async mounted() {
    await this.fetchData(this.reportDate); // Fetch data when the component mounts
  },
  watch: {
    reportDate(newDate) {
      this.fetchData(newDate); // Re-fetch data when the date prop changes
    }
  },
  methods: {
    async fetchData(date) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}invoice-processing-time-buckets?date=${date}`);
        const data = await response.json();
        console.log('Invoice processing time buckets:', data);

        if (data && data.length > 0) {
          this.fetchedData = data;
          // Populate the chart data
          this.chartData.labels = data.map((entry) => entry.time_bucket);
          this.chartData.datasets[0].data = data.map((entry) => entry.record_count);

          // Trigger the chart rendering
          this.chartDataReady = false;
          this.$nextTick(() => {
            this.chartDataReady = true;
          });
        }
        console.log('Updated chart data:', this.chartData);
      } catch (error) {
        console.error('Error fetching invoice processing time buckets:', error);
      }
    },
    handleBarClick(index, data) {
      console.log(this.fetchedData);
      console.log(`Index: ${index}`);
      console.log(`Data: ${data}`);
      const selectedBucket = this.fetchedData[index];
      console.log('Selected bucket:', selectedBucket);
      const entryIdsArray = selectedBucket.entry_ids.split(',').map(id => id.trim());
      console.log('Entry IDs:', entryIdsArray);
      this.$router.push({ name: 'AllEntries', params: { entryIds: entryIdsArray.join(',') } });
      // Perform additional actions here (e.g., routing, showing details, etc.)
    },
  }
};
</script>

<style scoped>
.chart-container {
  max-width: 800px; /* Restrict the maximum width */
  width: 100%; /* Allow the container to use up to 100% of its parent's width */
  height: 100%; /* Ensure the container takes up full height */
  display: flex; /* Flexbox for alignment */
  align-items: stretch; /* Stretch to fill the available height */
  justify-content: center; /* Center the chart horizontally */
}

#invoice-processing-time-chart {
  flex: 0 1 auto; /* Prevent the chart from growing beyond its content width */
  width: 100%; /* Ensure the chart uses the full available width within its container */
  height: 100%; /* Ensure the chart uses full height */
}
</style>
