export function getConfigurations(){
    const configuration = {
        defaultCPCCode: "4000",
        boncedCPCCode: "7000",
        defaultRegimeType: "IM4",
        defaultNPCCode: "000",
    }
    return configuration;
}

export function getTrinidadTaxes(){
    const taxes = [
        {
            fullName: "Value Added Tax",
            shortName: "VAT",
        },
        {
            fullName: "Import Duty",
            shortName: "ICD",
        },
        // {
        //     fullName: "Import Surcharge",
        //     shortName: "SUR",
        // },
        // {
        //     fullName: "Tobacco Tax",
        //     shortName: "TOX",
        // },
        {
            fullName: "Motor Vehicle Tax",
            shortName: "MVT",
        },
        // {
        //     fullName: "Special Tax",
        //     shortName: "SPX",
        // },
        // {
        //     fullName: "Anti-Dumping Import Duty",
        //     shortName: "ADD",
        // }
    ];
    console.log(taxes);
    return taxes;
}

export function getFreightTypes(){
    const freightTypes = [
        {
            modeOfTransport: "Ocean",
            freightTypes: [
                { code: "12", name: "FCL" },
                { code: "13", name: "LCL" },
                { code: "11", name: "Break Bulk" },
            ]
        },
        {
            modeOfTransport: "Air",
            freightTypes: [
                { code: "41", name: "Express" },
                { code: "42", name: "Loose" },
            ]
        }
    ]
    return freightTypes;
}