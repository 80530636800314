<template>
    <div class="sellers-page">
      <h1>Shippers List</h1>
      <form class="my-form-container" @submit.prevent="addSeller">
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="sellerName"><strong>Name</strong></label>
              <input type="text" class="form-control" id="sellerName" placeholder="Please Enter Seller Name" v-model="sellerName" />
           </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="sellerAddressLine1"><strong>Address Line 1</strong></label>
              <input type="text" class="form-control" id="sellerAddressLine1" placeholder="Please Enter Address Line 1" v-model="sellerAddressLine1" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="sellerAddressLine2"><strong>Address Line 2</strong></label>
              <input type="text" class="form-control" id="sellerAddressLine2" placeholder="Please Enter Address Line 2" v-model="sellerAddressLine2" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="sellerCity"><strong>City</strong></label>
              <input type="text" class="form-control" id="sellerCity" placeholder="Please Enter City" v-model="sellerCity" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="sellerCountry"><strong>Country</strong></label>
              <!-- <input type="text" class="form-control" id="sellerCountry" v-model="sellerCountry" /> -->
              <select class="form-select" v-model="sellerCountry" id="sellerCountry">
                <option disabled value="">Please select one</option>
                <option v-for="country in this.countries" :key="country.code" :value="country.code">
                  {{ country.code }} - {{ country.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-1">
          <div class="form-group">
            <label for="tinNumber"><strong>TIN Number</strong></label>
            <input type="text" class="form-control" id="tinNumber" placeholder="TIN Number" v-model="tinNumber" />
          </div>
        </div>
          <div class="col-md-1">
            <div class="form-group">
              <p><strong></strong></p>
              <button class="btn btn-primary" type="submit">Add Shipper</button>
            </div>
          </div>
        </div>
      </form>
     
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Address </th>
            <th scope="col">Country</th>
            <th scope="col">TIN Number</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(seller, index) in sellers" :key="index">
            <tr>
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ seller.name }}</td>
              <td>{{ seller.address }}</td>
              <td>{{ seller.country }}</td>
              <td>{{ seller.taxIdentificationNumber }}</td>
              <td>
                <button class="btn btn-primary" @click="toggleDetails(index)">
                  <span v-if="expandedIndex === index">
                    <i class="fas fa-chevron-up" style="color: white"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-chevron-down" style="color: white"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr v-if="expandedIndex === index">
              <td colspan="6">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="shipperName"><strong>Name</strong></label>
                      <input type="text" class="form-control" id="shipperName" v-model="seller.name" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="tinNumber"><strong>TIN Number</strong></label>
                      <input type="text" class="form-control" id="tinNumber" v-model="seller.taxIdentificationNumber" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="shipperAddressLine1"><strong>Address Line 1</strong></label>
                      <textarea type="text" class="form-control" id="shipperAddressLine1" v-model="seller.address" rows="4"></textarea>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="shipperCountry"><strong>Country</strong></label>
                      <v-select 
                      v-model="seller.country" 
                      :options="countries" 
                      label="name"
                      :searchable="true"
                      :filterable="true"
                      :reduce="option => option.code"
                      ></v-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <button class="btn btn-primary edit-form-button" @click="updateShipper(seller)">Update Shipper</button>
                      <button class="btn btn-danger edit-form-button" @click="deleteShipper(seller)">Delete Shipper</button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>

import * as DatabaseConnector from '@/composables/DatabaseConnector';
import * as SweetAlertConnector from '@/composables/SweetAlertConnector';

  export default {
    name: 'SellersPage',
    data() {
      return {
        sellers : [],
        countries: [],
        sellerName: "",
        sellerAddressLine1: "",
        sellerAddressLine2: "",
        sellerCity: "",
        sellerCountry: "",
        tinNumber: "",
        expandedIndex: null,
      };
    },
    methods: {
      toggleDetails(index) {
        this.expandedIndex = this.expandedIndex === index ? null : index;
      },
      async getSellers() {
        this.sellers = await DatabaseConnector.fetchShippers();
      },
      async updateShipper(seller){
        SweetAlertConnector.showLoadingAlert('Updating Data...');
        DatabaseConnector.updateShipper(seller).then(() => {
          this.getSellers();
          SweetAlertConnector.showSuccessAlert('Shipper updated successfully');
        }).catch((error) => {
          SweetAlertConnector.showErrorAlert('Error updating shipper', error);
        });
      },
      async deleteShipper(seller){
        seller.active = 0;
        SweetAlertConnector.showLoadingAlert('Deleting Data...');
        DatabaseConnector.updateShipper(seller).then(() => {
          this.getSellers();
          SweetAlertConnector.showSuccessAlert('Shipper deleted successfully');
        }).catch((error) => {
          SweetAlertConnector.showErrorAlert('Error deleting shipper', error);
        });
      },
      async getContries() {
        this.countries = await DatabaseConnector.fetchCountries();
      },
      async addSeller() {
        const newSeller = {
          name: this.sellerName,
          address: `${this.sellerAddressLine1}\n${this.sellerAddressLine2}\n${this.sellerCity}`,
          country: this.sellerCountry,
          phoneContact: "",
          emailContact: "",
          taxIdentificationNumber: this.tinNumber
        };
        console.log("Seller", newSeller)
        SweetAlertConnector.showLoadingAlert('Saving Data...');
        DatabaseConnector.addShipper(newSeller).then(() => {
          this.sellerName = "";
          this.sellerAddressLine1 = "";
          this.sellerAddressLine2 = "";
          this.sellerCity = "";
          this.sellerCountry = "";
          this.tinNumber = "";
          this.getSellers();
          SweetAlertConnector.showSuccessAlert('Shipper added successfully');
        }).catch((error) => {
          SweetAlertConnector.showErrorAlert('Error adding shipper', error);
        });
      }
    },
    mounted() {
      this.getSellers();
      this.getContries();
      // Code to execute after the component mounts
    }
  }
  </script>
  
  <style scoped>
    .sellers-page {
      padding: 20px;
      text-align: center;
    }
    
    .customs-declaration-section-two {
    /* Your styles here */
  }
  .form-group {
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adds space below each form group */
  }
  .form-check label {
    margin-right: 1rem;
  }
  .my-form-container {
    text-align: left;
  }
  .edit-form-button {
    margin-right: 10px;
  }
  </style>
  