import { ref } from 'vue'
export function usePackageTypes(){
    const packageTypesList = ref([{ "value": "1A", "text": "Drum, steel" },
    { "value": "1B", "text": "Drum, aluminium" },
    { "value": "1D", "text": "Drum, plywood" },
    { "value": "1G", "text": "Drum, fibre" },
    { "value": "1W", "text": "Drum, wooden" },
    { "value": "2C", "text": "Barrel, wooden" },
    { "value": "3A", "text": "Jerrican, steel" },
    { "value": "3H", "text": "Jerrican, plastic" },
    { "value": "43", "text": "Bag, super bulk" },
    { "value": "4A", "text": "Box, steel" },
    { "value": "4B", "text": "Box, aluminium" },
    { "value": "4C", "text": "Box, natural wood" },
    { "value": "4D", "text": "Box, plywood" },
    { "value": "4F", "text": "Box, reconstituted wood" },
    { "value": "4G", "text": "Box, fibreboard" },
    { "value": "4H", "text": "Box, plastic" },
    { "value": "5H", "text": "Bag, woven plastic" },
    { "value": "5L", "text": "Bag, textile" },
    { "value": "5M", "text": "Bag, paper" },
    { "value": "6H", "text": "Composite packaging, plastic receptacle" },
    { "value": "6P", "text": "Composite packaging, glass receptacle" },
    { "value": "AA", "text": "Intermediate bulk container, rigid" },
    { "value": "AB", "text": "Receptacle, fibre" },
    { "value": "AC", "text": "Receptacle, paper" },
    { "value": "AD", "text": "Receptacle, wooden" },
    { "value": "AE", "text": "Aerosol" },
    { "value": "AF", "text": "Pallet, modular, collars 80cms * 6" },
    { "value": "AG", "text": "Pallet, shrinkwrapped" },
    { "value": "AH", "text": "Pallet, 100cms * 110cms" },
    { "value": "AI", "text": "Clamshell" },
    { "value": "AJ", "text": "Cone" },
    { "value": "AM", "text": "Ampoule, non-protected" },
    { "value": "AP", "text": "Ampoule, protected" },
    { "value": "AT", "text": "Atomizer" },
    { "value": "AV", "text": "Capsule" },
    { "value": "BA", "text": "Barrel" },
    { "value": "BB", "text": "Bobbin" },
    { "value": "BC", "text": "Bottlecrate / bottlerack" },
    { "value": "BD", "text": "Board" },
    { "value": "BE", "text": "Bundle" },
    { "value": "BF", "text": "Balloon, non-protected" },
    { "value": "BG", "text": "Bag" },
    { "value": "BH", "text": "Bunch" },
    { "value": "BI", "text": "Bin" },
    { "value": "BJ", "text": "Bucket" },
    { "value": "BK", "text": "Basket" },
    { "value": "BL", "text": "Bale, compressed" },
    { "value": "BM", "text": "Basin" },
    { "value": "BN", "text": "Bale, non-compressed" },
    { "value": "BO", "text": "Bottle, non-protected, cylindrical" },
    { "value": "BP", "text": "Balloon, protected" },
    { "value": "BQ", "text": "Bottle, protected cylindrical" },
    { "value": "BR", "text": "Bar" },
    { "value": "BS", "text": "Bottle, non-protected, bulbous" },
    { "value": "BT", "text": "Bolt" },
    { "value": "BU", "text": "Butt" },
    { "value": "BV", "text": "Bottle, protected bulbous" },
    { "value": "BW", "text": "Box, for liquids" },
    { "value": "BX", "text": "Box" },
    { "value": "BY", "text": "Board, in bundle/bunch/truss" },
    { "value": "BZ", "text": "Bars, in bundle/bunch/truss" },
    { "value": "CA", "text": "Can, rectangular" },
    { "value": "CB", "text": "Crate, beer" },
    { "value": "CC", "text": "Churn" },
    { "value": "CD", "text": "Can, with handle and spout" },
    { "value": "CE", "text": "Creel" },
    { "value": "CF", "text": "Coffer" },
    { "value": "CG", "text": "Cage" },
    { "value": "CH", "text": "Chest" },
    { "value": "CI", "text": "Canister" },
    { "value": "CJ", "text": "Coffin" },
    { "value": "CK", "text": "Cask" },
    { "value": "CL", "text": "Coil" },
    { "value": "CM", "text": "Card" },
    { "value": "CN", "text": "Container, not otherwise specified" },
    { "value": "CO", "text": "Carboy, non-protected" },
    { "value": "CP", "text": "Carboy, protected" },
    { "value": "CQ", "text": "Cartridge" },
    { "value": "CR", "text": "Crate" },
    { "value": "CS", "text": "Case" },
    { "value": "CT", "text": "Carton" },
    { "value": "CU", "text": "Cup" },
    { "value": "CV", "text": "Cover" },
    { "value": "CW", "text": "Cage, roll" },
    { "value": "CX", "text": "Can, cylindrical" },
    { "value": "CY", "text": "Cylinder" },
    { "value": "CZ", "text": "Canvas" },
    { "value": "DA", "text": "Crate, multiple layer, plastic" },
    { "value": "DB", "text": "Crate, multiple layer, wooden" },
    { "value": "DC", "text": "Crate, multiple layer, cardboard" },
    { "value": "DG", "text": "Cage, Commonwealth Handling Equipment" },
    { "value": "DH", "text": "Box, Commonwealth Handling Equipment" },
    { "value": "DI", "text": "Drum, iron" },
    { "value": "DJ", "text": "Demijohn, non-protected" },
    { "value": "DK", "text": "Crate, bulk, cardboard" },
    { "value": "DL", "text": "Crate, bulk, plastic" },
    { "value": "DM", "text": "Crate, bulk, wooden" },
    { "value": "DN", "text": "Dispenser" },
    { "value": "DP", "text": "Demijohn, protected" },
    { "value": "DR", "text": "Drum" },
    { "value": "DS", "text": "Tray, one layer no cover, plastic" },
    { "value": "DT", "text": "Tray, one layer no cover, wooden" },
    { "value": "DU", "text": "Tray, one layer no cover, polystyrene" },
    { "value": "DV", "text": "Tray, one layer no cover, cardboard" },
    { "value": "DW", "text": "Tray, two layers no cover, plastic" },
    { "value": "DX", "text": "Tray, two layers no cover, wooden" },
    { "value": "DY", "text": "Tray, two layers no cover, cardboard" },
    { "value": "EC", "text": "Bag, plastic" },
    { "value": "ED", "text": "Case, with pallet base" },
    { "value": "EE", "text": "Case, with pallet base, wooden" },
    { "value": "EF", "text": "Case, with pallet base, cardboard" },
    { "value": "EG", "text": "Case, with pallet base, plastic" },
    { "value": "EH", "text": "Case, with pallet base, metal" },
    { "value": "EI", "text": "Case, isothermic" },
    { "value": "EN", "text": "Envelope" },
    { "value": "FC", "text": "Crate, fruit" },
    { "value": "FD", "text": "Crate, framed" },
    { "value": "FI", "text": "Firkin" },
    { "value": "FL", "text": "Flask" },
    { "value": "FO", "text": "Footlocker" },
    { "value": "FP", "text": "Filmpack" },
    { "value": "FR", "text": "Frame" },
    { "value": "FT", "text": "Foodtainer" },
    { "value": "FW", "text": "Cart, flatbed" },
    { "value": "FX", "text": "Bag, flexible container" },
    { "value": "GB", "text": "Bottle, gas" },
    { "value": "GI", "text": "Girder" },
    { "value": "GR", "text": "Receptacle, glass" },
    { "value": "GU", "text": "Tray, containing horizontally stacked" },
    { "value": "GZ", "text": "Girders, in bundle/bunch/truss" },
    { "value": "HA", "text": "Basket, with handle, plastic" },
    { "value": "HB", "text": "Basket, with handle, wooden" },
    { "value": "HC", "text": "Basket, with handle, cardboard" },
    { "value": "HG", "text": "Hogshead" },
    { "value": "HR", "text": "Hamper" },
    { "value": "IA", "text": "Package, display, wooden" },
    { "value": "IB", "text": "Package, display, cardboard" },
    { "value": "IC", "text": "Package, display, plastic" },
    { "value": "ID", "text": "Package, display, metal" },
    { "value": "IE", "text": "Package, show" },
    { "value": "IF", "text": "Package, flow" },
    { "value": "IG", "text": "Package, paper wrapped" },
    { "value": "IH", "text": "Drum, plastic" },
    { "value": "IK", "text": "Package, cardboard, with bottle grip" },
    { "value": "IL", "text": "Tray, rigid, lidded stackable (CEN)" },
    { "value": "IN", "text": "Ingot" },
    { "value": "IZ", "text": "Ingots, in bundle/bunch/truss" },
    { "value": "JC", "text": "Jerrican, rectangular" },
    { "value": "JG", "text": "Jug" },
    { "value": "JR", "text": "Jar" },
    { "value": "JT", "text": "Jutebag" },
    { "value": "JY", "text": "Jerrican, cylindrical" },
    { "value": "KG", "text": "Keg" },
    { "value": "LG", "text": "Log" },
    { "value": "LT", "text": "Lot" },
    { "value": "LV", "text": "Liftvan" },
    { "value": "LZ", "text": "Logs, in bundle/bunch/truss" },
    { "value": "MB", "text": "Bag, multiply" },
    { "value": "MC", "text": "Crate, milk" },
    { "value": "MR", "text": "Receptacle, metal" },
    { "value": "MS", "text": "Sack, multi-wall" },
    { "value": "MT", "text": "Mat" },
    { "value": "MW", "text": "Receptacle, plastic wrapped" },
    { "value": "MX", "text": "Matchbox" },
    { "value": "NA", "text": "Not available" },
    { "value": "NE", "text": "Unpacked or unpackaged" },
    { "value": "NF", "text": "Unpacked or unpackaged, single unit" },
    { "value": "NG", "text": "Unpacked or unpackaged, multiple units" },
    { "value": "NS", "text": "Nest" },
    { "value": "NT", "text": "Net" },
    { "value": "NU", "text": "Net, tube, plastic" },
    { "value": "NV", "text": "Net, tube, textile" },
    { "value": "OA", "text": "Pallet, CHEP 40 cm x 60 cm" },
    { "value": "OB", "text": "Pallet, CHEP 80 cm x 120 cm" },
    { "value": "OC", "text": "Pallet, CHEP 100 cm x 120 cm" },
    { "value": "OD", "text": "Pallet, AS 4068-1993" },
    { "value": "OE", "text": "Pallet, ISO T11" },
    { "value": "OF", "text": "Platform, unspecified weight or dimensions" },
    { "value": "OK", "text": "Block" },
    { "value": "PA", "text": "Packet" },
    { "value": "PB", "text": "Pallet, box Combined open-ended box" },
    { "value": "PC", "text": "Parcel" },
    { "value": "PD", "text": "Pallet, modular, collars 80cms * 1" },
    { "value": "PE", "text": "Pallet, modular, collars 80cms * 1" },
    { "value": "PF", "text": "Pen" },
    { "value": "PG", "text": "Plate" },
    { "value": "PH", "text": "Pitcher" },
    { "value": "PI", "text": "Pipe" },
    { "value": "PJ", "text": "Punnet" },
    { "value": "PK", "text": "Package" },
    { "value": "PL", "text": "Pail" },
    { "value": "PN", "text": "Plank" },
    { "value": "PO", "text": "Pouch" },
    { "value": "PR", "text": "Receptacle, plastic" },
    { "value": "PT", "text": "Pot" },
    { "value": "PU", "text": "Tray" },
    { "value": "PV", "text": "Pipes, in bundle/bunch/truss" },
    { "value": "PX", "text": "Pallet" },
    { "value": "PY", "text": "Plates, in bundle/bunch/truss" },
    { "value": "PZ", "text": "Planks, in bundle/bunch/truss" },
    { "value": "QA", "text": "Drum, steel, non-removable head" },
    { "value": "QB", "text": "Drum, steel, removable head" },
    { "value": "QC", "text": "Drum, aluminium, non-removable head" },
    { "value": "QD", "text": "Drum, aluminium, removable head" },
    { "value": "QF", "text": "Drum, plastic, non-removable head" },
    { "value": "QG", "text": "Drum, plastic, removable head" },
    { "value": "QH", "text": "Barrel, wooden, bung type" },
    { "value": "QJ", "text": "Barrel, wooden, removable head" },
    { "value": "QK", "text": "Jerrican, steel, non-removable head" },
    { "value": "QL", "text": "Jerrican, steel, removable head" },
    { "value": "QM", "text": "Jerrican, plastic, non-removable head" },
    { "value": "QN", "text": "Jerrican, plastic, removable head" },
    { "value": "QP", "text": "Box, wooden, natural wood, ordinary" },
    { "value": "QQ", "text": "Box, wooden, natural wood, with side" },
    { "value": "QR", "text": "Box, plastic, expanded" },
    { "value": "QS", "text": "Box, plastic, solid" },
    { "value": "RD", "text": "Rod" },
    { "value": "RG", "text": "Ring" },
    { "value": "RJ", "text": "Rack, clothing hanger" },
    { "value": "RK", "text": "Rack" },
    { "value": "RL", "text": "Reel" },
    { "value": "RO", "text": "Roll" },
    { "value": "RT", "text": "Rednet" },
    { "value": "RZ", "text": "Rods, in bundle/bunch/truss" },
    { "value": "SA", "text": "Sack" },
    { "value": "SB", "text": "Slab" },
    { "value": "SC", "text": "Crate, shallow" },
    { "value": "SD", "text": "Spindle" },
    { "value": "SE", "text": "Sea-chest" },
    { "value": "SH", "text": "Sachet" },
    { "value": "SI", "text": "Skid" },
    { "value": "SK", "text": "Case, skeleton" },
    { "value": "SL", "text": "Slipsheet" },
    { "value": "SM", "text": "Sheetmetal" },
    { "value": "SO", "text": "Spool" },
    { "value": "SP", "text": "Sheet, plastic wrapping" },
    { "value": "SS", "text": "Case, steel" },
    { "value": "ST", "text": "Sheet" },
    { "value": "SU", "text": "Suitcase" },
    { "value": "SV", "text": "Envelope, steel" },
    { "value": "SW", "text": "Shrinkwrapped" },
    { "value": "SX", "text": "Set" },
    { "value": "SY", "text": "Sleeve" },
    { "value": "SZ", "text": "Sheets, in bundle/bunch/truss" },
    { "value": "TB", "text": "Tub" },
    { "value": "TC", "text": "Tea-chest" },
    { "value": "TD", "text": "Tube, collapsible" },
    { "value": "TI", "text": "Tierce" },
    { "value": "TK", "text": "Tank, rectangular" },
    { "value": "TL", "text": "Tub, with lid" },
    { "value": "TN", "text": "Tin" },
    { "value": "TO", "text": "Tun" },
    { "value": "TR", "text": "Trunk" },
    { "value": "TS", "text": "Truss" },
    { "value": "TU", "text": "Tube" },
    { "value": "TV", "text": "Tube, with nozzle" },
    { "value": "TY", "text": "Tank, cylindrical" },
    { "value": "TZ", "text": "Tubes, in bundle/bunch/truss" },
    { "value": "UC", "text": "Uncaged" },
    { "value": "UN", "text": "Unit (single item or object)" },
    { "value": "VA", "text": "Vat" },
    { "value": "VG", "text": "Bulk, gas (at 1031 mbar and 15°C)" },
    { "value": "VI", "text": "Vial" },
    { "value": "VK", "text": "Vanpack" },
    { "value": "VL", "text": "Bulk, liquid" },
    { "value": "VN", "text": "Vehicle" },
    { "value": "VO", "text": "Bulk, solid, large particles" },
    { "value": "VP", "text": "Vacuum-packed" },
    { "value": "VQ", "text": "Bulk, liquefied gas (at abnormal temperature/pressure)" },
    { "value": "VR", "text": "Bulk, solid, granular particles (i.e., granulates)" },
    { "value": "VY", "text": "Bulk, solid, fine particles (i.e., powdered)" },
    { "value": "WA", "text": "Intermediate bulk container" },
    { "value": "WB", "text": "Wickerbottle" },
    { "value": "WC", "text": "Intermediate bulk container, steel" },
    { "value": "WD", "text": "Intermediate bulk container, aluminium" },
    { "value": "WF", "text": "Intermediate bulk container, metal" },
    { "value": "WG", "text": "Intermediate bulk container, steel" },
    { "value": "WH", "text": "Intermediate bulk container, aluminium" },
    { "value": "WJ", "text": "Intermediate bulk container, metal" },
    { "value": "WK", "text": "Intermediate bulk container, steel" },
    { "value": "WL", "text": "Intermediate bulk container, aluminium" },
    { "value": "WM", "text": "Intermediate bulk container, metal" },
    { "value": "WN", "text": "Intermediate bulk container, woven" },
    { "value": "WP", "text": "Intermediate bulk container, woven" },
    { "value": "WQ", "text": "Intermediate bulk container, woven" },
    { "value": "WR", "text": "Intermediate bulk container, woven" },
    { "value": "WS", "text": "Intermediate bulk container, plastic" },
    { "value": "WT", "text": "Intermediate bulk container, textile" },
    { "value": "WU", "text": "Intermediate bulk container, natural" },
    { "value": "WV", "text": "Intermediate bulk container, textile" },
    { "value": "WW", "text": "Intermediate bulk container, textile" },
    { "value": "WX", "text": "Intermediate bulk container, textile" },
    { "value": "WY", "text": "Intermediate bulk container, plywood" },
    { "value": "WZ", "text": "Intermediate bulk container, reconstructed" },
    { "value": "XA", "text": "Bag, woven plastic, without inner coat" },
    { "value": "XB", "text": "Bag, woven plastic, sift proof" },
    { "value": "XC", "text": "Bag, woven plastic, water resistant" },
    { "value": "XD", "text": "Bag, plastics film" },
    { "value": "XF", "text": "Bag, textile, without inner coat" },
    { "value": "XG", "text": "Bag, textile, sift proof" },
    { "value": "XH", "text": "Bag, textile, water resistant" },
    { "value": "XJ", "text": "Bag, paper, multi-wall" },
    { "value": "XK", "text": "Bag, paper, multi-wall, water resistant" },
    { "value": "YA", "text": "Composite packaging, plastic receptacle" },
    { "value": "YB", "text": "Composite packaging, plastic receptacle" },
    { "value": "YC", "text": "Composite packaging, plastic receptacle" },
    { "value": "YD", "text": "Composite packaging, plastic receptacle" },
    { "value": "YF", "text": "Composite packaging, plastic receptacle" },
    { "value": "YG", "text": "Composite packaging, plastic receptacle" },
    { "value": "YH", "text": "Composite packaging, plastic receptacle" },
    { "value": "YJ", "text": "Composite packaging, plastic receptacle" },
    { "value": "YK", "text": "Composite packaging, plastic receptacle" },
    { "value": "YL", "text": "Composite packaging, plastic receptacle" },
    { "value": "YM", "text": "Composite packaging, plastic receptacle" },
    { "value": "YN", "text": "Composite packaging, glass receptacle" },
    { "value": "YP", "text": "Composite packaging, glass receptacle" },
    { "value": "YQ", "text": "Composite packaging, glass receptacle" },
    { "value": "YR", "text": "Composite packaging, glass receptacle" },
    { "value": "YS", "text": "Composite packaging, glass receptacle" },
    { "value": "YT", "text": "Composite packaging, glass receptacle" },
    { "value": "YV", "text": "Composite packaging, glass receptacle" },
    { "value": "YW", "text": "Composite packaging, glass receptacle" },
    { "value": "YX", "text": "Composite packaging, glass receptacle" },
    { "value": "YY", "text": "Composite packaging, glass receptacle" },
    { "value": "YZ", "text": "Composite packaging, glass receptacle" },
    { "value": "ZA", "text": "Intermediate bulk container, paper" },
    { "value": "ZB", "text": "Bag, large" },
    { "value": "ZC", "text": "Intermediate bulk container, paper" },
    { "value": "ZD", "text": "Intermediate bulk container, rigid" },
    { "value": "ZF", "text": "Intermediate bulk container, rigid" },
    { "value": "ZG", "text": "Intermediate bulk container, rigid" },
    { "value": "ZH", "text": "Intermediate bulk container, rigid" },
    { "value": "ZJ", "text": "Intermediate bulk container, rigid" },
    { "value": "ZK", "text": "Intermediate bulk container, rigid" },
    { "value": "ZL", "text": "Intermediate bulk container, composite" },
    { "value": "ZM", "text": "Intermediate bulk container, composite" },
    { "value": "ZN", "text": "Intermediate bulk container, composite" },
    { "value": "ZP", "text": "Intermediate bulk container, composite" },
    { "value": "ZQ", "text": "Intermediate bulk container, composite" },
    { "value": "ZR", "text": "Intermediate bulk container, fibre" },
    { "value": "ZS", "text": "Intermediate bulk container, fibre" },
    { "value": "ZT", "text": "Intermediate bulk container, fibre" },
    { "value": "ZU", "text": "Intermediate bulk container, flexible" },
    { "value": "ZV", "text": "Intermediate bulk container, metal" },
    { "value": "ZW", "text": "Intermediate bulk container, natural" },
    { "value": "ZX", "text": "Intermediate bulk container, plywood" },
    { "value": "ZY", "text": "Intermediate bulk container, reconstructed" },
    { "value": "ZZ", "text": "Mutually defined" }]);

    return packageTypesList 
}