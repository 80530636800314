<template>
    <div>
      <VehicleEntry :record="record" :vehicleDetails="vehicleDetails" :invoiceDetails="invoiceDetails" :referenceNumberRecord="referenceNumberRecord" :isEditMode="true" @submit="handleEdit" />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import VehicleEntry from './VehicleEntry.vue';
  import * as DatabaseConnector from '@/composables/DatabaseConnector';
  import * as SweetAlertConnector from '../composables/SweetAlertConnector';
  import * as Utilities from '@/composables/Utilities';
  import * as PackageTypes from '@/composables/PackageTypes';
  import * as IncoTerms from '@/composables/IncoTerms';
  import * as BrokerCongifurations from '@/composables/BrokerConfiguration';
  
  export default {
    components: { VehicleEntry },
    data() {
      return {
        entryId: '',
        waybillId: '',
        entryStatus: '',
        record: Utilities.initializeCustomsEntryForm(), // Initialize record to null
        vehicleDetails: [],
        invoiceDetails: {
          invoice_id: '',
          supplierName: '',
          invoiceNumber: '',
          invoiceDate: '',
          invoiceCurrency: '',
          rateOfExchange: 0.0,
          invoiceTotal: 0.0,
          inlandAmount: 0.0,
          insuranceAmount: 0.0,
          otherCharges: 0.0,
        },
        referenceNumberRecord: '',
      };
    },
    mounted() {
        this.entryId = this.$route.params.entryId;
        this.fetchEntryDetails();
        this.getRegimeTypes();
        this.fetchPorts();
    },
    methods: {
        async fetchEntryDetails() {
            console.log('Fetching details for entry ID:', this.entryId);
            SweetAlertConnector.showLoadingAlert('Fetching Entry details...');
            try{
              const entryDetailsPromise = DatabaseConnector.getEntryDetails(this.entryId);
              const commercialInvoicePromise = DatabaseConnector.getCommercialInvoiceDetailsWithVehicles(this.entryId);

              const [entryDetailsResponse, commercialInvoiceResponse] = await Promise.all([entryDetailsPromise, commercialInvoicePromise]);
              SweetAlertConnector.closeCurrentAlert();
              SweetAlertConnector.showSuccessAlert('Entry details fetched successfully');
              console.log('Entry details:', entryDetailsResponse);
              // this.record = this.mapResponseToRecord(entryDetailsResponse);
              let waybillDate = entryDetailsResponse.waybill_date;

              let dateString;
              if (waybillDate === "0000-00-00" || !waybillDate) {
                  dateString = "";
              } else {
                  let dateObject = new Date(waybillDate);
                  if (isNaN(dateObject)) {
                      dateString = "";
                  } else {
                      dateString = dateObject.toISOString().split("T")[0];
                  }
              }
              this.record = Utilities.initializeCustomsEntryForm();
              this.record.incotermsList = IncoTerms.getIncoTermsList();
              this.record.modeOfTransportOOptions = BrokerCongifurations.getFreightTypes();
              this.record.packageTypes = PackageTypes.usePackageTypes();
              this.record.importerId = entryDetailsResponse.importer;
              this.record.exporterId = entryDetailsResponse.shipper;
              this.record.waybillNumber = entryDetailsResponse.waybill_number;
              this.record.waybillDate = dateString;
              this.record.modeOfTransport = entryDetailsResponse.mode_of_transport;
              this.record.freightType = entryDetailsResponse.freight_type;
              this.record.vesselId = entryDetailsResponse.vessel;
              this.record.portOfDischargeId = entryDetailsResponse.port_of_discharge;
              this.record.netWeight = entryDetailsResponse.net_weight;
              this.record.grossWeight = entryDetailsResponse.gross_weight;
              this.record.numberOfPackages = entryDetailsResponse.package_quantity;
              this.record.packageType = entryDetailsResponse.package_type;
              this.record.marksAndNumbers = entryDetailsResponse.marks_and_numbers;
              this.record.totalCargoValue = entryDetailsResponse.invoice_total ?? 0;
              this.record.freightAmount = entryDetailsResponse.freight_charge ?? 0;
              this.record.insuranceAmount = entryDetailsResponse.insurance_amount ?? 0;
              this.record.otherCharges = entryDetailsResponse.other_charges ?? 0;
              this.record.rateOfExchange = entryDetailsResponse.rate_of_exchange;
              this.record.countryOfOrigin = entryDetailsResponse.country_of_origin;
              this.record.countryOfFinalDestination = entryDetailsResponse.country_of_final_destination;
              this.record.regimeType = entryDetailsResponse.regimeType;
              this.record.regimeTypeCode = entryDetailsResponse.regimeTypeCode;
              this.record.depositAmount = entryDetailsResponse.deposit ?? 0;
              this.record.containerCharges = entryDetailsResponse.container_charges ?? 0;
              this.record.additionalCharges = entryDetailsResponse.additional_charges ?? 0;
              this.record.localFee = entryDetailsResponse.local_fee ?? 0;
              this.record.declarantId = entryDetailsResponse.declarant;
              this.record.incoTerms = entryDetailsResponse.incoterms;
              this.record.countryLastProvinance = entryDetailsResponse.country_last_provenance;
              this.record.tradingCountry = entryDetailsResponse.trading_country;
              this.entryStatus = entryDetailsResponse.entry_status;
              this.fetchPorts();

              console.log('Commercial Invoice details:', commercialInvoiceResponse);
              this.vehicleDetails = this.processVehicleData(commercialInvoiceResponse[0].lines);
              this.vehicleDetails.forEach(vehicle => {
                this.updateCommodityCode(vehicle);
              });
              this.invoiceDetails = {
                invoice_id: commercialInvoiceResponse[0].ci_invoice_id,
                supplierName: commercialInvoiceResponse[0].supplier_name,
                invoiceNumber: commercialInvoiceResponse[0].invoice_number,
                invoiceDate: commercialInvoiceResponse[0].invoice_date,
                invoiceCurrency: commercialInvoiceResponse[0].currency,
                rateOfExchange: commercialInvoiceResponse[0].rate_of_exchange,
                invoiceTotal: commercialInvoiceResponse[0].invoice_total,
                inlandAmount: commercialInvoiceResponse[0].inland,
                insuranceAmount: commercialInvoiceResponse[0].insurance,
                otherCharges: commercialInvoiceResponse[0].other_charges,
              };
              this.referenceNumberRecord = entryDetailsResponse.entry_number;
              this.waybillId = entryDetailsResponse.waybill_id;
              console.log('Vehicle details:', this.vehicleDetails);
            }
            catch(error){
              console.error('Failed to fetch the Entry details:', error);
              SweetAlertConnector.showErrorAlert('Failed to fetch the Entry details. Please try again.');
            }
        },
        updateCommodityCode(vehicle){
          if(vehicle.thnNumber === undefined || vehicle.thnNumber === null || vehicle.thnNumber === '') {
            vehicle.icdCharge = 0;
            vehicle.vatCharge = 0;
            vehicle.mvtCharge = 0;
            return 
          }
          DatabaseConnector.getCommodityCharges(vehicle.thnNumber.replace(/\./g, "")).then(chargesResponse => {
            let icdObject = chargesResponse.charges["import custom duty"];
            const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject[0].charge_amount);
            let vatObject = chargesResponse.charges["value added tax"];
            const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject[0].charge_amount);
            let mvtObject = chargesResponse.charges["motor vehicle tax"];
            const mvtCharge = mvtObject === undefined ? 0 : parseFloat(mvtObject[0].charge_amount);
            vehicle.icdCharge = icdCharge;
            vehicle.vatCharge = vatCharge;
            vehicle.mvtCharge = mvtCharge;
            vehicle.commodityCodeStatus = 'valid'
            vehicle.applicableTaxes = chargesResponse.charges;
            vehicle.commodity_description = chargesResponse.description;
          }).catch(error => {
            console.error('Failed to fetch the commodity charges:', error);
            vehicle.icdCharge = 0;
            vehicle.vatCharge = 0;
            vehicle.mvtCharge = 0;
            vehicle.commodityCodeStatus = 'invalid'
          });

        },
        async getRegimeTypes(){
          return DatabaseConnector.fetchCpcCodes('TT').then(data => {
            this.record.regimeTypeOptions = data;
          });
        },
        async fetchPorts(){
          return DatabaseConnector.fetchPorts().then(data => {
            this.record.ports = data;
          });
        },
        processVehicleDataOld(vehicleList){
          const groupedByProductCode = [];

          vehicleList.forEach(item => {
            const productCode = item['product_code'];
            const details = {
              id: item.id,
              chassisNumber: item.chassis_number,
              engineNumber: item.engine_number,
              exteriorColor: item.exterior_color,
              customerName: item.customer_name ?? '',
              customerTIN: item.customer_tin ?? '',
              brokerInstructions: item.broker_instructions,
              cpcCode: item.cpc_code, // TODO: Fetch CPC Code,
              npcCode: item.npc_code, //TODO: Fetch NPC Code,
              edNumber: item.ed_number,
              quantity: item.quantity,
              grossWeight: item.gross_weight ?? 0,
              netWeight: item.net_weight ?? 0,
              engineDisplacement: item.engine_displacement,
              manufactureYear: item.manufacture_year ?? '2000',
              engine_number: item.engine_number,
              invoiceLineId: item.cil_invoice_line_id
            };

            const currentProductCode = item['product_code'];
            const vehicle = groupedByProductCode.find(vehicle => vehicle.vehicleCode === currentProductCode);
            if (vehicle) {
              vehicle.quantity += parseInt(item['quantity']);
              vehicle.details.push(details);
              return;
            }
            else {
              groupedByProductCode.push({
                vehicleCode: productCode,
                // invoiceLineId: item.cil_invoice_line_id,
                description: item.description,
                thnNumber: item['tariff_code'] === null ? '' : item['tariff_code'],
                quantity: 1,
                unitPrice: item.unit_price === null ? 0 : item.unit_price,
                grossWeight: item['gross_weight'] === null ? 0 : item['gross_weight'],
                netWeight: item['net_weight'] === null ? 0 : item['net_weight'],
                curbWeight: item['curb_weight'] === null ? 0 : item['curb_weight'],
                engineDisplacement: item['engine_displacement'],
                fuelType: item['fuel_type'] === null ? '' : item['fuel_type'],
                numberOfSeats: item['number_of_seats'] === null ? 5 : item['number_of_seats'],
                numberOfDoors: item['number_of_doors'] === null ? 4 : item['number_of_doors'],
                tyreSize: item['tyre_size'] === null ? '' : item['tyre_size'],
                modelCode: item['model_code'] === null ? '' : item['model_code'],
                seatPosition: item['seat_position'] === null ? 'RHD' : item['seat_position'],
                details: [details]
              });
            }
          });
          return groupedByProductCode
        },
        processVehicleData(vehicleList){
          const groupedByEDNumberCode = [];

          vehicleList.forEach(item => {
            const edNumber = item['ed_number'];
            const details = {
              id: item.id,
              chassisNumber: item.chassis_number,
              engineNumber: item.engine_number,
              exteriorColor: item.exterior_color,
              customerName: item.customer_name ?? '',
              customerTIN: item.customer_tin ?? '',
              brokerInstructions: item.broker_instructions,
              cpcCode: item.cpc_code, // TODO: Fetch CPC Code,
              npcCode: item.npc_code, //TODO: Fetch NPC Code,
              edNumber: edNumber,
              quantity: item.quantity,
              grossWeight: item.gross_weight ?? 0,
              netWeight: item.net_weight ?? 0,
              engineDisplacement: item.engine_displacement,
              manufactureYear: item.manufacture_year ?? '2000',
              engine_number: item.engine_number,
              invoiceLineId: item.cil_invoice_line_id
            };

            // const currentProductCode = item['product_code'];
            const vehicle = groupedByEDNumberCode.find(vehicle => vehicle.edNumber === edNumber);
            if (vehicle) {
              vehicle.quantity += parseInt(item['quantity']);
              vehicle.details.push(details);
              return;
            }
            else {
              groupedByEDNumberCode.push({
                vehicleCode: item['product_code'],
                // invoiceLineId: item.cil_invoice_line_id,
                description: item.description,
                thnNumber: item['tariff_code'] === null ? '' : item['tariff_code'],
                quantity: 1,
                edNumber: edNumber,
                unitPrice: item.unit_price === null ? 0 : item.unit_price,
                grossWeight: item['gross_weight'] === null ? 0 : item['gross_weight'],
                netWeight: item['net_weight'] === null ? 0 : item['net_weight'],
                curbWeight: item['curb_weight'] === null ? 0 : item['curb_weight'],
                engineDisplacement: item['engine_displacement'],
                fuelType: item['fuel_type'] === null ? '' : item['fuel_type'],
                numberOfSeats: item['number_of_seats'] === null ? 5 : item['number_of_seats'],
                numberOfDoors: item['number_of_doors'] === null ? 4 : item['number_of_doors'],
                tyreSize: item['tyre_size'] === null ? '' : item['tyre_size'],
                modelCode: item['model_code'] === null ? '' : item['model_code'],
                seatPosition: item['seat_position'] === null ? 'RHD' : item['seat_position'],
                details: [details]
              });
            }
          });
          return groupedByEDNumberCode
        },
        mapResponseToRecord(response) {
            return {
              exporters: [],
              importers: [],
              vessels: [],
              ports: [],
              declarants: [],
              incotermsList: IncoTerms.getIncoTermsList(),
              modeOfTransportOOptions: BrokerCongifurations.getFreightTypes(),
              importerId : response.importer,
              exporterId : response.shipper,
              importerName: '',
              exporterName: '',
              importerTaxIdentificationNumber: '',
              exporterTaxIdentificationNumber: '',
              exporterCountry: '',
              declarantTaxIdentificationNumber: '',
              waybillNumber : response.waybill_number,
              waybillDate : '',
              modeOfTransport : response.mode_of_transport,
              freightType : response.freight_type,
              vesselId : response.vessel,
              vesselName: '',
              packageTypes: PackageTypes.usePackageTypes(),
              portOfDischargeId : response.port_of_discharge,
              portOfDischargeName: '',
              netWeight : response.net_weight,
              grossWeight : response.gross_weight,
              numberOfPackages : response.package_quantity,
              packageType : response.package_type,
              marksAndNumbers : response.marks_and_numbers,
              totalCargoValue : response.invoice_total,
              freightAmount : response.freight_charge,
              insuranceAmount : response.insurance_amount,
              otherCharges : response.other_charges,
              rateOfExchange : response.rate_of_exchange,
              countryOfOrigin : response.country_of_origin,
              countryOfFinalDestination : response.country_of_final_destination,
              regimeType : response.regimeType,

              regimeTypeCode : response.regimeTypeCode,
              regimeTypeOptions: [],
              depositAmount : response.deposit,
              containerCharges : response.container_charges,
              additionalCharges : response.additional_charges,
              localFee : response.local_fee,
              declarantId : response.declarant,
              incoTerms : response.incoterms,
              countryLastProvinance : response.country_last_provenance,
              tradingCountry : response.trading_country,
            };
        },
        async handleEdit(payload) {
          console.log('Edit payload:', payload);
          const waybillData = payload.waybill
          console.log('Waybill data:', waybillData);
          let entryData = payload.entry;
          entryData.entry_status = this.entryStatus;
          console.log('Entry data:', entryData);
          let invoiceData = payload.invoice;
          invoiceData.invoice_id = this.invoiceDetails.invoice_id;
          console.log('Invoice data:', invoiceData);

          let invoiceLineData = payload.invoiceLines;
          console.log('Invoice line data:', invoiceLineData);

          const vehicleData = payload.vehicleLines;
          console.log('Vehicle data:', vehicleData);

          const invoicesRequestData = { invoices: [invoiceData] };
          const invoiceLinesRequestData = { invoiceLines: invoiceLineData };
          const vehicleRequestData = { vehicles: vehicleData };

          

          SweetAlertConnector.showLoadingAlert('Updating Entry details...');
          Promise.all([
            DatabaseConnector.updateEntryData(this.entryId, entryData),
            DatabaseConnector.updateWaybillData(this.waybillId, waybillData),
            axios.put(`${process.env.VUE_APP_API_BASE_URL}update-multiple-invoices`, invoicesRequestData),
            axios.put(`${process.env.VUE_APP_API_BASE_URL}update-multiple-invoice-lines`, invoiceLinesRequestData),
            axios.put(`${process.env.VUE_APP_API_BASE_URL}update-vehicle-details`, vehicleRequestData)
            // DatabaseConnector.updateInvoiceDataOptimized(invoiceData),
            // DatabaseConnector.updateInvoiceLineDataOptimized(invoiceLineData),
          ]).then(() => {
            SweetAlertConnector.closeCurrentAlert();
            SweetAlertConnector.showSuccessAlert('Entry details updated successfully');

          }).catch(error => {
            console.error('Failed to update the Entry details:', error);
            SweetAlertConnector.showErrorAlert('Failed to update the Entry details. Please try again.');
          });


            
        },
    },
  };
  </script>
  