<template>
    <div class="card">
        <div class="drag-area" @dragover.prevent="onDragover" @dragleave.prevent="onDragLeave" @drop.prevent="onDrop">
            <span v-if="!isDragging">
                <h3>Drag & Drop files here or</h3>
                <span class="select" role="button" @click="selectFiles">
                    <h3>Choose a file</h3>
                </span>
            </span>
            <div v-else class="select">Drop files here</div>
            <input name="file" type="file" class="file" ref="fileInput" multiple @change="onFileSelect"/>
        </div>
        <div class="file-item" v-for="(image, index) in images" :key="index">
            <p>{{image.name}}</p>
        </div>
        <button type="button" class="upload-button">Upload</button>
    </div>
    
</template>

<script>
export default {
    data(){
        return {
            isDragging: false,
            images: [],
        }
    },
    methods:{
        selectFiles(){
            this.$refs.fileInput.click();
        },
        onFileSelect(e){
            const files = e.target.files;
            this.handleFiles(files);
        },
        handleFiles(files){
            if(files.length === 0){ return; }
            for(let i = 0; i < files.length; i++){
                const file = files[i];
                if(!this.images.includes(file)){
                    this.images.push(file);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const image = new Image();
                        image.src = e.target.result;
                        // this.$refs.fileInput.insertAdjacentHTML('beforebegin', `<div class="image"><span class="delete">&times;</span><img src="${image.src}"/></div>`);
                    }
                    reader.readAsDataURL(file);
                }
            }
        },
        onDragover(e){
            e.preventDefault();
            this.isDragging = true;
            e.dataTransfer.dropEffect = 'copy';
        },
        onDragLeave(e){
            e.preventDefault();
            this.isDragging = false;
        },
        onDrop(e){
            e.preventDefault();
            this.isDragging = false;
            const files = e.dataTransfer.files;
            this.handleFiles(files);
        }
    
    }
}
</script>
<style scoped>
.upload-button{
    margin-top: 5px;
}
.card{
    width: 100%;
    padding: 10px;
    box-shadow: 0 0 5px #ffdfdf;
    border-radius: 5px;
    overflow: hidden;
}

.card .top{
    text-align: center;
}

.card p{
    font-weight: bold;
    color: #4287f5;
}

.card button{
    outline: 0;
    border: 0;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    padding: 8px 13px;
    width: 100%;
    background: #4287f5;
}

.card .drag-area{
    border: 2px dashed #4287f5;
    border-radius: 5px;
    height: 150px;
    background: #fff;
    color: #4287f5;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: center;
    -webkit-user-select: none;
    margin-top: 10px;
}

.card .drag-area .visible{
    font-size: 18px;
}

.card .select{
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.4s;
}

.card .select:hover{
    opacity: 0.6;
}

.card .container{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 200px;
    position: relative;
    margin-bottom: 8px;
    padding-top: 10px;
}

.card .container .image{
    width: 75px;
    margin-right: 5px;
    height: 75px;
    position: relative;
    margin-bottom: 8px;
}

.card .container .image img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.card .container .image span{
    position: absolute;
    top: -2px;
    right: 9px;
    font-size: 20px;
    cursor: pointer;
}

.card input,

.card .drag-area .on-drop,

.card .drag-area.dragover .visible{
    display: none;
}
.delete{
    z-index: 999;
    color: #4287f5;
}
.file-item{
    align-items:start;
    padding: 3px;
    border: 2px solid #4287f5;
    border-radius: 5px;
    margin-top: 3px;
}
</style>