<template>
    <div class="chart-container">
      <Bar
        v-if="chartDataReady"
        id="invoice-correction-buckets-chart"
        :options="chartOptions"
        :data="chartData"
        ref="myChart"
      />
    </div>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from 'chart.js';
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
  
  export default {
    name: 'InvoiceLineCorrection',
    components: { Bar },
    props: {
      reportDate: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        fetchedData: [],
        chartData: {
          labels: [], // Labels for the correction buckets
          datasets: [
            {
              label: 'Number of Invoices',
              backgroundColor: 'rgba(75, 192, 192, 0.7)', // Bar color
              data: [], // Data for the number of invoices in each bucket
              datalabels: {
                anchor: 'center',
                align: 'center',
                color: '#000',
                backgroundColor: 'rgba(211, 211, 211, 0.8)', // Light grey background
                borderColor: 'rgba(0, 0, 0, 0.1)', // Subtle border
                borderWidth: 1,
                borderRadius: 4, // Rounded corners
                padding: 4,
                font: {
                  weight: 'bold'
                }
              }
            }
          ]
        },
        chartOptions: {
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Correction Buckets',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Number of Invoices',
              },
              beginAtZero: true,
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Invoice Corrections by Number of Changes",
              font: {
                size: 18, // Title font size
              },
              padding: {
                top: 10,
                bottom: 20,
              }
            },
            legend: {
              position: 'top',
            },
          },
        },
        chartDataReady: false, // Control when to render the chart
      };
    },
    async mounted() {
      await this.fetchData(this.reportDate); // Fetch data when the component mounts
    },
    watch: {
      reportDate(newDate) {
        this.fetchData(newDate); // Re-fetch data when the date prop changes
      }
    },
    methods: {
      async fetchData(date) {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}invoice-line-correction-data-buckets-day?date=${date}`);
          const data = await response.json();
          console.log('Invoice correction buckets:', data);
  
          if (data && data.length > 0) {
            this.fetchedData = data;
            // Populate the chart data
            this.chartData.labels = data.map((entry) => entry.bucket);
            this.chartData.datasets[0].data = data.map((entry) => entry.num_invoices); // Update to use num_invoices
  
            // Trigger the chart rendering
            this.chartDataReady = false;
            this.$nextTick(() => {
              this.chartDataReady = true;
            });
          }
          console.log('Updated chart data:', this.chartData);
        } catch (error) {
          console.error('Error fetching correction data buckets:', error);
        }
      },
    }
  };
  </script>
  
  <style scoped>
  .chart-container {
    max-width: 800px; /* Restrict the maximum width */
    width: 100%; /* Allow the container to use up to 100% of its parent's width */
    height: 100%; /* Ensure the container takes up full height */
    display: flex; /* Flexbox for alignment */
    align-items: stretch; /* Stretch to fill the available height */
    justify-content: center; /* Center the chart horizontally */
  }
  
  #invoice-correction-buckets-chart {
    flex: 0 1 auto; /* Prevent the chart from growing beyond its content width */
    width: 100%; /* Ensure the chart uses the full available width within its container */
    height: 100%; /* Ensure the chart uses full height */
  }
  </style>
  