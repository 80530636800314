import { ref } from 'vue'

export function getVehiclesList() {
    const vehicleList = ref(
        [
            { code: "328FG18", description: "FORKLIFT TRUCK" },
            { code: "3BAAGH30W", description: "ALPHARD MINIVAN" },
            { code: "4RUNNER", description: "4RUNNER" },
            { code: "6FGL18", description: "FORKLIFT" },
            { code: "7FBEHU18", description: "ELECTRIC FORKLIFT" },
            { code: "7FDU70", description: "FORKLIFT" },
            { code: "86", description: "TOYOTA 86" },
            { code: "8FBE20U", description: "FORKLIFT" },
            { code: "8FGC45U", description: "2014 LPG FORKLIFT" },
            { code: "8FGCU 30", description: "TOYOTA FORKLIFT 8FGCU 30" },
            { code: "8FGCU25", description: "TOYOTA FORLIFT" },
            { code: "8FGU15", description: "TOYOTA FORKLIFT TRUCK" },
            { code: "AGYA", description: "HATCHBACK" },
            { code: "AQUA", description: "AQUA" },
            { code: "AURIS", description: "TOYOTA AURIS MOTOR CAR" },
            { code: "AVALON", description: "AVALON" },
            { code: "AXIO", description: "TOYOTA AXIO" },
            { code: "AXIO HYBRID", description: "TOYOTA AXIO GASOLINE ELECTRIC HYBRID" },
            { code: "AGYO", description: "TOYOTA AYGO" },
            { code: "B101RAGQSGJ", description: "5 DR RHD" },
            { code: "B101RAGQSGT", description: "5DR GAS RHD" },
            { code: "BB", description: "MINI MPV" },
            { code: "BZ4X PURE", description: "ELECTRIC SUV" },
            { code: "CALYA ASTRA", description: "SUV" },
            { code: "CAMRY", description: "CAMRY" },
            { code: "CAMRY HYBRID", description: "CAMRY HYBRID" },
            { code: "CAMRY SOLARA", description: "CAMRY SOLARA" },
            { code: "CELICA", description: "CELICA" },
            { code: "CHR", description: "CROSSOVER SUV" },
            { code: "CHR HYBRID", description: "C-HR HYBRID" },
            { code: "COASTER", description: "COASTER" },
            { code: "COMMUTER", description: "CUMMUTER PANEL VAN" },
            { code: "COROLLA", description: "COROLLA" },
            { code: "COROLLA CROSS", description: "COROLLA CROSS" },
            { code: "CRESSIDA", description: "CRESSIDA" },
            { code: "CROWN", description: "SEDAN" },
            { code: "CROWN HYBRID", description: "TOYOTA CROWN HYBRID" },
            { code: "DYNA", description: "DYNA TRUCK" },
            { code: "ECHO", description: "ECHO" },
            { code: "ESTIMA", description: "SEDAN" },
            { code: "FIELDER", description: "FIELDER" },
            { code: "FJ CRUISER", description: "FJ CRUISER" },
            { code: "FORKLIFT", description: "TOYOTA FORKLIFT" },
            { code: "FORTUNER", description: "FORTUNER" },
            { code: "GRAND", description: "GRAND HIACE" },
            { code: "GRANDE", description: "GRANDE ACE" },
            { code: "HARRIER", description: "HARRIER SUV" },
            { code: "HIACE", description: "HIACE" },
            { code: "HIGHLANDER", description: "HIGHLANDER" },
            { code: "HIGHLANDER HYBRID", description: "HIGHLANDER HYBRID" },
            { code: "HILUX", description: "HILUX SINGLE CAB" },
            { code: "HILUX DOUBLE", description: "HILUX DOUBLE CAB" },
            { code: "HINO", description: "HINO TRUCK" },
            { code: "IPSUM", description: "IPSUM" },
            { code: "IST", description: "IST" },
            { code: "LAND CRUISER", description: "LAND CRUISER" },
            { code: "LC200", description: "LC200" },
            { code: "LC70", description: "LC70" },
            { code: "LEXUS", description: "LEXUS" },
            { code: "LITEACE", description: "TOYOTA LITEACE" },
            { code: "MARKX", description: "MARKX" },
            { code: "MATRIX", description: "MATRIX" },
            { code: "MR2", description: "MR2" },
            { code: "NOAH", description: "NOAH MINIBUS" },
            { code: "PASEO", description: "PASEO" },
            { code: "PASSO", description: "TOYOTA PASSO" },
            { code: "PIXIS EPOCH", description: "PIXIS EPOCH" },
            { code: "PRADO", description: "PRADO" },
            { code: "PREVIA", description: "PREVIA"}
        ]
    )
    return vehicleList
}