<template>
  <div class="chart-container">
    <Bar
      v-if="chartDataReady"
      id="classification-accuracy-chart"
      :options="chartOptions"
      :data="chartData"
      ref="myChart"
    />
    <div class="chart-details">
      <h3>Explanation of the Classification Accuracy Buckets:</h3>
      <ul>
        <li><strong>1 - 6 numbers match incorrectly:</strong> 5 points</li>
        <li><strong>7 - 8 numbers match incorrectly:</strong> 3 points</li>
        <li><strong>9 – 11 match incorrectly: </strong> 1 point</li>
        <li><strong>Exact Match:</strong> 0 points</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'ClassificationDailyPerformance',
  components: { Bar },
  props: {
    reportDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetchedData: [], // To hold the fetched classification accuracy buckets
      chartData: {
        labels: [], // Labels for the score buckets
        datasets: [
          {
            label: 'Number of Lines Classified',
            backgroundColor: 'rgba(153, 102, 255, 0.7)', // Bar color
            data: [], // Data for the number of records
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: '#000',
              backgroundColor: 'rgba(211, 211, 211, 0.8)', // Light grey background
              borderColor: 'rgba(0, 0, 0, 0.1)',
              borderWidth: 1,
              borderRadius: 4,
              padding: 4,
              font: {
                weight: 'bold'
              }
            }
          }
        ]
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Score Buckets',
            },
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: 'Number of Lines',
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: "Classification Accuracy Buckets",
            font: {
              size: 18, // Title font size
            },
            padding: {
              top: 10,
              bottom: 20,
            }
          },
          legend: {
            position: 'top',
          },
        },
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const elementIndex = elements[0].index;
            // const label = this.chartData.labels[elementIndex];
            const data = this.chartData.datasets[0].data[elementIndex];
            
            // Trigger your custom event or action here
            this.handleBarClick(elementIndex, data);
          }
        },
      },
      chartDataReady: false, // Control when to render the chart
    };
  },
  async mounted() {
    await this.fetchData(this.reportDate); // Fetch data when the component mounts
  },
  watch: {
    reportDate(newDate) {
      this.fetchData(newDate); // Re-fetch data when the date prop changes
    }
  },
  methods: {
    async fetchData(date) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}classification-accuracy-buckets?date=${date}`);
        const data = await response.json();
        console.log('Classification accuracy buckets:', data);

        if (data && data.length > 0) {
          this.fetchedData = data;
          // Populate the chart data
          this.chartData.labels = data.map((entry) => entry.score_bucket);
          this.chartData.datasets[0].data = data.map((entry) => entry.number_of_lines);

          // Trigger the chart rendering
          this.chartDataReady = false;
          this.$nextTick(() => {
            this.chartDataReady = true;
          });
        } else {
          // Reset the chart if no data is returned
          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];
          this.chartDataReady = true;
        }
        console.log('Updated chart data:', this.chartData);
      } catch (error) {
        console.error('Error fetching classification accuracy buckets:', error);
      }
    },
    handleBarClick(index, data) {
      console.log(this.fetchedData);
      console.log(`Index: ${index}`);
      console.log(`Data: ${data}`);
      const selectedBucket = this.fetchedData[index];
      console.log('Selected bucket:', selectedBucket);
      const invoiceLineIds = selectedBucket.invoice_line_ids.split(',').map(id => id.trim());
      console.log('Invoice Line IDs:', invoiceLineIds);
  
      this.$router.push({
        name: 'ClassificationAccuracyReport',
        query: {
          invoiceLineIds: invoiceLineIds.join(',')
        }
      });
    },
  }
};
</script>

<style scoped>
.chart-container {
  width: 100%; /* Ensure the chart takes up the full width of the parent */
  max-width: 800px; /* Optional: limit the maximum width */
  margin: auto;
}

.chart-container > canvas {
  width: 100% !important; /* Force the canvas (chart) to take up the full width */
}

.chart-details {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  font-size: 14px;
  text-align: left; /* Override parent centering */
}

.chart-details h3 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.chart-details ul {
  padding-left: 20px;
}

.chart-details li {
  margin-bottom: 5px;
}
</style>
