<template>
  <Bar
    v-if="chartDataReady"
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
    ref="myChart"
  />
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, ChartDataLabels);

export default {
  name: 'InvoiceAIPerformance',
  components: { Bar },
  data() {
    return {
      chartData: {
        labels: [], // Initialize as an empty array
        datasets: [
          {
            label: 'Total Number of Invoices',
            backgroundColor: 'rgba(135, 206, 235, 0.5)', // Skyblue color with 50% opacity
            data: [], // Initialize as an empty array for total number of invoices
            yAxisID: 'y', // Bind this dataset to the first y-axis
            order: 1, // Draw the bar chart first
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: '#000',
              backgroundColor: 'rgba(211, 211, 211, 0.8)', // Light grey background with slight transparency
              borderColor: 'rgba(0, 0, 0, 0.1)', // Optional: add a subtle border
              borderWidth: 1,
              borderRadius: 4, // Rounded corners for the box
              padding: {
                top: 4,
                bottom: 4,
                left: 6,
                right: 6
              },
              font: {
                weight: 'bold'
              },
              formatter: Math.round // Round numbers
            }
          },
          {
            label: 'Average Processing Time',
            backgroundColor: 'orange',
            data: [], // Initialize as an empty array
            type: 'line',
            borderColor: 'orange',
            fill: false,
            yAxisID: 'y1', // Bind this dataset to the second y-axis
            order: 2, // Draw the line chart after the bar chart
            datalabels: {
              anchor: 'end',
              align: 'top',
              color: '#000',
              backgroundColor: 'rgba(211, 211, 211, 0.8)', // Light grey background with slight transparency
              borderColor: 'rgba(0, 0, 0, 0.1)', // Optional: add a subtle border
              borderWidth: 1,
              borderRadius: 4, // Rounded corners for the box
              padding: {
                top: 4,
                bottom: 4,
                left: 6,
                right: 6
              },
              font: {
                weight: 'bold'
              },
              offset: 10, // Offset labels slightly above the point
              formatter: function(value) {
                return value.toFixed(2); // Round to two decimal places
              }
            }
          }
        ]
      },
      chartOptions: {
        responsive: true,
        plugins: {
          datalabels: {
            color: '#444',  // Default color, not used for specific datasets
          },
          title: {
            display: true,
            text: 'Invoice AI Performance - Past 5 days',
            font: {
              size: 18,
            },
            padding: {
              top: 10,
              bottom: 20,
            }
          },
          legend: {
            position: 'top',
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Number of Invoices',
            },
            beginAtZero: true,
          },
          y1: {
            type: 'linear',
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: 'Processing Time',
            },
            beginAtZero: true,
          },
        },
      },
      chartDataReady: false
    };
  },
  async mounted() {
    await this.fetchData(); // Fetch data when the component mounts
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}invoice-ai-performance-metrics`);
        const data = await response.json();
        if (data && data.length > 0) {
          this.chartData.labels = data.map((entry) => new Date(entry.processing_date).toLocaleDateString('en-US'));
          this.chartData.datasets[0].data = data.map((entry) => entry.total_number_of_invoices);
          this.chartData.datasets[1].data = data.map((entry) => entry.average_processing_time);

          this.chartDataReady = false;
          this.$nextTick(() => {
            this.chartDataReady = true;
          });
        }
      } catch (error) {
        console.error('Error fetching performance metrics:', error);
      }
    }
  }
}
</script>

<style scoped>
.chart-container {
  max-width: 800px;
  margin: auto;
}
</style>
